import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONSTANTS */

const index = observer((props) => {

  LocalStorage.removeLS();

  return <Fragment>
    <br/>
    <br/>
    <ModulePaperWrapper>
      <Typography align="center" gutterBottom>
        <img src={LOGO} width="50%" />
      </Typography>
      <img src={LANDINGGRAPHICS} width="100%" />
      <Typography variant="h5" gutterBottom>
        <b>You have been logged out.</b>
      </Typography>
      <br/>
      <Typography variant="body2">
        <Link to="/u/login"><b>Login and continue</b></Link>
      </Typography>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
