import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Landing Success Reporting Download";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: null,
        endpoints: null
      }
    }
  },
  routes: {
    label: "Success Reporting Download",
    prefix: "/reporting/l/download-xlsx/19"
  },
  setup,
  environment
}

export default config;
