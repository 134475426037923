import React from 'react';

/* packages */
import DesignSection from '../../util-mui-design-section';
/* packages */

const Index = props => {
  return <DesignSection.Title>
    Company Detail
  </DesignSection.Title>
}

export default Index;
