import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import Breadcrumbs from '../../util-mui-breadcrumbs';
/* packages */

const Index = ({ companyId }) => {
  return <Breadcrumbs>
    <Link to={"/company"}><small>Home</small></Link>
    <Link to={"/company/list"}><small>List</small></Link>
    <Link to={"/company/detail/" + companyId}><small>Detail</small></Link>
    <small>Edit Contact</small>
  </Breadcrumbs>
}

export default Index;
