import Text from './text';
import Password from './password';
import Textarea from './textarea';
import Suggest from './suggest';
import Hidden from './hidden';
import Checkbox from './checkbox';
import Select from './select';

export default {
  Text,
  Password,
  Textarea,
  Suggest,
  Hidden,
  Checkbox,
  Select
}
