import React, { useEffect, useRef, useState } from 'react'

import Autosuggest from 'react-autosuggest';

import { Invalid } from '../feedbacks';

const Suggest = props => {

  const { name, initial, type, label, errors, value, handleChange, suggestionProps, isSelected, onResetSelected, selectedValue, displayTemplate, removeText } = props;

  const theme_valid = {
    container:                'space-tiny-top space-tiny-bottom',
    containerOpen:            'space-tiny-top space-tiny-bottom',
    input:                    'form-control',
    inputOpen:                'react-autosuggest__input--open',
    inputFocused:             'react-autosuggest__input--focused',
    suggestionsContainer:     'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList:          'react-autosuggest__suggestions-list',
    suggestion:               'react-autosuggest__suggestion',
    suggestionFirst:          'react-autosuggest__suggestion--first',
    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
    sectionContainer:         'react-autosuggest__section-container',
    sectionContainerFirst:    'react-autosuggest__section-container--first',
    sectionTitle:             'react-autosuggest__section-title'
  }
  const theme_invalid = {
    container:                'space-tiny-top space-tiny-bottom',
    containerOpen:            'space-tiny-top space-tiny-bottom',
    input:                    'is-invalid form-control',
    inputOpen:                'react-autosuggest__input--open',
    inputFocused:             'react-autosuggest__input--focused',
    suggestionsContainer:     'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList:          'react-autosuggest__suggestions-list',
    suggestion:               'react-autosuggest__suggestion',
    suggestionFirst:          'react-autosuggest__suggestion--first',
    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
    sectionContainer:         'react-autosuggest__section-container',
    sectionContainerFirst:    'react-autosuggest__section-container--first',
    sectionTitle:             'react-autosuggest__section-title'
  }

  const theme = !errors ? theme_valid : theme_invalid;

  let selectedDisplay = null;
  if (selectedValue) {
    const { suggestion } = JSON.parse(selectedValue);
    selectedDisplay = displayTemplate(suggestion);
  }

  suggestionProps.inputProps.placeholder = label;

  return <div>
    { /*!isSelected && <p className="space-tiny-top space-tiny-bottom">{label}</p>*/ }
    { !isSelected && <Autosuggest theme={theme} {...suggestionProps} /> }
    <input type="hidden" name={name} id={name} />
    <Invalid errors={errors} />
    { isSelected && selectedDisplay }
    { isSelected && props.children }
  </div>

}

export default Suggest;
