import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-body-label';

import CONFIG from '../../../config';

const itemLabel = props => {
  return <View style={[styles.container, styles.itemsWrapper]}>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>Cheque Banking</Text>
    </View>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>$ ____________</Text>
    </View>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>Date Banked</Text>
    </View>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>_____/_____/_____</Text>
    </View>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>CSR Signed:</Text>
    </View>
    <View style={styles.bodyLabelContainer}>
      <Text style={styles.bodyLabelText}>______________</Text>
    </View>
  </View>
}

export default itemLabel;
