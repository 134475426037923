import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperInfo } from '../../util-atomic-design';
/* packages */

const index = ({productId, productName, path}) => {
  return <Fragment>

    <Typography variant="h6">
      <img width="20%" src={path} /> &nbsp;
      <b>{ productName }</b>
    </Typography>
  </Fragment>
}

export default index;
