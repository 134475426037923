import React, { useContext, useEffect, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Moment from "react-moment";

/* packages */
import { Lists } from "../../lib-atomic";
import { ModuleText } from "../../lib-atomic-design-section";
import { NumberCurrency } from "../../lib-atomic-functions";
/* packages */

/* section */
import OrderDisplayStatus from "../../app-module-order-section-status/default";
/* section */

import CONFIG from "../config";

/* CONSTANTS */
const IDTPL = (data, obj) => {
  const link = "/orders/detail/" + data;
  return (
    <ModuleText>
      <Link to={link}>
        <b>{data}</b>
      </Link>
    </ModuleText>
  );
};
const ENROLLMENTTRADERNAME = (data, obj) => {
  return (
    <ModuleText>
      <small>{data}</small>
    </ModuleText>
  );
};
const ITEMSTPL = (data, obj) => {
  const count = data.length;
  return (
    <ModuleText>
      {count} <small>(items)</small>
    </ModuleText>
  );
};
const AMOUNTTPL = (data, obj) => {
  const amount = NumberCurrency(data);
  return <ModuleText>${amount}</ModuleText>;
};
const STATUSTPL = (data, obj) => {
  return (
    <ModuleText>
      <OrderDisplayStatus {...obj} />
    </ModuleText>
  );
};
const COMPANYTPL = (data, obj) => {
  return (
    <Fragment>
      <ModuleText>
        <b>{data}</b>
      </ModuleText>
    </Fragment>
  );
};
const CONTACTPL = (data, obj) => {
  return (
    <Fragment>
      <ModuleText>
        {obj.clientContactFirstName}
        {obj.clientContactLastName}
      </ModuleText>
    </Fragment>
  );
};
const TOTALTPL = (data, obj) => {
  if (!data) return null;
  const amount = NumberCurrency(data.toFixed(2));
  return (
    <Fragment>
      <ModuleText>${amount}</ModuleText>
    </Fragment>
  );
};
const DATETPL = (data, obj) => {
  return (
    <ModuleText>
      <b>
        <Moment format="DD/MM/YYYY">{data}</Moment>
      </b>{" "}
      <br />
      <small>
        <Moment format="HH:mm:ss">{data}</Moment>
      </small>{" "}
      <br />
      <small>
        <Moment fromNow>{data}</Moment>
      </small>
    </ModuleText>
  );
};
const CONFIRMDATETPL = (data, obj) => {
  if (!data)
    return (
      <ModuleText>
        <small>N/A</small>
      </ModuleText>
    );
  return (
    <ModuleText>
      <b>
        <Moment format="DD/MM/YYYY">{data}</Moment>
      </b>{" "}
      <br />
      <small>
        <Moment format="HH:mm:ss">{data}</Moment>
      </small>{" "}
      <br />
      <small>
        <Moment fromNow>{data}</Moment>
      </small>
    </ModuleText>
  );
};
const LABEL = (data) => {
  return (
    <ModuleText>
      <br />
      <small>
        <b>{data}</b>
      </small>
      <br />
      <br />
    </ModuleText>
  );
};

const LISTTEMPLATECONFIG = [
  { field: "id", template: IDTPL, label: LABEL("ORDER #") },
  { field: "clientName", template: COMPANYTPL, label: LABEL("COMPANY") },
  { field: "contactId", template: CONTACTPL, label: LABEL("CONTACT") },
  { field: "totalAmount", template: TOTALTPL, label: LABEL("TOTAL") },
  { field: "orderStatusName", template: STATUSTPL, label: LABEL("STATUS") },
  { field: "createDate", template: DATETPL, label: LABEL("CREATED") },
  {
    field: "confirmDate",
    template: CONFIRMDATETPL,
    label: LABEL("PROCESS DATE"),
  },
  {
    field: "enrolmentTraderName",
    template: ENROLLMENTTRADERNAME,
    label: false,
  },
];
/* CONSTANTS */

const index = ({ order }) => {
  /* data props */
  const listData = { data: order.slice(0, 50), config: LISTTEMPLATECONFIG };
  /* data props */

  return <Lists.Normal {...listData} />;
};

export default index;
