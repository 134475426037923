import React, { useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import ContactSectionInfo from '../../app-module-company-contacts-detail-section-info/default';
/* packages */

/* loader */
import Loader from '../loader/fetcher';
/* loader */

/* stores */
import { Fetcher } from '../../app-module-contact-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const CONTACT = CONFIG.application.contact.api.endpoints.detail;
const CONTACTCONFIG = {
  url: CONTACT.url,
  method: CONTACT.method,
  headers: CONTACT.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = observer(({companyId, contactId}) => {

  const { token } = LocalStorage.getLS().login;

  /* state */
  CONTACTCONFIG.headers = { ...CONTACTCONFIG.headers, Authorization: token }
  const [ contactContext, contactMethod ] = FetchContextApi(CONTACTCONFIG, Fetcher);

  useEffect(() => {
    if (companyId && contactId) contactMethod({query: companyId + '/contacts'});
  }, [companyId, contactId]);
  /* state */

  if (contactContext.loading) return <Loader />
  if (!contactContext.data.json) return <Loader />
  const contact = contactContext.data.json.contacts.filter((contact) => contact.id === Number(contactId))[0];
  if (!contact) return <Loader />

  /* data props */
  const { firstName, lastName, emailAddress, mobile, comment } = contact;
  const contactData = {
    contactId,
    contactFirstName: firstName ? firstName : '',
    contactLastName: lastName ? lastName : '',
    contactEmail: emailAddress ? emailAddress : "",
    contactMobile: mobile ? mobile : "",
    contactComments: comment ? comment : ""
  };
  // /* data props */

  return <ContactSectionInfo {...contactData} />
});

export default index;
