import React from 'react';

/* packages */
import { ModuleTitle } from '../../lib-atomic-design-section';
/* packages */

const action = props => {
  return <ModuleTitle>
    Preview / Download PDF
  </ModuleTitle>
}

export default action;
