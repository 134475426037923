import React from 'react';

import { Row, Col } from 'reactstrap';

const programHeader = props => {
  return <Row>
    <Col>
    program header
    </Col>
  </Row>
}

export default programHeader;
