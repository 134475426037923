/* reporting module */
import PageLanding from '../../../app-module-reporting-page-landing';
import ReportingPage19 from '../../../app-module-reporting-page-19';
import ReportingPage20 from '../../../app-module-reporting-page-20';
import ReportingPage21 from '../../../app-module-reporting-page-21';

import ReportingLanding19 from '../../../app-module-reporting-landing-19';
import ReportingLanding20 from '../../../app-module-reporting-landing-20';
import ReportingLanding21 from '../../../app-module-reporting-landing-21';

import ReportingEmptyLanding from '../../../app-module-reporting-landing-empty';
import Reporting404 from '../../../app-module-admin-landing-404';

/* company module */
import CompanyLanding from '../../../app-module-company-landing';
import CompanyList from '../../../app-module-company-list';
import CompanySearch from '../../../app-module-company-search';
import CompanyCreate from '../../../app-module-company-create';
import CompanyEdit from '../../../app-module-company-edit';
import CompanyDetail from '../../../app-module-company-detail';
import CompanyContactsCreate from '../../../app-module-contact-create';
import CompanyContactsEdit from '../../../app-module-contact-edit';

import LandingSuccessCompanyCreate from '../../../app-module-company-landing-success-create';

/* order */
const orderModules = [
]

/* company */
const companyModules = [
]


/* reporting */
const reportingModules = [
    PageLanding,
    ReportingPage21,
    ReportingLanding21,
    ReportingEmptyLanding,
]

const modules = {
    company: companyModules,
    reporting: reportingModules,
    orders: orderModules,
    product: []
}

export default modules;
  