import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

/* packages */
import { ModuleWrapperMain, ModuleWrapperMd } from '../../lib-atomic-design-section';
/* packages */

/* modules */
import CompanyNav from './nav';
import CompanyTitle from './title';
import CompanyList from './list';
import CompanySearch from '../../app-module-company-search-button/default';
/* modules */

/* stores */
import CompanyListStore from '../../app-module-company-container/stores/list';
/* stores */

import CONFIG from '../config';

const Index = observer((props) => {

  return <ModuleWrapperMain>
    <ModuleWrapperMd>
      <CompanyNav {...props} />
      <CompanyTitle {...props} />
      <CompanySearch {...props} />
      <CompanyList {...props} />
    </ModuleWrapperMd>
  </ModuleWrapperMain>

});

export default Index;
