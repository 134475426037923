import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperInfo } from '../../util-atomic-design';
/* packages */

const index = ({productId, productName}) => {
  return <ModuleWrapperInfo>
    <Typography variant="h6">
      <b>{ productName } </b>
    </Typography>
  </ModuleWrapperInfo>
}

export default index;
