const isConfirm = (data) => {
  const value = data.value.trim();
  const config = data.config;
  console.log(config.confirm_to, ' XADA ', data);
  const confirmTo = data.allValues[config.confirm_to];
  const suffix = config.confirm_suffix;
  const nullAllow = config.allow_null ? true : false;
  if (nullAllow && value.length < 1 && confirmTo.length < 1) return true;
  return (value === confirmTo) ? true : "value does not match with the " + suffix;
}

export default isConfirm;
