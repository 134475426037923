import React from "react";

import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

/* packages */
import atomicComponents from '../packages';
const {
  Style,
  TranxactorLogo,
  Box
} = atomicComponents;
/* packages */

const Index = (props) => {
  return <Style>
    <Row>
      <Col sm="12" md={{ size: 4, offset: 4 }} lg={{ size: 4, offset: 4 }} xl={{ size: 4, offset: 4 }}>
        <div className="logo">
          <TranxactorLogo width="220" />
        </div>
        <Box>
        <h1>Password Reset</h1>
        <h2>To reset you password, enter the email you use to sign in.</h2>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Email Address</Label>
            <Input type="email" name="email" id="exampleEmail" bsSize="lg"/>
          </FormGroup>
          <FormGroup className="mt-4">
            <Button color="primary" block>Send Link</Button>
          </FormGroup>
          <FormGroup className="mt-4">
            <a href="/login">Cancel</a>
          </FormGroup>
        </Form>
        </Box>
      </Col>
    </Row>
  </Style>
}

export default Index;
