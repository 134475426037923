import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

import { Invalid } from '../feedbacks';

const text = ({name, label, value, helperText, errors, onChange}) => {
  const fieldData = {
    name,
    label,
    value,
    onChange,
    helperText,
    fullWidth: true,
    error: errors ? true : false,
    variant: "outlined",
    margin: "dense"
  }
  const feedbackData = { errors };
  return <Fragment>
    <TextField {...fieldData} />
    <Invalid {...feedbackData} />
  </Fragment>
}

export default text;
