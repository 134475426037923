import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Breadcrumbs } from '../../lib-atomic';
/* packages */

const nav = ({orderId}) => {
  return <Breadcrumbs.Normal>
    <Link to={"/orders"}><small>Home</small></Link>
    <Link to={"/orders/list"}><small>List</small></Link>
    <Link to={"/orders/detail/" + orderId}><small>Detail</small></Link>
    <small>PDF</small>
  </Breadcrumbs.Normal>
}

export default nav;
