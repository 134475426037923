import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* module */
import ContactInfoFull from '../../app-module-contact-section-info-full/default';
/* packages */

const index = ({companyId, contacts, edit}) => {
  if (!contacts) return null;
  return contacts.map((contact, index) => {
    const editLink = edit ? <Link to={"/company/" + companyId + "/contacts/edit/" + contact.id}><small>Edit</small></Link> : false;
    const contactInfoData = { contactId: contact.id, contactFirstName: contact.firstName, contactLastName: contact.lastName, contactEmail: contact.emailAddress, contactMobile: contact.mobile, contactComments: contact.comment, optin: contact.flPrimary, editLink }
    return <ContactInfoFull {...contactInfoData} />
  });

}

export default index;
