import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Detail";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': 'WGMB'
        },
        endpoints: {
          info: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          },
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          },
          contacts: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          },
          decline: {
            headers: {
              'Authorization': null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/accounts/:accountId/decline',
            url: setup.setup.api.tranxactor_westfield_corp.url[environment] + "/accounts/",
            method: 'POST'
          }  
        }
      }
    },
    contact: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': 'WGMB'
        },
        endpoints: {
          list: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/accounts/:accountId/contacts',
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Decline",
    prefix: "/company/decline/:companyId"
  },
  setup,
  environment
}

export default config;
