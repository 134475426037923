import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Moment from "react-moment";

/* packages */
import { Lists } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
import { ModuleText } from "../../lib-atomic-design-section";
/* packages */

/* modules */
import OrdersLoader from "../loader";
/* modules */

/* functions */
import { CompanyList } from "../../app-module-company-container/functions";
/* functions */

import CONFIG from "../config";

/* CONSTANTS */
const isNZBNTpl = (data, obj) => {
  const isNZBNDuplicate = String(data) === "1" ? true : false;
  if (!isNZBNDuplicate) return <></>;
  return (
    <div
      style={{
        borderRadius: "50px",
        backgroundColor: "green",
        width: "15px",
        height: "15px",
      }}
    >
      &nbsp;
    </div>
  );
};

/* CONSTANTS */
const IdTpl = (data, obj) => {
  const link = "/company/detail/" + data;
  return (
    <ModuleText>
      <Link to={link}>
        <b>{data}</b>
      </Link>
    </ModuleText>
  );
};

const NameTpl = (data, obj) => {
  return (
    <ModuleText>
      <b>{data}</b>
      <br />
      {obj.emailAddress && (
        <Fragment>
          <span>{obj.emailAddress}</span> <br />
        </Fragment>
      )}
      {obj.mobile && (
        <Fragment>
          <span>{obj.mobile}</span> <br />
        </Fragment>
      )}
      {obj.NZBN && (
        <Fragment>
          <span>NZBN: {obj.NZBN}</span> <br />
        </Fragment>
      )}
    </ModuleText>
  );
};
const AddressTpl = (data, obj) => {
  return (
    <ModuleText>
      {obj.physicalAddress1 && (
        <Fragment>
          <span>{obj.physicalAddress1}</span> <br />
        </Fragment>
      )}
      {obj.physicalSuburb && (
        <Fragment>
          <span>{obj.physicalSuburb}</span> <br />
        </Fragment>
      )}
      {obj.physicalCity && (
        <Fragment>
          <span>{obj.physicalCity}</span> <br />
        </Fragment>
      )}
      {obj.physicalPostcode && (
        <Fragment>
          <span>{obj.physicalPostcode}</span> <br />
        </Fragment>
      )}
    </ModuleText>
  );
};
const NZBNTpl = (data, obj) => {
  return (
    <ModuleText>
      <b>{data}</b>
    </ModuleText>
  );
};
const LABEL = (data) => {
  return (
    <ModuleText>
      <small>
        <b>{data}</b>
      </small>
      <br />
      <br />
    </ModuleText>
  );
};
const StatusTpl = (data, obj) => {
  return (
    <ModuleText>
      <br />
      {obj.statusName && (
        <Fragment>
          <span>{obj.statusName}</span> <br />
        </Fragment>
      )}
    </ModuleText>
  );
};

const config = [
  { field: "id", template: IdTpl, label: LABEL("COMPANY #") },
  { field: "name", template: NameTpl, label: LABEL("COMPANY DETAILS") },
  {
    field: "address",
    template: AddressTpl,
    label: LABEL("ADDRESS"),
  },
  { field: "status", template: StatusTpl, label: LABEL("STATUS") },
  {
    field: "flDuplicateNZBNExists",
    template: isNZBNTpl,
    label: LABEL("DUPLICATE NZBN"),
  },
  // {
  //   field: "physicalAddress2",
  //   template: AddressTpl,
  //   label: LABEL("DELIVERY ADDRESS"),
  // },
];
/* CONSTANTS */

const Index = observer((props) => {
  const { login } = LocalStorage.getLS();
  const { token } = login;
  const tokenCorp = LocalStorage.getLS().loginCorp.token;

  /* states */
  const { companyListContext, companyListMethod, companyListData } =
    CompanyList({ CONFIG, token, tokenCorp });

  useEffect(() => {
    companyListMethod();
  }, []);
  /* states */

  const { clients } = companyListData ? companyListData : false;

  /* laoder */
  if (!clients) return <OrdersLoader />;

  /* data props */
  // const listData = { data: clients.slice(0, 20), config };
  const listData = { data: clients, config };
  /* data props */

  return <Lists.Normal {...listData} />;
});

export default Index;
