import React from "react"

import './style.css';

const Xlarge = props => {
  return <div className="skeleton-container">
    <div className="line xl h60"></div>
  </div>
}

export default Xlarge;
