import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
import { FormHook } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const SEARCHOBJECTS = CONFIG.application.company.objects;
/* CONSTANTS */

const search = observer(({props, handleClickAway}) => {

  const handleCancel = () => {
    handleClickAway();
  }

  const handleSearch = () => {
    handleClickAway();
    const queryString = Object.keys(values).map(key => key + '=' + values[key]).join('&');
    props.history.push('/company/search?' + queryString);
  }

  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleSearch, SEARCHOBJECTS);

  const name = { ...SEARCHOBJECTS.name, error: error.name, value: values.name, onChange };
  const searchButton = { fullWidth: false, size: "small" }
  const cancelButton = { fullWidth: false, size: "small", onClick: handleCancel }

  return <form onSubmit={onSubmit} noValidate>
    <Inputs.Text {...name} />
    <br/>
    <br/>
    <Buttons.Normal {...searchButton}>Search</Buttons.Normal>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
  </form>

});

export default search;
