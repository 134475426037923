import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ContactsUpdateStore from '../stores/update';
/* stores */

import CONFIG from '../../config';

const Index = ({token, companyId}) => {
  const companyUpdateContext  = useContext(ContactsUpdateStore);
  const companyUpdateMethod = async (body) => {
    companyUpdateContext.setLoading(true);
    CONFIG.application.company.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.company.api.endpoints.update.url + companyId,
      config: {
        method: CONFIG.application.company.api.endpoints.update.method,
        headers: CONFIG.application.company.api.headers,
        body
      }
    });
    companyUpdateContext.setStatus(fetch[0]);
    companyUpdateContext.setData(fetch[1]);
    companyUpdateContext.setLoading(false);
  }
  const companyUpdateData = companyUpdateContext.isData();

  return { companyUpdateContext, companyUpdateMethod, companyUpdateData }
}

export default Index;
