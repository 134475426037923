import React, { Fragment } from 'react';

import { Skeletons } from '../../util-atomic';

const Loader = props => {
  return <Fragment>
    <Skeletons.Medium />
    <Skeletons.Small />
  </Fragment>
}

export default Loader;
