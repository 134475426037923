import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Buttons } from '../../lib-atomic';
/* packages */

const action = ({order}) => {

  const { id, orderStatusName } = order;

  /* data props */
  const linkButton = { size: "small" }
  /* data props */

  return <Fragment>

    <Buttons.Primary {...linkButton}>
      <Link to={"/orders/detail/" + id}>Back to detail</Link>
    </Buttons.Primary>  

    <br/>
    <br/>

  </Fragment>

}

export default action;
