import React, { useEffect, Fragment } from 'react';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import useForm from '../../util-hook-useform';
import useSuggest from '../../util-hook-usesuggest';
import validation from '../../util-inputs-validation';
import Input from '../../util-mui-inputs';
import { Buttons } from '../../util-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import StepsLoader from '../loader/steps';
import ContactInfo from '../../app-module-company-contacts-detail/info';
/* module */

/* functions */
import { ContactList } from '../../app-module-contact-container/functions';
/* functions */

import CONFIG from '../config';

const Index = observer(({ handleNext, handleBack, handleReset, handleCollection, collection }) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const formObjects = CONFIG.application.contact.objects;
  const companyId = collection.company ? collection.company.id : false;

  /* states */
  const { contactListContext, contactListMethod, contactListData } = ContactList({CONFIG, token, companyId});

  useEffect(() => {
    if (companyId) contactListMethod();
  }, [companyId]);

  useEffect(() => {
    if (collection.contact) loadFromCollection();
  }, [collection]);
  /* states */

  const contactSource = contactListData ? contactListData.contacts : false;

  const loadFromCollection = () => {
    onLoadCompany(JSON.stringify({suggestion: collection.contact}));
  }

  const contactNext = () => {
    handleNext();
  }

  const contactBack = () => {
    handleBack();
  }

  const contactTemplate = (data) => {
    return <ContactInfo {...data} />
  }

  const contactValidate = () => {
    handleNext();
    const { suggestion } = JSON.parse(selectedContact);
    handleCollection('contact', suggestion);
  }

  const contactAdd = (data) => {
    if (!data) return handleValue('contact', '');
    const { suggestion } = JSON.parse(data);
    return handleValue('contact',  String(suggestion.id));
  }

  const [
    onLoadCompany,
    onResetContact,
    isSelectedContact,
    selectedContact,
    suggestionContact
  ] = useSuggest(contactAdd, contactTemplate, contactSource, 'firstName');

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(contactValidate, validation, formObjects, false);

  /* form: stepTwo */
  const contactFormObject = { ...formObjects.contact, errors: errors.contact, value: values.contact, handleChange: handleChange };
  /* form: stepTwo */

  if (!contactSource) return <StepsLoader />

  const isContactCollection = collection.contact ? true : false;

  return <form onSubmit={handleSubmit} noValidate>
    <p>Select a contact person</p>
    <Input.Suggest {...contactFormObject} suggestionProps={suggestionContact} isSelected={isSelectedContact} selectedValue={selectedContact} displayTemplate={contactTemplate}>
      <Buttons.PrimarySmall onClick={onResetContact}>Remove</Buttons.PrimarySmall>
    </Input.Suggest>
    <hr />
    <Buttons.Normal>Select and continue</Buttons.Normal>
    <br/>
    <hr />
    <Buttons.DefaultSmallNon onClick={contactBack}>Back</Buttons.DefaultSmallNon>
    { isContactCollection && <Buttons.DefaultSmallNon onClick={contactNext}>Next</Buttons.DefaultSmallNon> }
  </form>

});

export default Index;
