import Wrapper from './wrapper';
import Title from './title';
import SubTitle from './sub-title';
import ModuleName from './module-name';
import ModuleLink from './module-link';

export default {
  Wrapper,
  Title,
  SubTitle,
  ModuleName,
  ModuleLink
}
