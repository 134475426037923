import Hidden from './hidden';
import Text from './text';
import Password from './password';
import Textarea from './textarea';
import Suggest from './suggest';
import Checkbox from './checkbox';
import Select from './select';
import Date from './date';
import DateModal from './date-modal';

export default {
  Hidden,
  Text,
  Password,
  Textarea,
  Suggest,
  Checkbox,
  Select,
  Date,
  DateModal
}
