import React, { Fragment } from "react";

/* mui */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
/* mui */

/* packages */
import {
  ModuleWrapperMd,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
import { ModuleSmallTitle } from "../../lib-atomic-design-section";
/* packages */

/* modules */
import OrderDisplayStatus from "../../app-module-order-section-status/default";
/* modules */

const index = ({
  id,
  orderStatusName,
  paymentType,
  paymentTypeName,
  purchaseOrder,
  notes,
  comments,
  enrolmentTraderName,
}) => {
  /* data props */
  const statusData = {
    orderStatusName,
    paymentType,
    paymentTypeName,
    enrolmentTraderName,
  };
  /* data props */

  return (
    <ModuleWrapperMd>
      <ModulePaperWrapper>
        <ModuleSmallTitle>ORDER</ModuleSmallTitle>
        <Typography variant="h6">
          <b>#{id}</b>
        </Typography>
        <OrderDisplayStatus {...statusData} />

        {purchaseOrder && (
          <Fragment>
            <ModuleSmallTitle>PO NUMBER</ModuleSmallTitle>
            <Typography variant="body2">
              <b>{purchaseOrder}</b>
            </Typography>
          </Fragment>
        )}

        {notes && (
          <Fragment>
            <ModuleSmallTitle>NOTE</ModuleSmallTitle>
            <Typography variant="body2">
              <i>{notes}</i>
            </Typography>
          </Fragment>
        )}

        {comments && (
          <Fragment>
            <ModuleSmallTitle>COMMENT</ModuleSmallTitle>
            <Typography variant="body2">
              <i>{comments}</i>
            </Typography>
          </Fragment>
        )}
      </ModulePaperWrapper>
    </ModuleWrapperMd>
  );
};

export default index;
