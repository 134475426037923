import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-header-label';

import CONFIG from '../../../config';

const itemLabel = ({reportData}) => {
  if (!reportData) return null;
  const { totalSales, totalIncomes, sales, incomes } = reportData;
  const subset = { totalSales, totalIncomes, sales, incomes };
  const columnLength = (reportData) => {
    var count = 0;
    Object.keys(reportData).map((key) => {
      const value = reportData[key]
      if (typeof value === 'object') {
        count = count + Object.keys(value).length;
      } else {
        count = count + 1;
      }
      return null;
    })
    return count
  }
  var d = Math.pow(10,1);
  const widthPercent = (parseInt((100 / columnLength(subset))*d)/d).toFixed(1);
  return <View style={[styles.container, styles.itemsWrapper]}>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Gift Card Sales</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Service Fees</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Greeting Card Fees</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Admin Fees</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Car Wash</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Courier</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Valet</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Hands</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Delivery</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Miscell</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Parknm</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={[styles.headerLabelText, {fontFamily: 'Helvetica-Bold'}]}>Total</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Cash</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Eft-Pos</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Visa</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>MasterCard</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Amex</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Diners</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerLabelText}>Gift Card</Text>
    </View>
    <View style={[styles.headerLabelContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={[styles.headerLabelText, {fontFamily: 'Helvetica-Bold'}]}>Total</Text>
    </View>
  </View>
}

export default itemLabel;
