import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import Store from './store';
/* stores */

const index = ({CONFIG}) => {
  const orderPrintCountContext  = useContext(Store);
  const orderPrintCountMethod = async ({token, orderId}) => {
    orderPrintCountContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.order.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.order.api.endpoints.print_count.url + orderId + "/printInvoice",
      config: {
        method: CONFIG.application.order.api.endpoints.print_count.method,
        headers: CONFIG.application.order.api.headers
      }
    });
    orderPrintCountContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { orderPrintCountContext, orderPrintCountMethod }
}

export default index;
