import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles';

import CONFIG from '../../../config';
const { logo, logo_lg_pdf } = CONFIG.setup.assets;

const header = props => {
  return <Fragment>
    <View style={[styles.container, styles.headerWrapper]}>
      <View style={styles.headerContainer}>
        <Image style={styles.logoHeader} src={logo_lg_pdf} />
      </View>
      <View style={styles.headerContainer}>
        <View style={styles.textContainer}>
          <Text>Westfield Gift Cards c/-Scentre (New Zealand) Limited</Text>
          <Text>P O Box 109-280</Text>
          <Text>New Market</Text>
          <Text>GST No 57-630-167</Text>
        </View>
      </View>
    </View>
  </Fragment>
}

export default header;
