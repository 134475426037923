import React from 'react';

import Typography from '@material-ui/core/Typography';

const SubTitle = props => {
  return <Typography variant="subtitle2" gutterBottom>
    { props.children }
  </Typography>
}

export default SubTitle;
