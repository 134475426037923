import Normal from './normal';
import Primary from './primary';
import Text from './text';
import Box from './box';

export default {
  Normal,
  Primary,
  Text,
  Box
}
