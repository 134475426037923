import React from "react";

import { withStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import MuiTableCell from "@material-ui/core/TableCell";
const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);
/*
:properties
config
item - data object itself
*/

const column = ({ config, item }) => {
  return config.map((conf, index) => {
    const content = conf.template
      ? conf.template(item[conf.field], item)
      : item[conf.field];

    return <TableCell>{content}</TableCell>;
    // return (
    //   <Grid item xs key={index}>
    //     {content}
    //   </Grid>
    // );
  });
};

export default column;
