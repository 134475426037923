import React from 'react';

import { Skeletons } from '../../lib-atomic';
import { ModuleWrapperXs } from '../../lib-atomic-design-section';

const loader = props => {
  return <ModuleWrapperXs>

    <Skeletons.Small />
    <Skeletons.Medium />
    <Skeletons.Medium />
    <Skeletons.Small />

    <Skeletons.Small />
    <Skeletons.Medium />
    <Skeletons.Medium />
    <Skeletons.Small />

    <Skeletons.Small />
    <Skeletons.Medium />
    <Skeletons.Medium />
    <Skeletons.Small />

    <Skeletons.Small />
    <Skeletons.Medium />
    <Skeletons.Medium />
    <Skeletons.Small />

    <Skeletons.Small />
    <Skeletons.Medium />
    <Skeletons.Medium />
    <Skeletons.Small />

  </ModuleWrapperXs>
}

export default loader;
