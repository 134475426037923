import React, { useContext } from "react";

/* packages */
import Fetch from "../../../util-fetch";
/* packages */

/* stores */
import InfoStore from "./store";
/* stores */

const Index = ({ CONFIG, token, companyId, contactId }) => {
  const contactInfoContext = useContext(InfoStore);
  const contactInfoMethod = async () => {
    contactInfoContext.setLoading(true);
    CONFIG.application.contacts.api.headers.Authorization = token;
    const fetch = await Fetch({
      url:
        CONFIG.application.contacts.api.endpoints.detail.url +
        companyId +
        "/contacts",
      config: {
        method: CONFIG.application.contacts.api.endpoints.detail.method,
        headers: CONFIG.application.contacts.api.headers
      }
    });
    contactInfoContext.setStatus(fetch[0]);
    contactInfoContext.setData(fetch[1], contactId);
    contactInfoContext.setLoading(false);
  };
  const contactInfoData = contactInfoContext.isData();

  return { contactInfoContext, contactInfoMethod, contactInfoData };
};

export default Index;
