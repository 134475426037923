import React, { useEffect, useContext } from "react";
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* stores */
import { Report19, Report20, Report21 } from '../../app-module-reporting-container/stores';
/* stores */

/* modules */
import Report19Loader from '../loader/report19';
import Report20Loader from '../loader/report20';
import Report21Loader from '../loader/report21';
/* modules */

const index = observer((props) => {

  /* ---------------- REPORT 19 */
  /* stores contexts */
  const report19Context  = useContext(Report19);
  const report20Context  = useContext(Report20);
  const report21Context  = useContext(Report21);
  /* stores contexts */

  /* logout if login not available */
  const { login } = LocalStorage.getLS();
  if (!login) return <Redirect to='/u/logout' />
  
  /* logout > token expire */
  useEffect(() => {
    // console.log(report19Context.expireToken);
    // console.log(report20Context.expireToken);
    if (report19Context.expireToken || report20Context.expireToken || report21Context.expireToken) props.history.push('/u/landing-token-expire');
  }, [report19Context.expireToken, report20Context.expireToken, report21Context.expireToken]);

  /* loader */
  if (report19Context.loading) return <Report19Loader />
  /* ---------------- REPORT 19 */

  /* loader */
  if (report20Context.loading) return <Report20Loader />
  /* ---------------- REPORT 20 */

  /* loader */
  if (report21Context.loading) return <Report21Loader />
  /* ---------------- REPORT 21 */

  return props.children;

});

export default index;
