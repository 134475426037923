import React from 'react';

import { PDFViewer } from "@react-pdf/renderer";

/* module */
import PDFDocument from './document';
/* module */

const PDFPreview = props => {
  const { pdfPreviewData, pdfPreviewSize } = props;
  return <PDFViewer {...pdfPreviewSize}>
    <PDFDocument {...pdfPreviewData} />
  </PDFViewer>
}

export default PDFPreview;
