import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* packages */
import Box from '../../util-box';
import ListingX from "../../util-list";
import Listing from "../../util-mui-list";
import Skeleton from "../../util-skeleton";
import Fetch from '../../util-fetch';
import Alert from '../../util-alert';
import Inputsx from '../../util-inputs';
import Button from '../../util-mui-buttons';
import Input from '../../util-mui-inputs';
import useForm from '../../util-hook-useform';
import Initial from '../../util-hook-useform/initial';
import useSuggest from '../../util-hook-usesuggest';
import validation from '../../util-inputs-validation';
import Stores from '../../app-plugin-stores';
import Breadcrumbs from '../../util-mui-breadcrumbs';
import DesignSectionX from '../../util-design-section';
import DesignSection from '../../util-mui-design-section';
import { Skeletons } from '../../util-atomic';

import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import CompanyLoader from '../loader';
import CompanyNav from './nav';
import CompanyTitle from './title';
import CompanyForm from './form';
/* module */

/* functions */
import CompanyUpdate from '../libs/functions/update';
import CompanyDetail from '../../app-module-company-detail/libs/functions/detail';
/* functions */

import CONFIG from '../config';

const Index = observer((props) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const formObjects = CONFIG.application.company.objects;
  const { companyId } = props.match.params;

  /* states */
  const { companyDetailContext, companyDetailMethod, companyDetailData } = CompanyDetail({token, companyId});
  const { companyUpdateContext, companyUpdateMethod, companyUpdateData } = CompanyUpdate({token, companyId});

  useEffect(() => {
    companyDetailMethod();
  }, []);

  useEffect(() => {
    if (companyDetailData) handleEditValues(companyDetailData);
  }, [companyDetailData]);
  /* states */

  /* handles */
  const handleCompanyUpdate= async () => {
    await companyUpdateMethod(JSON.stringify(values));
    props.history.push('/company/detail/' + companyId);
  }

  const handleEditValues = ({clients}) => {
    const editValues = clients.filter(client => client.id === Number(companyId))[0];
    Object.keys(formObjects).map((key, index) => {
      handleValue(key, editValues[key]);
    });
  }
  /* handles */

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(handleCompanyUpdate, validation, formObjects, false);

  const name = { ...formObjects.name, errors: errors.name, value: values.name, handleChange };
  const emailAddress = { ...formObjects.emailAddress, errors: errors.emailAddress, value: values.emailAddress, handleChange };
  const mobile = { ...formObjects.mobile, errors: errors.mobile, value: values.mobile, handleChange };
  const NZBN = { ...formObjects.NZBN, errors: errors.NZBN, value: values.NZBN, handleChange };
  const physicalAddress1 = { ...formObjects.physicalAddress1, errors: errors.physicalAddress1, value: values.physicalAddress1, handleChange };
  const physicalAddress2 = { ...formObjects.physicalAddress2, errors: errors.physicalAddress2, value: values.physicalAddress2, handleChange };

  /* data : false */
  /* no available data yet... */
  if (!companyDetailData) return <CompanyLoader />
  /* data : false */

  /* data props */
  const infoDetailData = { companyId };
  const companyData = { companyId };
  const formData = { name, emailAddress, mobile, NZBN, physicalAddress1, physicalAddress2, onSubmit: handleSubmit }
  const navData = { companyId };
  /* data props */

  return <Row>
    <Col xs="12" sm="12" md="12" lg="12" xl="12">
      <CompanyNav {...navData} />
      <CompanyTitle />
    </Col>
    <Col xs="12" sm="12" md="6" lg="3" xl="3">
      <CompanyForm {...formData} />
    </Col>
  </Row>

});

export default Index;

//<CompanyEdit data={companyFormData} />
