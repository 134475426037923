import React from "react"
import PropTypes from 'prop-types';

/* style */
import './styles.css';
/* reactstrap */
import { Progress } from 'reactstrap';

const Loader = (props) => {
  return <Progress className="mb-0 atom-el-loader" value="100" animated />
}

export default Loader;
