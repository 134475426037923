import React from 'react';

const icon = props => {
  return <svg>
    <circle {...props} cx="53.792" cy="53.311" r="53.311"/>
    <circle {...props} cx="404.908" cy="53.311" r="53.311"/>
    <circle {...props} cx="229.35" cy="53.311" r="53.311"/>
    <circle {...props} cx="53.472" cy="229.19" r="53.312"/>
    <circle {...props} cx="404.587" cy="229.19" r="53.312"/>
    <circle {...props} cx="229.03" cy="229.19" r="53.312"/>
    <circle {...props} cx="53.472" cy="405.068" r="53.311"/>
    <circle {...props} cx="404.587" cy="405.068" r="53.311"/>
    <circle {...props} cx="229.03" cy="405.068" r="53.311"/>
  </svg>
}

export default icon;
