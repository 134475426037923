import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ListStore from './store';
/* stores */

const Index = ({CONFIG, token}) => {
  const productCat4ListContext  = useContext(ListStore);
  const productCat4ListMethod = async () => {
    productCat4ListContext.setLoading(true);
    CONFIG.application.product.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.product.api.endpoints.list_Cat4.url,
      config: {
        method: CONFIG.application.product.api.endpoints.list_Cat4.method,
        headers: CONFIG.application.product.api.headers
      }
    });
    productCat4ListContext.setStatus(fetch[0]);
    productCat4ListContext.setData(fetch[1]);
    productCat4ListContext.setLoading(false);
  }
  const productCat4ListData = productCat4ListContext.isData();

  return { productCat4ListContext, productCat4ListMethod, productCat4ListData }
}

export default Index;
