import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import UpdateStore from './store';
/* stores */

const Index = ({CONFIG, token, orderId}) => {
  const orderUpdateContext  = useContext(UpdateStore);
  const orderUpdateMethod = async (body) => {
    orderUpdateContext.setLoading(true);
    CONFIG.application.order.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.order.api.endpoints.update.url + orderId,
      config: {
        method: CONFIG.application.order.api.endpoints.update.method,
        headers: CONFIG.application.order.api.headers,
        body
      }
    });
    orderUpdateContext.setStatus(fetch[0]);
    orderUpdateContext.setData(fetch[1]);
    orderUpdateContext.setLoading(false);
  }
  const orderUpdateData = orderUpdateContext.isData();

  return { orderUpdateContext, orderUpdateMethod, orderUpdateData }
}

export default Index;
