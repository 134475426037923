import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Feedback from '../util-mui-inputs-feedback';

const Select = props => {
  const { name, type, label, helpText, options, errors, value, handleChange } = props;
  const error = (errors) ? true : false;
  return <div>
    <TextField
      select
      fullWidth
      error={error}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      helperText={helpText}
      variant="outlined"
      margin="dense"
    >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.name}
      </MenuItem>
    ))}
    </TextField>
    <Feedback.Invalid errors={errors} />
  </div>
}

export default Select;
