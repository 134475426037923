import React, { useContext } from 'react'
import { Route, Switch } from "react-router-dom";

import Conf from './config';

import Default from './default'

const Atom = props => {
  const url = props.match ? props.match.url : '';
  const prefix = Conf.routes.prefix;
  return <Switch>
    <Route exact path={url + prefix} component={Default} />
  </Switch>
}

export default Atom;
