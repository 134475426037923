import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Product List";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    product: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          list_Cat1: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=1',
            url: setup.setup.api.westfield_v1.url[environment] + "/products/?categoryId=1",
            method: 'GET'
          },
          list_Cat2: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=2',
            url: setup.setup.api.westfield_v1.url[environment] + "/products/?categoryId=2",
            method: 'GET'
          },
          list_Cat3: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=3',
            url: setup.setup.api.westfield_v1.url[environment] + "/products/?categoryId=3",
            method: 'GET'
          },
          list_Cat4: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=4',
            url: setup.setup.api.westfield_v1.url[environment] + "/products/?categoryId=4",
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Products",
    prefix: "/product/list"
  }
}

export default config;
