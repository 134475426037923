import React from 'react';

import Typography from '@material-ui/core/Typography';

const Title = props => {
  return <Typography variant="subtitle2" gutterBottom>
    <b>{ props.children }</b>
  </Typography>
}

export default Title;
