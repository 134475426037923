import React from "react"

import './background.css';
import './color.css';
import './typography.css';
import './spacing.css';
import './button.css';
import './alert.css';
import './reset.css';
import './style.css';

const Style = props => {
  return <div className="design-systems">
    {props.children}
  </div>
}

export default Style;
