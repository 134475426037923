import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

/* modules */
import Feeds from './feeds';
/* modules */

const Invalid = ({errors}) => {
  if (!errors) return null;
  const data = { errors }
  return <FormHelperText error>
    <Feeds {...data} />
  </FormHelperText>
}

export default Invalid;
