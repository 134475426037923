import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";
import { NumberCurrency } from "../../../../lib-atomic-functions";

const productsItem = (product) => {
  const valueOfProd = parseFloat(product.value);
  const total = NumberCurrency((product.quantity * valueOfProd).toFixed(2));
  const value_currency = NumberCurrency(valueOfProd.toFixed(2));
  let startCardNumber = product.startCardNumber
    ? product.startCardNumber.substring(0, 6)
    : null;
  startCardNumber = product.startCardNumber
    ? product.startCardNumber.replace(startCardNumber, "")
    : null;
  let endCardNumber = product.endCardNumber
    ? product.endCardNumber.substring(0, 6)
    : null;
  endCardNumber = product.endCardNumber
    ? product.endCardNumber.replace(endCardNumber, "")
    : null;
  console.log(product, startCardNumber, endCardNumber);
  return (
    <View style={[styles.container]}>
      {product.isCardNumberExist && (
        <View style={styles.itemCardNoContainer}>
          <Text style={styles.StartEndNumber}>{startCardNumber} </Text>
        </View>
      )}
      {product.isCardNumberExist && (
        <View style={styles.itemCardNoContainer}>
          <Text style={styles.StartEndNumber}>{endCardNumber} </Text>
        </View>
      )}
      <View style={styles.qtyContainer}>
        <Text
          style={[
            styles.alignRightContainer,
            {
              paddingLeft: 1,
              paddingRight: product.isCardNumberExist ? 5 : 25,
            },
          ]}
        >
          {product.quantity}
        </Text>
      </View>
      <View style={[styles.otherContainer]}>
        <Text
          style={[
            styles.alignRightContainer,
            { paddingRight: product.isCardNumberExist ? 15 : 25 },
          ]}
        >
          ${value_currency}
        </Text>
      </View>
      <View style={styles.itemContainer}>
        <Text>{product.details.productName}</Text>
      </View>
      <View style={[styles.totalContainer, styles.alignRightContainer]}>
        <Text>${total}</Text>
      </View>
    </View>
  );
};

export default productsItem;
