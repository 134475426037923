import role0 from './role0';
import role1 from './role1';
import role2 from './role2';
import role3 from './role3';

export default [
    role0,
    role1,
    role2,
    role3
]