import React, { useContext, useEffect } from 'react';

import { StyleSheet, Font } from "@react-pdf/renderer";

// Register font
const source = 'https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNzbP97U9sKh0jjxbPbfOKg.ttf'
Font.register({ family: 'Cera', src: source });

export default StyleSheet.create({
  page: {
    flex: 1,
    flexDirection: "column",
    fontSize: 10,
    fontWeight: "normal",
    paddingTop: 47,
    paddingBottom: 47,
    fontFamily: 'Cera'
  },
  container: {
    paddingLeft: 47,
    paddingRight: 47,
    flexDirection: "row",
  },
  headerWrapper: {
  },
  headerContainer: {
    flex: 2,
    textAlign: "left",
    //backgroundColor: "purple",
    paddingTop: 3,
    paddingBottom: 3,
  },
  logoHeader: {
    width: 150,
    height: 32
  },
  columnDirection: {
    flexDirection: "column",
  },
  SectionTitle: {
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  titleContainer: {
    flex: 1,
    textAlign: "left",
    //backgroundColor: "green",
    paddingTop: 3,
    paddingBottom: 3,
  },
  titleWrapper: {
  },
});
