import React, { useEffect, useRef, useState } from 'react'

import { FormGroup, CustomInput, Label, Input } from 'reactstrap';

import Feeds from '../feedbacks';

const Checkbox = props => {

  const { name, initial, type, label, errors, value, handleChange } = props;
  return <FormGroup className="space-semi-top space-semi-bottom">
    {value && <CustomInput type="checkbox" checked="checked" name={name} id={name} label={label} /> }
    {!value && <CustomInput type="checkbox" name={name} id={name} label={label} /> }
  </FormGroup>

}

export default Checkbox;
