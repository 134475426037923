import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  moduleTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
}));
/* CONSTANTS */

const title = props => {
  
  /* mui */
  const classes = useStyles();
  /* mui */  

  return <Typography variant="h5">
    <b>{ props.children }</b>
  </Typography>

}

export default title;
