import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';

/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const text = (props) => {
  const disabled = props.disabled ? true : false;
  return <Fragment>
    <TextField {...props} disabled={disabled} fullWidth variant="outlined" margin="dense" />
    <Invalid {...props} />
  </Fragment>
}

export default text;
