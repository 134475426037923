import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order List";
const server = null;
const service = null;
const initDate = new Date();
const initDateFormat = initDate.toISOString().split('T')[0]; // yyyy-mm-dd
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Authorization': null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders",
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Orders list",
    prefix: "/orders/search"
  },
  setup,
  environment
}

export default config;
