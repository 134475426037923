import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

/*
: properties
maxItems
*/

const normal = props => {
  return <Breadcrumbs aria-label="Breadcrumb">
    { props.children }
  </Breadcrumbs>
}

export default normal;
