import Large from './large';
import Xlarge from './xlarge';
import Medium from './medium';
import Small from './small';

export default {
  Large,
  Xlarge,
  Medium,
  Small
}
