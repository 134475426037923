import React, { useContext, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Moment from "react-moment";
import LuhnJS from "luhn-js";

import { pdf } from "@react-pdf/renderer";

import { Row, Col } from "reactstrap";

/* design */
import DesignSection from "../../app-design-section";
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { FormHook, LocalStorage } from "../../lib-atomic-functions";
import { FetchContextApi } from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleLink,
  ModuleText,
  ModuleImage,
} from "../../lib-atomic-design-section";
import { PDFBlobProviderInvoiceCorp } from "../../app-module-order-page-pdf/program";
/* packages */

/* module */
import ProductDetail from "../../app-module-product-detail/detail";
/* module */

/* stores */
import {
  OrderConfirm,
  OrderEmailInvoice,
} from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const CONFIRMATION = CONFIG.application.order.api.endpoints.confirmation;
const CONFIRMATIONOBJECTS = CONFIG.application.order.api.objects;
const CONFIRMATIONCONFIG = {
  url: CONFIRMATION.url,
  method: CONFIRMATION.method,
  headers: CONFIRMATION.headers,
  responseType: "json",
};
const SENDATTACHMENT = CONFIG.application.order.api.endpoints.sendattachment;
const SENDATTACHMENTCONFIG = {
  url: SENDATTACHMENT.url,
  method: SENDATTACHMENT.method,
  headers: SENDATTACHMENT.headers,
  responseType: "formdata",
};
/* CONSTANTS */

const index = ({
  props,
  previewData,
  blob,
  order,
  company,
  contact,
  product,
  fee,
  product_card,
}) => {
  delete CONFIRMATIONOBJECTS["payment"];
  const { token } = LocalStorage.getLS().login;
  const tokenCorp = LocalStorage.getLS().loginCorp.token;
  const { products } = product_card;
  console.log(product_card, products, " CHECK..");
  const orderId = order.id;
  const isCorporate = order.flCorporate === 1 ? true : false;
  const [confirm, setConfirm] = useState(false);
  const [finalConfirm, finalConfirmSet] = useState(false);

  /* states */
  CONFIRMATIONCONFIG.headers = {
    ...CONFIRMATIONCONFIG.headers,
    Authorization: isCorporate ? tokenCorp : token,
  };

  /* new: appName header if flag as corporate flCorporate  */
  if (isCorporate) {
    CONFIRMATIONCONFIG.headers = {
      ...CONFIRMATIONCONFIG.headers,
      appName: "corp",
    };
  }

  const [orderConfirmContext, orderConfirmMethod] = FetchContextApi(
    CONFIRMATIONCONFIG,
    OrderConfirm
  );
  const [orderEmailInvoiceContext, orderEmailInvoiceMethod] = FetchContextApi(
    SENDATTACHMENTCONFIG,
    OrderEmailInvoice
  );
  /* states */

  const handleGenProducts = (products) => {
    console.log(products);
    console.log(CONFIG.products.cards);
    const { red, hb, ty } = CONFIG.products.cards;
    const productsCards = products.filter(
      (product) => product.subClassId === 3
    );
    const productRed = productsCards.filter(
      (product) => product.productId === red
    );
    const productHb = productsCards.filter(
      (product) => product.productId === hb
    );
    const productTy = productsCards.filter(
      (product) => product.productId === ty
    );
    const isProductRed = productRed.length > 0 ? true : false;
    const isProductHb = productHb.length > 0 ? true : false;
    const isProductTy = productTy.length > 0 ? true : false;
    return [
      productsCards,
      productRed,
      productHb,
      productTy,
      isProductRed,
      isProductHb,
      isProductTy,
    ];
  };
  const [
    productsCards,
    productRed,
    productHb,
    productTy,
    isProductRed,
    isProductHb,
    isProductTy,
  ] = handleGenProducts(products);

  const total_cards = productsCards.reduce(
    (a, b) => a + (b["quantity"] || 0),
    0
  );
  const total_value = products
    .reduce((a, b) => a + (b["value"] * b["quantity"] || 0), 0)
    .toFixed(2);

  const handleAutomateLoop = (products, type, startnumber) => {
    let start = startnumber;
    return products.map((product, index) => {
      const key = "starting_" + type + "_" + index;
      const { quantity } = product;
      let mynum = Number(start);
      start = start.substring(0, 15);
      start = Number(start) + Number(quantity);
      start = LuhnJS.generate(String(start));
      return {
        productId: product.productId,
        value: product.value,
        quantity: product.quantity,
        startCardNumber: mynum,
      };
    });
  };

  async function prepareBlob(previewData) {
    console.log(previewData);
    const blobPrepared = await PDFBlobProviderInvoiceCorp(previewData);
    return blobPrepared;
  }

  const handleConfirmation = async () => {
    finalConfirmSet(true);
    const bodyData = {
      paymentTypeId: values.payment,
      extOrderNumber: values.reference,
      comments: values.note,
      products: [],
    };

    await orderConfirmMethod({
      query: orderId,
      body: JSON.stringify(bodyData),
    });

    if (orderConfirmContext.status) {
      // if order confirmed then send invoice in email
      prepareBlob(previewData).then(async (blobPrepared) => {
        console.log(blobPrepared, " CONSOLE BLOBL");
        let formData = new FormData(); //formdata
        formData.append("file", blobPrepared); //append the values with key, value pair
        const fullName =
          previewData.pdfPreviewData.contactInfoData.firstName +
          " " +
          previewData.pdfPreviewData.contactInfoData.lastName;
        const emailAddress =
          previewData.pdfPreviewData.companyInfoData.clients[0].emailAddress;
        const dataEmail = {
          templateId: "934e91e7-f83e-4c22-b33f-691ab86f0014",
          fullName,
          emailAddress,
          params: {
            name: fullName,
            order_number: orderId,
          },
        };
        // formData.append("fullName", fullName);
        // formData.append("emailAddress", emailAddress);

        formData.append("data", JSON.stringify(dataEmail));
        await orderEmailInvoiceMethod({
          body: formData,
        });

        props.history.push("/orders/l/success-quote-confirm/" + orderId);
      });
    }
  };

  const handleCancel = async () => {
    props.history.push("/orders/detail/" + orderId);
  };

  const handleConfirm = () => {
    setConfirm(!confirm);
  };

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleConfirmation,
    CONFIRMATIONOBJECTS
  );

  const reference = {
    ...CONFIRMATIONOBJECTS.reference,
    error: error.reference,
    value: values.reference,
    onChange,
  };
  // const payment = {
  //   ...CONFIRMATIONOBJECTS.payment,
  //   error: error.payment,
  //   value: values.payment,
  //   onChange,
  // };
  //const delivery = { ...NEWCONFIRMATIONOBJECTS.delivery, error: error.delivery, value: values.delivery, onChange };
  const note = {
    ...CONFIRMATIONOBJECTS.note,
    error: error.note,
    value: values.note,
    onChange,
  };

  /* data props */
  const submitButton = { size: "small" };
  const cancelButton = { size: "small", onClick: handleCancel };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <img src={LANDINGGRAPHICS} width="100%" />
      <ModuleTitle>Almost there!</ModuleTitle>
      <ModuleText>
        You are about to confirm order number <b>{orderId}</b>.
      </ModuleText>
      <br />
      <Inputs.Text {...reference} />
      {/* <Inputs.Select {...payment} /> */}
      <Inputs.Textarea {...note} />
      <hr />
      {!confirm && (
        <section className="space-standard-top space-standard-bottom">
          <Buttons.Primary onClick={handleConfirm}>
            Confirm and submit
          </Buttons.Primary>
        </section>
      )}
      {confirm && (
        <section className="space-standard-top space-standard-bottom">
          <p>
            <b>
              You are about to top up {total_cards} cards totalling $
              {total_value} do you wish to continue
            </b>
          </p>

          {!finalConfirm ? (
            <>
              <Buttons.Normal {...submitButton}>
                Continue and Confirm
              </Buttons.Normal>
              <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
            </>
          ) : (
            <ModuleText>
              Submitting...
            </ModuleText>
          )}
        </section>
      )}
    </form>
  );
};

export default index;
