import React from 'react';

const icon = (props) => {
  return <svg>
    <path {...props} d="M175.216,404.514c-0.001,0.12-0.009,0.239-0.009,0.359c0,30.078,24.383,54.461,54.461,54.461
      s54.461-24.383,54.461-54.461c0-0.12-0.008-0.239-0.009-0.359H175.216z" />
  </svg>
}

export default icon;
