import React from 'react';

import { PDFDownloadLink } from "@react-pdf/renderer";

/* module */
import PDFDocument from './document';
/* module */

const PDFLink = ({filename, data, size}) => {
  return <PDFDownloadLink
    document={<PDFDocument {...data} />}
    fileName={filename}>
    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : filename)}
  </PDFDownloadLink>
}

export default PDFLink;
