const useFetch = async ({ url, config }) => {
  try {
    const response = await fetch(url, config);
    const data = await response.json();
    if (
      response.status !== 202 &&
      response.status !== 200 &&
      response.status !== 204
    )
      throw data;
    // if (!response.ok) throw data;
    return [true, { blob: false, json: data }];
  } catch (e) {
    return [false, { blob: false, json: e }];
  }
};

export default useFetch;
