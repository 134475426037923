import React from 'react';
import TextField from '@material-ui/core/TextField';

import Feedback from '../util-mui-inputs-feedback';

const Password = props => {
  const { name, type, label, helpText, errors, value, handleChange } = props;
  const error = (errors) ? true : false;
  return <div>
    <TextField
      fullWidth
      error={error}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      helperText={helpText}
      type="password"
      variant="outlined"
      margin="dense"
    />
    <Feedback.Invalid errors={errors} />
  </div>
}

export default Password;
