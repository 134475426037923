import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const text = props => {
  return <Typography variant="body2" gutterBottom>
    { props.children }
  </Typography>
}

export default text;
