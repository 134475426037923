import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ListStore from './store';
/* stores */

const Index = ({CONFIG, token, companyId}) => {
  const contactListContext  = useContext(ListStore);
  const contactListMethod = async () => {
    contactListContext.setLoading(true);
    CONFIG.application.contact.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.contact.api.endpoints.list.url + companyId + '/contacts',
      config: {
        method: CONFIG.application.contact.api.endpoints.list.method,
        headers: CONFIG.application.contact.api.headers
      }
    });
    contactListContext.setStatus(fetch[0]);
    contactListContext.setData(fetch[1]);
    contactListContext.setLoading(false);
  }
  const contactListData = contactListContext.isData();

  return { contactListContext, contactListMethod, contactListData }
}

export default Index;
