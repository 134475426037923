import React from "react";

import Grid from "@material-ui/core/Grid";

/* modules */
import Column from "./column-body";
import { TableBody, TableRow } from "@material-ui/core";
/* modules */

/*
:properties
config
data - list / collection - row-body.js
*/

const row = ({ config, data }) => {
  return (
    <TableBody>
      {data.map((item, index) => {
        const columnData = { config, item };
        return (
          <TableRow>
            <Column {...columnData} />
          </TableRow>
        );
      })}
    </TableBody>
  );
  return;
};

export default row;
