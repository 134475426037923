import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  loading = false;
  data = false;
  status = false;
  expireToken = false;

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = data ? data : false;
    this.expireToken = data.status === 400 ? true : false;
  }

}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable
})


export default createContext(new Store())
