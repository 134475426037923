import React, { Fragment } from 'react';

/* mui */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperInfo } from '../../util-atomic-design';
/* packages */

const index = ({contactId, contactFirstName, contactLastName, contactEmail, contactMobile, contactComments, optin, editLink}) => {
  const optInOut = optin === 1 ? "Opt-in" : "Opt-out";

  return <ModuleWrapperInfo>

    <Grid container>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Typography variant="caption">
          <small>CONTACT PERSON</small>
        </Typography>
        <Typography variant="body2">
          <b>{ contactFirstName }</b>
        </Typography>
        <Typography variant="body2">
          <b>{ contactLastName }</b>
        </Typography>
        <Typography variant="body2">
          { contactComments }
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Typography variant="caption">
          <small>CONTACT DETAILS ({optInOut})</small>
        </Typography>
        <Typography variant="body2" noWrap>
          { contactEmail }
        </Typography>
        <Typography variant="body2">
          { contactMobile }
        </Typography>
      </Grid>

    </Grid>

    { editLink && editLink }
  </ModuleWrapperInfo>

}

export default index;
