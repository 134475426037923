import React from "react"

import './style.css';

import { Card, CardBody } from 'reactstrap';

const Box = (props) => {
  const color = props.color;
  return <div className="atom-el-box">
    <Card>
      <CardBody>
        {/*
        <CardTitle className="box-title-center">
          Example Title
        </CardTitle>
        */}
        {props.children}
      </CardBody>
    </Card>
  </div>
}

export default Box;
