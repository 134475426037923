import React from 'react';
import Button from '@material-ui/core/Button';

const DefaultSmallNon = props => {
  const { children, onClick } = props;
  const handles = { onClick }
  return <Button size="small" {...handles} >
    {children}
  </Button>
}

export default DefaultSmallNon;
