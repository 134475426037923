import React from 'react';
import Button from '@material-ui/core/Button';

const NormalSmallBlock = props => {
  const { children, onClick } = props;
  return <Button fullWidth variant="contained" color="primary" size="small" type="submit">
    {children}
  </Button>
}

export default NormalSmallBlock;
