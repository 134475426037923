import React, { useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { Lists } from '../../lib-atomic';
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import { ModuleText } from '../../lib-atomic-design-section';
/* packages */

/* section */
import OrderSectionInfo from '../../app-module-order-section-info/default';
import CompanySectionInfo from '../../app-module-company-detail-section-info/default';
import ContactSectionInfo from '../../app-module-company-contacts-detail-section-info/default';
import OrderSectionProduct from '../../app-module-order-section-product/default';
import OrderSectionTotal from '../../app-module-order-section-total/default';
/* section */

import CONFIG from '../config';

const index = ({ order, company, contact, product, fee }) => {
  
  return <Fragment>
    <OrderSectionInfo {...order} />
    <CompanySectionInfo {...company} />
    <ContactSectionInfo {...contact} />
    <OrderSectionProduct {...product} />
    <OrderSectionProduct {...fee} />
    <OrderSectionTotal {...order } />
  </Fragment>

};

export default index;
