import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import Store from './store';
/* stores */

const index = ({CONFIG}) => {
  const contactUpdateContext  = useContext(Store);
  const contactUpdateMethod = async ({token, companyId, contactId, body}) => {
    contactUpdateContext.setLoading({loading: true, status: true, data: false});
    CONFIG.application.contact.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.contact.api.endpoints.update.url + companyId + '/contacts/' + contactId,
      config: {
        method: CONFIG.application.contact.api.endpoints.update.method,
        headers: CONFIG.application.contact.api.headers,
        body
      }
    });
    contactUpdateContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { contactUpdateContext, contactUpdateMethod }
}

export default index;
