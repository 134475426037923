import React, { useEffect } from "react";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import DetailLoader from "../loader/detail";
import DefaultInfo from "../info/default";
import NormalInfo from "../info/normal";
/* module */

/* functions */
import {
  ProductCat1List,
  ProductCat2List,
  ProductCat3List,
  ProductCat4List
} from "../../app-module-product-container/functions";
/* functions */

import CONFIG from "../config";

const index = observer(({ productId, normalWrapp, cardWrapp }) => {
  const { login } = LocalStorage.getLS();
  const { token } = login;

  /* states */
  const {
    productCat1ListContext,
    productCat1ListMethod,
    productCat1ListData
  } = ProductCat1List({ CONFIG, token });
  const {
    productCat2ListContext,
    productCat2ListMethod,
    productCat2ListData
  } = ProductCat2List({ CONFIG, token });
  const {
    productCat3ListContext,
    productCat3ListMethod,
    productCat3ListData
  } = ProductCat3List({ CONFIG, token });
  const {
    productCat4ListContext,
    productCat4ListMethod,
    productCat4ListData
  } = ProductCat4List({ CONFIG, token });

  const productsAll =
    productCat1ListData &&
    productCat2ListData &&
    productCat3ListData &&
    productCat4ListData
      ? [
          ...productCat1ListData.products,
          ...productCat2ListData.products,
          ...productCat3ListData.products,
          ...productCat4ListData.products
        ]
      : false;

  useEffect(() => {
    // productCat1ListMethod();
    // productCat2ListMethod();
    // productCat3ListMethod();
    // productCat4ListMethod();
    if (!productCat1ListContext.data) productCat1ListMethod();
    if (!productCat2ListContext.data) productCat2ListMethod();
    if (!productCat3ListContext.data) productCat3ListMethod();
    if (!productCat4ListContext.data) productCat4ListMethod();
  }, [productId]);
  /* states */

  /* data : false */
  /* no available data yet... */
  if (!productsAll) return <DetailLoader />;
  /* data : false */

  /* data props */
  const infoData = productsAll.filter(
    product => product.productId === Number(productId)
  )[0];
  /* data props */

  if (cardWrapp) return <DefaultInfo {...infoData} />;
  return <NormalInfo {...infoData} />;
});

export default index;
