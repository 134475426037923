import Tranxactor from "./tranxactor";
import Notification from "./notification";
import Setting from "./setting";
import Drawer from "./drawer";
import Menu from "./menu";

export default {
  Tranxactor,
  Notification,
  Setting,
  Drawer,
  Menu
};
