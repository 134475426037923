import React from "react";
import { Route, Switch } from "react-router-dom";

import LandingXS from "./layout/landing-xs";
import ThqProgram from "./layout/thq-program";
import ThqMain from "./layout/thq-main";

const App = (props) => {
  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};
  return (
    <Switch>
      <Route path="/u" component={LandingXS} />
      <Route path="/p" component={ThqProgram} />
      <Route path="/:module" component={ThqMain} />
    </Switch>
  );
};

export default App;
