import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Create (bulk)";
const server = null;
const service = null;
const minTopup = 10;
const maxTopup = 995;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    product: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          list_Cat1: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=1",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=1",
            method: "GET",
          },
          list_Cat2: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=2",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=2",
            method: "GET",
          },
          list_Cat3: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=3",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=3",
            method: "GET",
          },
          list_Cat4: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=4",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=4",
            method: "GET",
          },
        },
      },
    },
    company: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients",
            method: "GET",
          },
          listcorp: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-Corp/clients",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/accounts",
            method: "GET",
          },
        },
      },
      objects: {
        company: {
          name: "company",
          initial: "",
          type: "text",
          label: "Select a Company",
          helpText: false,
          rules: ["isNotNull"],
        },
      },
    },
    order: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          create: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders",
            method: "PUT",
          },
        },
      },
      objects: {
        product: {
          name: "product",
          initial: "",
          type: "text",
          label: "Select a Product",
          helpText: false,
          rules: ["isNotNull"],
        },
        value: {
          name: "value",
          initial: "",
          type: "text",
          label: "Amount",
          helpText: false,
          rules: ["isNotNull", "isNumber", "isMinVal", "isMaxVal"],
          suffix: "$",
          minLimit: minTopup,
          maxLimit: maxTopup,
        },
        quantity: {
          name: "quantity",
          initial: "",
          type: "text",
          label: "Quantity",
          helpText: false,
          rules: ["isNotNull", "isNumber"],
        },
      },
      objects_carrier: {
        product: {
          name: "product",
          initial: "",
          type: "text",
          label: "Select a Product",
          helpText: false,
          rules: ["isNotNull"],
        },
        value: {
          name: "value",
          initial: "",
          type: "text",
          label: "Amount",
          helpText: false,
          rules: ["isNotNull", "isNumber"],
        },
        quantity: {
          name: "quantity",
          initial: "",
          type: "text",
          label: "Quantity",
          helpText: false,
          rules: ["isNotNull", "isNumber"],
        },
      },
      responses: {
        failTotal: "Total must not exceed $" + maxTopup,
      },
    },
    contact: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
        },
      },
      objects: {
        contact: {
          name: "contact",
          initial: "",
          type: "text",
          label: "Select a Contact",
          helpText: false,
          rules: ["isNotNull"],
        },
      },
    },
    fees: {
      api: {
        headers: null,
        endpoints: {
          create: {
            doc: null,
            url: null,
            method: null,
          },
        },
      },
      objects: {
        product: {
          name: "product",
          initial: "",
          type: "text",
          label: "Select Fees",
          helpText: false,
          rules: ["isNotNull"],
        },
        value: {
          name: "value",
          initial: "",
          type: "text",
          label: "Amount",
          helpText: false,
          rules: ["isNumber"],
        },
        // quantity: { name: "quantity", initial: "1", type: "text", label: "Quantity", helpText: false, rules: ["isNotNull"] }
      },
    },
    notes: {
      api: {
        headers: null,
        endpoints: {
          create: {
            doc: null,
            url: null,
            method: null,
          },
        },
      },
      objects: {
        purchaseOrder: {
          name: "purchaseOrder",
          initial: "",
          type: "text",
          label: "Purchase Order",
          helpText: false,
          rules: false,
        },
        notes: {
          name: "notes",
          initial: "",
          type: "text",
          label: "Note",
          helpText: false,
          rules: false,
        },
      },
    },
  },
  routes: {
    label: "Create (bulk)",
    prefix: "/orders/create",
  },
  setup,
  environment,
  minTopup,
  maxTopup,
};

export default config;
