const isMin = (data) => {
  let value = data.value;
  const config = data.config;
  const limit = config.maxLimit;
  const suffix = config.suffix;
  value = value.trim();
  return (value <= limit) ? true : "maximum of " + suffix + limit;
}

export default isMin;
