import React, { useState, Fragment } from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
/* mui */

/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
import { FormHook } from '../../lib-atomic-functions';
/* packages */

/* forms */
import Search from '../form/search';
/* forms */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const index = props => {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  /* data props */
  
  const searchData = { ...props, handleClickAway }
  const searchButton = { fullWidth: false, size: "small", onClick: handleClick }
  /* data props */

  return <Grid container>
    <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
      <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fragment>
        <Buttons.Text {...searchButton}>
          <SearchIcon fontSize="small" /> &nbsp; Search
        </Buttons.Text>
        { open &&
          <Paper className={classes.paper}>
          <Search {...searchData} />
          </Paper>
        }
        </Fragment>
      </ClickAwayListener>
      </div>
    </Grid>
  </Grid>
}

export default index;
