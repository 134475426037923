import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-index';

import CONFIG from '../../../config';
const { logo, logo_lg_pdf } = CONFIG.setup.assets;

const header = props => {
  return <Fragment>
    <View style={[styles.container, styles.headerWrapper]}>
      <View style={styles.headerContainer}>
        <Image style={styles.logoHeader} src={logo_lg_pdf} />
      </View>
    </View>
  </Fragment>
}

export default header;
