export default {
  project: "Westfield",
  name: "Westfield",
  url: "http://thq-westfield.s3-website-ap-southeast-2.amazonaws.com",
  auth_landing_module: "/ordering",
  idle_limit: 180000, //180000,
  thor: {
    moduleCode: {
      production: "WGMB",
      development: "WGMB"
    },
    moduleCodeCorp: {
      production: "WSH2kdh2bcks",
      development: "WSH2kdh2bcks"
    },
    programId: null,
    products: {
      production: {
        cards: {
          red: 380172,
          hb: 724985,
          ty: 724984
        }
      },
      development: {
        cards: {
          red: 380172,
          hb: 724986,
          ty: 724985
        }
      }
    }
  },
  api: {
    westfield_auth_v1: {
      url: {
        production: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth",
        development: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth"
      }
    },
    westfield_v1: {
      url: {
        production: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder",
        development: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder"
      }
    },
    westfield_reporting_v1: {
      url: {
        production: "https://tr4ns10.tr4ns.com",
        development: "https://tr4ns10.tr4ns.com"
      }
    },
    tranxactor_westfield_corp: {
      url: {
        production: "https://tr4ns10.tr4ns.com/Westfield-Corporate",
        development: "https://tr4ns10.tr4ns.com/Westfield-Corporate"
      }
    }
  }
};
