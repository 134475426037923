import React, { useState } from "react";

import { LocalStorage } from "../../components/lib-atomic-functions";

// new imports

import App from "./imports-app";
import Icon from "../../components/util-svgs";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import Logo from "./imports-logo";

import appConf from "../../components/app-conf";

////////
const logo = appConf[process.env.REACT_APP_CLIENT].assets.logo;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1
  },
  username: {
    color: "grey",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  signOutBtn: {
    color: "#42a5f5",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  appBar: {
    backgroundColor: "#ffffff",
    borderBottomColor: "transparent",
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    backgroundColor: "#ffffff",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "space-between"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

const importNewNav = props => {
  const classes = useStyles();
  const { login } = LocalStorage.getLS();
  const { username } = login;
  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <div className="bg-light border-right" id="thq-sidebar-wrapper">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isToggled
        })}
      >
        <Toolbar>
          <Typography
            style={{ marginLeft: isToggled ? 10 : 60 }}
            variant="h8"
            noWrap
            className={classes.title}
          >
            <img
              style={{ display: isToggled ? "none" : "block" }}
              src={logo}
              width="100"
            />
          </Typography>
          <Button onClick={() => {}} className={classes.username}>
            &nbsp;{username}&nbsp;
          </Button>
          <Button
            onClick={() => {
              props.history.push("/u/logout");
            }}
            className={classes.signOutBtn}
          >
            &nbsp;Sign out&nbsp;
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isToggled,
          [classes.drawerClose]: !isToggled
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isToggled,
            [classes.drawerClose]: !isToggled
          })
        }}
        open={isToggled}
      >
        <div className={classes.drawerHeader}>
          {isToggled ? <Logo /> : null}
          {isToggled ? (
            <IconButton onClick={handleToggle}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <ListItemIcon>
              <IconButton onClick={handleToggle}>
                <Icon.Drawer width="20" fill="#bbb" />
              </IconButton>
            </ListItemIcon>
          )}
        </div>
        <List>
          <div id="thq-sidebar-modules">
            <App {...props} />
          </div>
        </List>
      </Drawer>
    </div>
  );
};

export default importNewNav;
