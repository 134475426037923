import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Contact Create";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    contact: {
      api: {
        headers: {
          'Content-Type': 'application/json',
        },
        endpoints: {
          create: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'PUT'
          }
        }
      },
      objects: {
        firstName: { name: "firstName", initial: "", label: "Name", helperText: false, rules: ['isNotNull', 'isName'] },
        emailAddress: { name: "emailAddress", initial: "", label: "Email Address", helperText: false, rules: ['isNotNull', 'isEmail'] },
        mobile: { name: "mobile", initial: "", label: "Contact Number", helperText: false,  rules: ['isNumber'] },
        phone1: { name: "phone1", initial: "0", label: "Phone Number", helperText: false,  rules: ['isNumber'] },
        comment: { name: "comment", initial: "", label: "Position", helperText: false,  rules: false },
        flPrimary: { name: "flPrimary", initial: "", label: "Opt-in", helperText: false,  rules: false },
        flActive: { name: "flActive", initial: "", label: "Activate", helperText: false,  rules: false },
      },
    }
  },
  routes: {
    label: "Create",
    prefix: "/company/contacts/create/:companyId"
  },
  setup
}

export default config;
