import React from 'react';

import Modules from '../../modules/landing-modules';

const landing = props => {
  return Modules.map((Module, i) => {
    return <Module.Routes {...props} key={i} />
  });
}

export default landing;
