import React, { useState } from 'react';

import { Container, Row, Col } from 'reactstrap';

// apps
import NewNav from './imports-new-nav';
import Body from './imports-body';

import AdminIdle from '../../components/app-module-admin-idle/default';
import appConf from '../../components/app-conf';

const drawerWidth = 240;

////////
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;
const Main = props => {
  return <Themes>
  <AdminIdle>
  <div className={"d-flex"} id="thq-main-wrapper">
    <NewNav {...props} />
    <div id="thq-content-wrapper">
      <Container fluid id="thq-main-body" >
        <Row>
          <Col style={{marginLeft:drawerWidth, paddingBottom:50}}>
            {/* imports */}
            <Body  {...props} />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
  </AdminIdle>
  </Themes>
}

export default Main;
