import React from 'react';
import Button from '@material-ui/core/Button';

const PrimaryLink = props => {
  const { children, href } = props;
  const handles = { href }
  return <Button color="primary" size="small" {...handles} >
    {children}
  </Button>
}

export default PrimaryLink;
