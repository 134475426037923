import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  constructor() {
    this.reset();
  }

  reset = () => {
    this.loading = false;
    this.data = false;
    this.status = true;
    this.expireToken = false;
  }

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = status ? data : false;
    this.expireToken = data.json.status === 203 ? true : false; // determine the response if token expire ex: 203
  }

}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable,
})


export default createContext(new Store())
