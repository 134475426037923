import React from 'react';

/* packages */
import { ModuleTitle } from '../../util-atomic-design';
/* packages */

const index = props => {
  return <ModuleTitle>
    Company List
  </ModuleTitle>
}

export default index;
