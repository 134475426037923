import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-header-data';

import CONFIG from '../../../config';

const putDollars = (number) => {
  return '$' + number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const itemData = ({reportData}) => {
  if (!reportData) return null;
  const { totalSales, totalIncomes, sales, incomes } = reportData;
  const subset = { totalSales, totalIncomes, sales, incomes };
  const columnLength = (reportData) => {
    var count = 0;
    Object.keys(reportData).map((key) => {
      const value = reportData[key]
      if (typeof value === 'object') {
        count = count + Object.keys(value).length;
      } else {
        count = count + 1;
      }
      return null;
    })
    return count
  }
  var d = Math.pow(10,1);
  const widthPercent = (parseInt((100 / columnLength(subset))*d)/d).toFixed(1);
  return <View style={[styles.container, styles.itemsWrapper]}>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.giftCardSales) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.serviceFees) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.greetingCardFees) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.adminFees) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.carwash) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.courier) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.valet) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.hands) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.delivery) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.miscell) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(sales.parknm) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(totalSales) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.cashSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.eftposSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.visaSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.masterCardSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.amexSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.dinersSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(incomes.giftCardSum) }</Text>
    </View>
    <View style={[styles.headerDataContainer, {width:widthPercent.toString() + '%'}]}>
      <Text style={styles.headerDataText}>{ putDollars(totalIncomes) }</Text>
    </View>
  </View>
}

export default itemData;
