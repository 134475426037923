import React from 'react';

import { Route, Switch } from "react-router-dom";
import Page404 from '../../components/app-module-admin-landing-404/default';

/* packages */
import Privilege from '../../components/app-conf-privilege';
import { LocalStorage } from '../../components/lib-atomic-functions';
/* packages */

const importBody = props => {
  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableContainers = rolesHandler(LocalStorage, rolesModules);
  const availableSubModules = rolesHandler(LocalStorage, rolesSubModules);
  const { module } = props.match.params;
  const { pathname } = props.location;

  var pathnameRefined = pathname.slice(-1) === '/'?pathname.slice(1, -1):pathname;
  
  // check if user try to visit unavailable pages; prepare flags for showing 404 page
  var modular404 = true;
  var showPage404 = true;
  // add more flags...

  for (const mod1 in availableContainers) {
    if (mod1 === module) {
      modular404 = false;
      // if (mod1 === 'reporting') {

      for (const mod in availableSubModules[mod1]) {
        const originalPrefix = availableSubModules[mod1][mod].Config.routes.prefix;
        const originalPrefixArray = originalPrefix.split("/").slice(1,originalPrefix.split("/").length);
        // const numberOfOptionalParam = originalPrefix.split(":").length-1;
        const pathArray = pathnameRefined.split("/").slice(1,pathnameRefined.split("/").length);

        if (originalPrefixArray.length !== pathArray.length) continue;
        var refined1 = []
        var refined2 = []
        for (var i = 0; i < originalPrefixArray.length; i++) {
          var item = originalPrefixArray[i];
          if (item.indexOf(":") === -1) {
            refined1.push(item)
            if (pathArray.length >= i) {
              refined2.push(pathArray[i])
            }
          }
        }
        const final1 = refined1.slice(0,refined1.length).join("/")
        const final2 = refined2.slice(0,refined2.length).join("/")
        if (final1 === final2) {
          showPage404 = false
          break;
        }
      }
    }
  }
  // pop up container level 404
  if (modular404) {
    return <Route component={Page404} {...module} />
  }
  // pop up 404 page for each module
  const { Routes, Config } = availableContainers[module];

  if (showPage404) return <Route component={Page404} {...module} />

  return <Routes {...props} />
}
export default importBody;
