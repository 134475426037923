import themes from "./themes";
import setup from "./setup";
//import modules from './modules';
import assets from "./assets";

export default {
  themes,
  setup,
  assets,
};
