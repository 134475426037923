import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

/* modules */
import RowHeader from "./row-header";
import RowBody from "./row-body";
/* modules */

const normal = props => {
  const { data } = props;
  if (data.length < 1) return null;
  return (
    <Fragment>
      <TableContainer>
        <Table size={"small"}>
          <RowHeader {...props} />
          <RowBody {...props} />
        </Table>
      </TableContainer>
    </Fragment>
  );
  return (
    <Fragment>
      <Grid container>
        <RowHeader {...props} />
      </Grid>
      <Grid container>
        <RowBody {...props} />
      </Grid>
    </Fragment>
  );
};

export default normal;
