import DefaultSmallNon from './btn-default-small-non';
import Normal from './btn-normal';
import NormalSmall from './btn-normal-small';
import NormalSmallBlock from './btn-normal-small-block';
import NormalBlock from './btn-normal-block';
import Primary from './btn-primary';
import PrimarySmall from './btn-primary-small';
import PrimaryLink from './btn-primary-link';
import PrimaryLinkSmall from './btn-primary-link-small';
import PrimaryBlock from './btn-primary-block';

export default {
  DefaultSmallNon,
  Normal,
  NormalSmall,
  NormalSmallBlock,
  NormalBlock,
  Primary,
  PrimarySmall,
  PrimaryLink,
  PrimaryLinkSmall,
  PrimaryBlock,
}
