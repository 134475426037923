import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image
} from "@react-pdf/renderer";

import styles from "./styles-header-data";

import CONFIG from "../../../config";

const itemData = ({ reportData }) => {
  if (!reportData) return null;
  const { qtyCardsSold, totalSales, totalIncomes, sales, incomes } = reportData;
  return (
    <View style={[styles.container, styles.itemsWrapper]}>
      {/*
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>User</Text>
      </View>
    */}
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{qtyCardsSold}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{sales.giftCardSales}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{sales.serviceFees}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{sales.greetingCardFees}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{sales.adminFees}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{sales.courier}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{totalSales}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.cashSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.eftposSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.visaSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.masterCardSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.amexSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.dinersSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.giftCardSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{incomes.directCreditSum}</Text>
      </View>
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>{totalIncomes}</Text>
      </View>
    </View>
  );
};

export default itemData;
