import Login from '../../app-module-admin-login';
import LoginRedirect from '../../app-module-admin-login-redirect';
import Logout from '../../app-module-admin-logout';
import TokenExpire from '../../app-module-admin-token-expire';
import PasswordReset from '../../app-module-admin-password-reset';
import CardActivation from '../../app-module-admin-landing-card-activation';
import IdleUser from '../../app-module-admin-landing-idle-user';

const AdminModules = [
  Login,
  Logout,
  TokenExpire,
  LoginRedirect,
  PasswordReset,
  CardActivation,
  IdleUser
]

export default AdminModules;
