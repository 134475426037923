import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

/* container */
import OrderContainer from "../../app-module-order-container/default";
/* container */

import { pdf } from "@react-pdf/renderer";

/* packages */
import {
  ModuleWrapperXl,
  ModuleWrapperMain,
  ModuleWrapperMd,
  ModuleWrapperLeft,
  ModuleWrapperRight,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* modules */
import Loader from "../loader/orders";
import OrdersDetail from "../detail/orders";
import OrdersConfirmation from "../form/orders";
import OrdersCorpConfirmation from "../form/orders_corp";
/* modules */

/* section */
import Nav from "../section/nav";
import Title from "../section/title";
import Action from "../section/action";
/* section */

/* functions */
import { OrderingDetail } from "../../app-module-ordering-container/functions";
import { CompanyDetail } from "../../app-module-company-container/functions";
import { ContactDetail } from "../../app-module-contact-container/functions";
import {
  ProductCat1List,
  ProductCat2List,
  ProductCat3List,
  ProductCat4List,
} from "../../app-module-product-container/functions";
/* functions */

/* stores */
import { OrderDetail } from "../../app-module-order-container/stores";
/* stores */

import PDFblob from "../../app-module-order-page-pdf/function";

import CONFIG from "../config";

/* CONSTANTS */
const DETAIL = CONFIG.application.order.api.endpoints.detail;
const DETAILCONFIG = {
  url: DETAIL.url,
  method: DETAIL.method,
  headers: DETAIL.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = observer((props) => {
  const { orderId } = props.match.params;
  const { token } = LocalStorage.getLS().login;

  /* state */
  DETAILCONFIG.headers = { ...DETAILCONFIG.headers, Authorization: token };
  const [orderMainDetailContext, orderMainDetailMethod] = FetchContextApi(
    DETAILCONFIG,
    OrderDetail
  );

  /* product */
  const { orderDetailContext, orderDetailMethod } = OrderingDetail({ CONFIG });
  const {
    productCat1ListContext,
    productCat1ListMethod,
    productCat1ListData,
  } = ProductCat1List({ CONFIG, token });
  const {
    productCat2ListContext,
    productCat2ListMethod,
    productCat2ListData,
  } = ProductCat2List({ CONFIG, token });
  const {
    productCat3ListContext,
    productCat3ListMethod,
    productCat3ListData,
  } = ProductCat3List({ CONFIG, token });
  const {
    productCat4ListContext,
    productCat4ListMethod,
    productCat4ListData,
  } = ProductCat4List({ CONFIG, token });
  /* companys */
  const {
    companyInfoContext,
    companyInfoMethod,
    companyInfoData,
  } = CompanyDetail({
    CONFIG,
    token,
    companyId: orderDetailContext.data.clientId,
  });
  /* contacts */
  const {
    contactInfoContext,
    contactInfoMethod,
    contactInfoData,
  } = ContactDetail({
    CONFIG,
    token,
    companyId: orderDetailContext.data.clientId,
    contactId: orderDetailContext.data.contactId,
  });

  const ex = async () => {
    // await orderMainDetailMethod({ query: orderId });
    await orderDetailMethod({ token, orderId });
    await productCat1ListMethod();
    await productCat2ListMethod();
    await productCat3ListMethod();
    await productCat4ListMethod();
  };

  useEffect(() => {
    ex();
  }, [orderId]);

  useEffect(() => {
    if (orderDetailContext.data) {
      console.log(" run company and contact...");
      companyInfoMethod();
      console.log("run company");
      contactInfoMethod();
      console.log("run cotact...");
    }
  }, [orderDetailContext.data]);
  /* state */

  const productsAll =
    productCat1ListData &&
    productCat2ListData &&
    productCat3ListData &&
    productCat4ListData
      ? [
          ...productCat1ListData.products,
          ...productCat2ListData.products,
          ...productCat3ListData.products,
          ...productCat4ListData.products,
        ]
      : false;

  /* laoder */
  if (orderDetailContext.loading) return <Loader />;
  if (!orderDetailContext.data) return <Loader />;
  if (!contactInfoContext.data) return <Loader />;
  if (orderDetailContext.expireToken) return <Loader />; // redirect
  if (!companyInfoData || !contactInfoData || !productsAll) return <Loader />;

  /* data loader */
  const productsData = orderDetailContext.data.detail.filter(
    (product) => product.subClassId === 2 || product.subClassId === 3
  );
  const feesData = orderDetailContext.data.detail.filter(
    (product) => product.subClassId === 1 || product.subClassId === 5
  );
  const productsDetailsData = productsData.map((productDetail) => {
    productDetail["details"] = productsAll.filter(
      (product) => product.productId === productDetail.productId
    )[0];
    return productDetail;
  });
  const feesDetailsData = feesData.map((productDetail) => {
    productDetail["details"] = productsAll.filter(
      (product) => product.productId === productDetail.productId
    )[0];
    return productDetail;
  });

  /* data props */
  const order = orderDetailContext.data;
  const previewData = {
    pdfFilename: orderId + ".pdf",
    pdfPreviewData: {
      orderInfoData: order,
      companyInfoData,
      contactInfoData,
      productsDetailsData,
      feesDetailsData,
    },
    pdfPreviewSize: {
      width: 450,
      height: 700,
    },
  };
  console.log(previewData, " PREVIEW");

  console.log(orderDetailContext.data, productCat1ListContext.data, "ORDERS");

  const filterProduct = (idList, data) => {
    return {
      products: data.detail.filter((product) =>
        idList.includes(product.subClassId)
      ),
    };
  };

  const orderClean = {
    previewData,
    order: orderDetailContext.data,
    company: orderDetailContext.data,
    contact: orderDetailContext.data,
    product: filterProduct([2, 3], orderDetailContext.data),
    fee: filterProduct([1, 4], orderDetailContext.data),
    product_card: filterProduct([3], orderDetailContext.data),
  };
  const detailData = {
    props,
    ...orderClean,
  };
  /* data props */

  const { flCorporate } = orderDetailContext.data;
  const isBulk = flCorporate !== 1 ? true : false;
  const isCorporate = flCorporate === 1 ? true : false;
  console.log(detailData, " SADS");

  return (
    <OrderContainer {...props}>
      <ModuleWrapperXl>
        <Nav {...props} />
        <Title {...props} />
        <Action {...detailData} />
      </ModuleWrapperXl>

      <ModuleWrapperXl>
        <ModuleWrapperMain>
          
          <ModuleWrapperLeft>
            <OrdersDetail {...detailData} />
          </ModuleWrapperLeft>


          <ModuleWrapperRight>
            <ModuleWrapperMd>
              <ModulePaperWrapper>
                {isBulk && <OrdersConfirmation {...detailData} />}
                {isCorporate && <OrdersCorpConfirmation {...detailData} />}
              </ModulePaperWrapper>
            </ModuleWrapperMd>
          </ModuleWrapperRight>
        </ModuleWrapperMain>
      </ModuleWrapperXl>
    </OrderContainer>
  );
});

export default index;
