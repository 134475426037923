import React from 'react';
import Typography from '@material-ui/core/Typography';

const Feeds = ({ errors }) => {
  return errors.map((error, index) => {
    return <Typography key={index} variant="caption" display="block" gutterBottom>
      { error }
    </Typography>
  });
}

export default Feeds;
