import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const env = process.env.NODE_ENV;
const project = "app-plugin-logo";
const server = null;
const service = null;
const config = {
  details: {
    name: project,
    server: server,
    environment: env,
    service: service,
  },
  routes: {
    label: "Logo",
    prefix: "/logo"
  },
  setup
}

export default config;
