import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperInfo } from '../../util-atomic-design';
/* packages */

const index = ({id, firstName, emailAddress, mobile, comment, flPrimary, editLink}) => {
  const optInOut = flPrimary === 1 ? "Opt-in" : "Opt-Out";
  return <ModuleWrapperInfo>
    <Typography variant="body2">
      <b>{ firstName }</b>
    </Typography>
    <Typography variant="body2">
      { emailAddress }
    </Typography>
    <Typography variant="body2" gutterBottom>
      { mobile }
    </Typography>
    <Typography variant="body2" gutterBottom>
      { comment }
    </Typography>
    <Typography variant="body2" gutterBottom>
      { optInOut }
    </Typography>
    { editLink && editLink }
  </ModuleWrapperInfo>
}

export default index;
