const isDate = (data) => {
  let value = data.value;
  const config = data.config;
  const format = config.format;
  const delimiter = config.delimiter;
  value = value.trim();
  const ymd = {
    "-": /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
    "/": /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/,
  };
  const mdy = {
    "-": /^(\d{1,2})-(\d{1,2})-(\d{4})$/,
    "/": /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
  };
  const dmy = {
    "-": /^(\d{1,2})-(\d{1,2})-(\d{4})$/,
    "/": /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
  };
  let isValid = null;
  switch (format) {
    case "yyyymmdd":
      isValid = (ymd[delimiter].test(value));
    break;
    case "mmddyyyy":
      isValid = (mdy[delimiter].test(value));
    break;
    case "ddmmyyyy":
      isValid = (dmy[delimiter].test(value));
    break;
  }
  return (isValid) ? true : "must be a valid date format"

}

export default isDate;
