const isMinMax = (data) => {
  const config = data.config;
  const limit = config.minmax_limit;
  const suffix = config.minmax_suffix;
  const nullAllow = config.allow_null ? true : false;
  if ((data.value === null || data.value === undefined) && nullAllow) return true;
  if ((data.value === null || data.value === undefined) && !nullAllow) return "must be " + limit + " " + suffix;
  const value = data.value.trim();
  if (nullAllow && value.length < 1) return true;
  return (value.length === limit) ? true : "must be " + limit + " " + suffix;
}

export default isMinMax;
