import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Breadcrumb = props => {
  return (
    <Paper elevation={0}>
      <Breadcrumbs maxItems={3} aria-label="Breadcrumb">
        { props.children }
      </Breadcrumbs>
    </Paper>
  );
}

export default Breadcrumb;
