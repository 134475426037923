import React, { useState, useEffect  } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

const index = props => {

  const [update, forceUpdate] = useState(true);
 

  LocalStorage.removeLS();

  useEffect(() => {
    forceUpdate(false);
    props.history.push("/u/login");
  }, []);
  
  //window.location.replace("/u/login");

  return null;

}

export default index;
