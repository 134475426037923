import React, { Component } from "react";
import { Link } from 'react-router-dom';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

/* packages */
import Icon from '../../util-svgs';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';

const Index = props => {

  const { login } = LocalStorage.getLS();
  const { username } = login;

  const name = CONFIG.setup.setup.name;
  const logo = CONFIG.setup.assets.logo;

  return <Navbar color="light" light expand="md" fixed>
    {/* <button className="btn btn-link" {...props}><Icon.Drawer width="15" fill="#bbb" /></button> */}
    <NavbarBrand style={{marginLeft:120}} href="/">
      <img src={logo} width="100" />
    </NavbarBrand>
    <NavbarToggler />
    <Collapse navbar>
      {
        // <Nav className="mr-auto" navbar>
        //   <NavItem>
        //     <NavLink href="#"><b>{name}</b></NavLink>
        //   </NavItem>
        // </Nav>
      }
      <Nav className="ml-auto" navbar>
        {
          // <NavItem>
          //   <NavLink href="#"><Icon.Notification width="20" fill="#bbb" /></NavLink>
          // </NavItem>
          // <NavItem>
          //   <NavLink href="#"><Icon.Setting width="20" fill="#bbb" /></NavLink>
          // </NavItem>
        }
        <NavItem>
          <NavLink href="#">{ username }</NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <Link to="/u/logout">&nbsp;Sign out&nbsp;</Link>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#"><Icon.Menu width="20" fill="#bbb" /></NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  </Navbar>

}

export default Index;
