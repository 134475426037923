import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
/* mui */

/* container */
import OrderContainer from '../../app-module-order-container/default';
/* container */

/* packages */
import { ModuleWrapperXl, ModuleWrapperMain, ModuleWrapperMd, ModuleWrapperLeft, ModuleWrapperRight, ModulePaperWrapper } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* module */
import OrderNav from './nav';
import OrderTitle from './title';
import OrderSubTitle from './subtitle';
import OrderSubTitle2 from './subtitle2';
import OrderSubTitle3 from './subtitle3';
import OrderStepper from './stepper';
import CompanyDetail from '../../app-module-company-detail/detail';
import ContactDetail from '../../app-module-company-contacts-detail/detail';
import OrderItemsList from '../../app-module-order-section-product/default';
/* module */

/* section */
import Nav from '../section/nav';
import Title from '../section/title';
import Action from '../section/action';
/* section */

/* sections */
import CompanyInfoFetcher from '../../app-module-company-info-section-fetcher/default';
import ContactInfoFetcher from '../../app-module-contact-info-section-fetcher/default';
import OrderSectionItems from '../../app-module-order-section-product/default';
import OrderSectionTotal from '../../app-module-order-section-total/default';
/* sections */

import CONFIG from '../config';

/* CONSTANTS */
const steps = ['Company', 'Contact', 'Products', 'Fees', 'Others'];
/* CONSTANTS */

const index = observer((props) => {

  /* states */
  const [collection, setCollection] = useState({
    status: false,
    company: false,
    contact: false,
    po: false,
    products: [],
    fees: [],
    note: false
  })
  /* states */

  const handleRemoveProduct = (selectedIndex) => {
    collection.products = collection.products.filter((product, index) => index !== selectedIndex);
    setCollection({...collection});
  }

  const handleRemoveFees = (selectedIndex) => {
    collection.fees = collection.fees.filter((product, index) => index !== selectedIndex);
    setCollection({...collection});
  }

  const handleTotal = () => {
    const { products, fees } = collection;
    const productsList = [...products, ...fees];
    let totalAmount = 0;
    let totalGST = false;
    productsList.map((product) => {
      totalAmount = totalAmount + (product.quantity * product.value);
    })
    return { totalAmount, totalGST }
  } 

  /* data props */
  const summaryData = {
    companyId: collection.company ? collection.company.id : false,
    contactId: collection.contact ? collection.contact.id : false,
    products: collection.products.length > 0 ? collection.products : false,
    fees: collection.fees.length > 0 ? collection.fees : false
  }
  const stepperData = { collection, setCollection, props }
  const companyData = { companyId: collection.company ? collection.company.id : false }
  const contactData = { companyId: collection.company ? collection.company.id : false, contactId: collection.contact ? collection.contact.id : false }
  const productsData = { products: collection.products.length > 0 ? collection.products : false, handleRemove: handleRemoveProduct }
  const feesData = { products: collection.fees.length > 0 ? collection.fees : false, handleRemove: handleRemoveFees }
  const productTotal = handleTotal();
  /* data props */

  return <OrderContainer {...props} >

    <ModuleWrapperXl>

      <Nav {...props} />
      <Title {...props} />
      <Action {...props} />

    </ModuleWrapperXl>

    <ModuleWrapperXl>

      <ModuleWrapperMain>
      
        <ModuleWrapperLeft>

        <ModuleWrapperMd>
        <ModulePaperWrapper>
        <OrderStepper {...stepperData} />
        </ModulePaperWrapper> 
        </ModuleWrapperMd>

        </ModuleWrapperLeft>

        <ModuleWrapperRight>

        <ModuleWrapperMd>

          { companyData.companyId && <CompanyInfoFetcher {...companyData} /> }
          { contactData.contactId && <ContactInfoFetcher {...{...contactData}} /> }
          <OrderSectionItems {...productsData} />
          <OrderSectionItems {...feesData} />
          { productsData.products && <OrderSectionTotal {...productTotal} /> }

        </ModuleWrapperMd>
        
        </ModuleWrapperRight>

      </ModuleWrapperMain>

    </ModuleWrapperXl>

  </OrderContainer>

  // return <ModuleWrapperMain>
  //   <ModuleWrapperFull>
  //     <OrderNav {...props} />
  //   </ModuleWrapperFull>
  //   <ModuleWrapperLeft>
  //     <OrderTitle {...props} />
  //     <OrderStepper {...stepperData} />
  //   </ModuleWrapperLeft>
  //   <ModuleWrapperRight>
  //     <OrderSubTitle {...props} />
  //     { companyData.companyId && <OrderSubTitle2 {...props} /> }
  //     { companyData.companyId && <CompanyDetail {...companyData} /> }
  //     { contactData.contactId && <ContactDetail {...contactData} /> }
  //     { productsData.products && <OrderSubTitle3 {...props} /> }
  //     <OrderItemsList {...productsData} />
  //     <OrderItemsList {...feesData} />
  //   </ModuleWrapperRight>
  // </ModuleWrapperMain>

});

export default index;
