import React, { useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Moment from "react-moment";

import { Row, Col } from "reactstrap";

/* packages */
import Box from "../../util-box";
import ListingX from "../../util-list";
import Listing from "../../util-mui-list";
import Skeleton from "../../util-skeleton";
import Fetch from "../../util-fetch";
import Alert from "../../util-alert";
import Button from "../../util-button";
import Inputs from "../../util-inputs";
import useForm from "../../util-hook-useform";
import validation from "../../util-inputs-validation";
import Stores from "../../app-plugin-stores";
import Breadcrumbs from "../../util-mui-breadcrumbs";
import DesignSectionX from "../../util-design-section";
import DesignSection from "../../util-mui-design-section";

import { Skeletons } from "../../util-atomic";
import {
  ModuleWrapper,
  ModuleWrapperMain,
  ModuleWrapperLeft,
  ModuleWrapperRight,
  ModuleWrapperFull,
} from "../../util-atomic-design";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import CompanyNav from "./nav";
import CompanyTitle from "./title";
import CompanySubTitle from "./subtitle";
import CompanyAction from "./actionToBeApproved";
import CompanyActionToBeApproved from "./action";
import CompanySubAction from "./subaction";
import CompanyList from "./list";
import CompanyInfo from "../info/new";
import CompanyContact from "../contact";
import Loader from "../loader";

import CompanyInfoDetail from "../detail";
import CompanyContactsList from "../../app-module-contact-section-info-list/default";
//import CompanyContactsList from '../../app-module-contact-list/default';
/* module */

/* functions */
import CompanyDetail from "../libs/functions/detail";
/* functions */

/* functions  */
import { ContactListAll } from "../../app-module-contact-container/functions";
/* functions  */

import CONFIG from "../config";

const Index = observer((props) => {
  const { login } = LocalStorage.getLS();
  const { token } = login;
  if (!login) return <Redirect to="/u/logout" />;

  const { companyId } = props.match.params;

  /* states */
  const { companyDetailContext, companyDetailMethod, companyDetailData } =
    CompanyDetail({ token, companyId });
  const { contactListContext, contactListMethod } = ContactListAll({ CONFIG });

  useEffect(() => {
    companyDetailMethod();
    contactListMethod({ token, companyId });
  }, []);

  useEffect(() => {
    if (contactListContext.expireToken)
      props.history.push("/u/landing-token-expire");
  }, [contactListContext.expireToken]);
  /* states */

  /* laoder */
  if (!companyDetailData) return <Loader />;
  if (contactListContext.loading) return <Loader />;
  if (!contactListContext.data) return <Loader />;
  if (contactListContext.expireToken) return <Loader />; // redirect
  /* data props */
  const { contacts } = contactListContext.data;
  // const newCompanyDetail = companyDetailData.clients.filter(client => client.id === Number(companyId))[0];
  const actionData = { companyId };
  const infoDetailData = { companyId };
  const listData = { companyId, contacts, edit: true };
  const navData = { companyId };
  // const propsData = { companyId, ...newCompanyDetail }
  console.log({ companyDetailData });
  const companyToBeApproved = companyDetailData.clients.filter(
    (client) =>
      client.statusId.toString() === "752" && client.id.toString() === companyId
  );
  /* data props */

  if (companyToBeApproved.length == 1) {
    return (
      <ModuleWrapperMain>
        <ModuleWrapperFull>
          <CompanyNav {...navData} />
        </ModuleWrapperFull>

        <ModuleWrapperLeft>
          <CompanyTitle />
          <CompanyActionToBeApproved {...actionData} />
          <CompanyInfoDetail {...infoDetailData} />
        </ModuleWrapperLeft>

        <ModuleWrapperRight>
          <CompanySubTitle />
          <CompanySubAction {...actionData} />
          <CompanyContactsList {...listData} />
        </ModuleWrapperRight>
      </ModuleWrapperMain>
    );
  } else {
    return (
      <ModuleWrapperMain>
        <ModuleWrapperFull>
          <CompanyNav {...navData} />
        </ModuleWrapperFull>

        <ModuleWrapperLeft>
          <CompanyTitle />
          <CompanyAction {...actionData} />
          <CompanyInfoDetail {...infoDetailData} />
        </ModuleWrapperLeft>

        <ModuleWrapperRight>
          <CompanySubTitle />
          <CompanySubAction {...actionData} />
          <CompanyContactsList {...listData} />
        </ModuleWrapperRight>
      </ModuleWrapperMain>
    );
  }
});

export default Index;
