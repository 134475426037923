import React from 'react';
import Grid from '@material-ui/core/Grid';

const WrapperFull = props => {
  return <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    { props.children }
  </Grid>
}

export default WrapperFull;
