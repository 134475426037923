import React from 'react';

import Dots from './dots';

const icon = props => {
  return <svg width={props.width} viewBox="0 0 512 512">
    <Dots fill={props.fill} />
  </svg>
}

export default icon;
