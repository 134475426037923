import React, { Fragment } from 'react';

import { Skeletons } from '../../util-atomic';

const loader = props => {
  return <Fragment>
    <Skeletons.Large />
    <Skeletons.Medium />
  </Fragment>
}

export default loader;
