import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import Nav from '../nav/contact';
import Title from '../title/contact';
import Form from '../form/contact';
import Loader from '../loader';
import CompanyInfoDetail from '../../app-module-company-detail/detail';
/* module */

/* functions  */
import { ContactCreate } from '../../app-module-contact-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const CONTACTOBJECTS = CONFIG.application.contact.objects;
/* CONSTANTS */

const index = observer((props) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const { companyId } = props.match.params;

  /* state */
  const { contactCreateContext, contactCreateMethod } = ContactCreate({CONFIG});
  /* state */

  const handleCreate = async () => {
    values.flPrimary = values.flPrimary ? 1 : 0;
    values.flActive = values.flActive ? 1 : 0;
    await contactCreateMethod({token, companyId, body: JSON.stringify(values)});
    props.history.push('/company/detail/' + companyId);
  }

  const {
    values,
    onChange,
    onCheck,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONTACTOBJECTS);

  const firstName = { ...CONTACTOBJECTS.firstName, error: error.firstName, value: values.firstName, onChange };
  const emailAddress = { ...CONTACTOBJECTS.emailAddress, error: error.emailAddress, value: values.emailAddress, onChange };
  const mobile = { ...CONTACTOBJECTS.mobile, error: error.mobile, value: values.mobile, onChange };
  const comment = { ...CONTACTOBJECTS.comment, error: error.comment, value: values.comment, onChange };
  const flPrimary = { ...CONTACTOBJECTS.flPrimary, error: error.flPrimary, value: values.flPrimary, onChange: onCheck };
  const flActive = { ...CONTACTOBJECTS.flActive, error: error.flActive, value: values.flActive, onChange: onCheck };
  const submitButton = { fullWidth: true }

  return <form onSubmit={onSubmit} noValidate>
    <Inputs.Text {...firstName} />
    <Inputs.Text {...emailAddress} />
    <Inputs.Text {...mobile} />
    <Inputs.Text {...comment} />
    <Inputs.Checkbox {...flPrimary} />
    <Inputs.Checkbox {...flActive} />
    <br/>
    <br/>
    <Buttons.Normal>Add Contact</Buttons.Normal>
  </form>

});

export default index;
