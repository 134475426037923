import React, { useContext, useEffect } from "react";

import { StyleSheet, Font } from "@react-pdf/renderer";

// Register font
// const source =
//   "https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNzbP97U9sKh0jjxbPbfOKg.ttf";
const source = "/assets/westfield/fonts/proxima/ProximaNova-Regular_1.ttf";
const source2 = "/assets/westfield/fonts/proxima/Proxima-Nova-Bold.ttf";

Font.register({
  family: "proxima-nova-regular",
  src: source,
});
Font.register({
  family: "proxima-nova-bold",
  src: source2,
});

export default StyleSheet.create({
  SectionTitleMain: {
    fontSize: 22,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: "proxima-nova-bold",
  },
  SectionTitle: {
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  TotalTitle: {
    // fontWeight: "bold",
  },
  StartEndNumber: {
    fontSize: 10,
  },
  defaultBold: {
    fontFamily: "proxima-nova-bold",
  },
  DefaultName: {
    fontSize: 8,
    paddingTop: 3,
    paddingBottom: 3,
  },
  DefaultNameLarge: {
    paddingTop: 5,
    paddingBottom: 1,
    fontFamily: "proxima-nova-bold",
  },
  DefaultNameLargeBottom: {
    paddingTop: 1,
    paddingBottom: 5,
    marginTop: -2,
    fontFamily: "proxima-nova-bold",
  },
  DefaultNamePadded: {
    paddingLeft: 10,
  },
  page: {
    flex: 1,
    flexDirection: "column",
    fontSize: 10,
    fontWeight: "normal",
    paddingTop: 47,
    paddingBottom: 47,
    fontFamily: "proxima-nova-regular",
    letterSpacing: 1,
  },
  container: {
    marginLeft: 47,
    marginRight: 47,
    flexDirection: "row",
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
    width: 250,
    backgroundColor: "yellow",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
    width: 250,
    backgroundColor: "red",
  },
  logoHeader: {
    width: 100,
    // height: 32,
  },
  logoFooter: {
    width: 70,
    justifyContent: "center",
    marginTop: 10,
    marginRight: 10,
    // height: 32,
  },
  lineFooter: {
    width: "99.94%",
  },
  headerWrapper: {},
  headerContainer: {
    flex: 2,
    textAlign: "left",
    //backgroundColor: "purple",
    paddingTop: 3,
    paddingBottom: 3,
  },
  headerRightContainer: {
    flex: 4,
    textAlign: "left",
    //backgroundColor: "purple",
    paddingTop: 3,
    paddingBottom: 3,
  },
  textAlignRight: {
    textAlign: "right",
  },
  titleWrapper: {},
  titleContainer: {
    flex: 1,
    textAlign: "left",
    //backgroundColor: "green",
    paddingTop: 3,
    paddingBottom: 3,
  },
  companyWrapper: {},
  companyContainer: {
    flex: 2,
    textAlign: "left",
    //backgroundColor: "yellow",
    paddingTop: 3,
    paddingBottom: 3,
  },
  itemsLabelWrapper: { paddingBottom: 6 },
  itemsWrapper: { color: "white", backgroundColor: "black" },
  itemContainer: {
    flex: 6,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  itemContainer0: {
    flex: 6,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 2,
    paddingBottom: 2,
  },
  itemContainer2: {
    flex: 5,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 2,
    paddingBottom: 2,
  },
  itemContainer4: {
    flex: 6,
    textAlign: "center",
    // backgroundColor: "blue",
    paddingTop: 2,
    paddingBottom: 2,
  },
  itemContainer4AlignCenter: {
    flex: 2,
    textAlign: "right",
    paddingLeft: 1,
    paddingRight: 80,
  },
  qtyContainer: {
    flex: 2,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  totalContainer: {
    flex: 2,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  gstContainer: {
    flex: 6,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  otherWrapper: {},
  otherContainer: {
    flex: 2,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  courierTotalContainer: {
    flex: 6,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
    width: "auto",
  },
  courierTotalContainer2: {
    flex: 3,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 5,
    marginRight: 5,
    width: "auto",
  },
  rowDirection: {
    flexDirection: "row",
  },
  columnDirection: {
    flexDirection: "column",
  },
  alignLeftContainer: {
    textAlign: "left",
  },
  alignRightContainer: {
    textAlign: "right",
  },
  transparentline: {
    // flex: 1,
    backgroundColor: "white",
    height: 1,
    marginTop: 3,
    marginBottom: 3,
  },
  line: {
    // flex: 1,
    backgroundColor: "black",
    height: 1,
    marginTop: 3,
    marginBottom: 3,
  },
  footerWrapper: {
    flex: 1,
    flexDirection: "column",
  },
  footerContainer: {
    flex: 1,
    justifyContent: "flex-end",
    paddingTop: 3,
    paddingBottom: 3,
  },
  footerMainBg: {
    backgroundColor: "black",
    color: "white",
  },
  footerMain: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "black",
    color: "white",
  },
  footerContentContainer: {
    flexDirection: "row",
  },
  footerContentFull: {
    flex: 10,
    textAlign: "left",
  },
  footerContentLeft: {
    flex: 6,
    textAlign: "left",
  },
  footerContentText: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  footerContentBold: {
    fontWeight: "bold",
  },
  footerContentLinks: {
    // borderTopColor: "white",
    paddingTop: 4,
    paddingBottom: 4,
  },
  footerContentRight: {
    flex: 1,
    textAlign: "left",
  },
});
