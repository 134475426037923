import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";
const { logo, logo_pdf, footer_pdf } = CONFIG.setup.assets;
const currentTime = new Date();
const year = currentTime.getFullYear();

const footer = ({ order }) => {
  return (
    <Fragment>
      <View style={styles.container}>
        <View style={styles.line}></View>
      </View>

      <View style={[styles.container, styles.otherWrapper]}>
        <View style={styles.otherContainer}>
          <View style={[styles.line, { marginTop: 5, marginBottom: 5 }]}></View>
          <View style={styles.columnDirection}>
            <Text style={styles.DefaultName}>
              Thank you for choosing to purchase Westfield Gift Cards.
            </Text>
            <Text style={styles.DefaultName}>
              If you have any questions please refer to the Westfield Gift Card
              Terms and Conditions,{" "}
            </Text>
            <Text style={styles.DefaultName}>www.westfield.co.nz</Text>
            <Text style={styles.DefaultName}>
              Westfield Gift Cards expire 12 months from the date issued.
            </Text>
            <Text style={styles.DefaultName}>
              This receipt should be retained as your proof of purchase.
            </Text>
          </View>
        </View>
      </View>

      <View wrap={false} style={[styles.container, styles.footerWrapper]}>
        <View style={styles.footerContainer}>
          <View style={[styles.footerMainBg]}>
            <Image style={styles.lineFooter} src={footer_pdf} />
          </View>

          <View style={[styles.footerMain]}>
            <View style={[styles.footerContentContainer]}>
              <View style={styles.footerContentLeft}>
                <Text style={[styles.footerContentText, styles.defaultBold]}>
                  GIFT CARDS
                </Text>
                <Text style={styles.footerContentText}>
                  <Text style={styles.defaultBold}>Call</Text> 0800 937 834 -
                  9am - 5pm UTC Business days
                </Text>
                <Text style={styles.footerContentText}>
                  <Text style={styles.defaultBold}>Email</Text>{" "}
                  {/* {order.enrolmentTraderEmailAddress} */}
                  giftcardsnzcorporate@scentregroup.com
                </Text>
              </View>
              <View style={styles.footerContentRight}>
                <View style={styles.textContainer}>
                  <Image style={styles.logoFooter} src={logo_pdf} />
                </View>
              </View>
            </View>

            <View style={[styles.footerContentContainer]}>
              <View style={styles.footerContentFull}>
                <Text style={styles.footerContentLinks}>
                  <Link src="https://giftcards.westfield.co.nz/page/contact">
                    Contact Us{" "}
                  </Link>
                  <Text> </Text>
                  <Link src="https://www.westfield.co.nz/privacy-policy">
                    Privacy Policy{" "}
                  </Link>
                  <Text> </Text>
                  <Link src="https://www.westfield.co.nz/terms-and-conditions">
                    Product Terms & Condition
                  </Link>
                  <Text> </Text>
                  <Link src="http://www.scentregroup.com/">
                    @ {year} Scentre Group
                  </Link>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default footer;
