import React, { useContext, useEffect } from 'react';

import { StyleSheet, Font } from "@react-pdf/renderer";

// Register font
const source = 'https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNzbP97U9sKh0jjxbPbfOKg.ttf'
Font.register({ family: 'Cera', src: source });

export default StyleSheet.create({
  container: {
    paddingLeft: 47,
    paddingRight: 47,
    flexDirection: "row",
  },
  headerLabelContainer: {
    flex: 16,
    textAlign: "left",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5
  },
  headerLabelText: {
    fontSize: 7,
  }
});
