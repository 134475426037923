import React from 'react';
import Grid from '@material-ui/core/Grid';

const wrapper_left = props => {
  return <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
    { props.children }
  </Grid>
}

export default wrapper_left;
