import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Breadcrumbs } from '../../util-atomic';
/* packages */

const Index = props => {
  return <Breadcrumbs>
    <Link to={"/company"}><small>Home</small></Link>
    <small>List</small>
  </Breadcrumbs>
}

export default Index;
