import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ListStore from './store';
/* stores */

const Index = ({CONFIG, token}) => {
  const productCat1ListContext  = useContext(ListStore);
  const productCat1ListMethod = async () => {
    productCat1ListContext.setLoading(true);
    CONFIG.application.product.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.product.api.endpoints.list_Cat1.url,
      config: {
        method: CONFIG.application.product.api.endpoints.list_Cat1.method,
        headers: CONFIG.application.product.api.headers
      }
    });
    productCat1ListContext.setStatus(fetch[0]);
    productCat1ListContext.setData(fetch[1]);
    productCat1ListContext.setLoading(false);
  }
  const productCat1ListData = productCat1ListContext.isData();

  return { productCat1ListContext, productCat1ListMethod, productCat1ListData }
}

export default Index;
