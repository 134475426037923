import React from 'react';

import { Row, Col } from 'reactstrap';

const Title = props => {
  return <h1 className="title-semi">
    { props.children }
  </h1>
}

export default Title;
