import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* packages */
import Box from '../../util-box';
import ListingX from "../../util-list";
import Listing from "../../util-mui-list";
import Skeleton from "../../util-skeleton";
import Fetch from '../../util-fetch';
import Alert from '../../util-alert';
import Inputsx from '../../util-inputs';
import Button from '../../util-mui-buttons';
import Input from '../../util-mui-inputs';
import useForm from '../../util-hook-useform';
import useSuggest from '../../util-hook-usesuggest';
import validation from '../../util-inputs-validation';
import Stores from '../../app-plugin-stores';
import Breadcrumbs from '../../util-mui-breadcrumbs';
import DesignSectionX from '../../util-design-section';
import DesignSection from '../../util-mui-design-section';
import { Skeletons } from '../../util-atomic';

import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* stores */
import CompanyCreateStore from '../../app-module-company-container/stores/create';
/* stores */

/* loader */
import CompanyCreateLoader from '../loader';
/* loader */

import CONFIG from '../config';

const Default = observer((props) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const config = CONFIG.application.company.objects;

  /* states */
  const CompanyCreateContext  = useContext(CompanyCreateStore);
  const PutCompanyCreate = async (body) => {
    CompanyCreateContext.setLoading(true);
    CONFIG.application.company.api.headers.Authorization = token;
    const companyCreateFetch = await Fetch({
      url: CONFIG.application.company.api.endpoints.create.url,
      config: {
        method: CONFIG.application.company.api.endpoints.create.method,
        headers: CONFIG.application.company.api.headers,
        body
      }
    });
    CompanyCreateContext.setStatus(companyCreateFetch[0]);
    CompanyCreateContext.setData(companyCreateFetch[1]);
    CompanyCreateContext.setLoading(false);
  }
  const PutCompanyCreateRedirect = (data) => {
    props.history.push('/company/l/success-company-create');
  };
  const CompanyCreateData = CompanyCreateContext.isData();

  useEffect(() => {
    if (CompanyCreateData) PutCompanyCreateRedirect(CompanyCreateData);
  }, [CompanyCreateData]);
  /* states */

  /* handles */
  const handleCreateCompany = async () => {
    await PutCompanyCreate(JSON.stringify(values))
  }
  /* handles */

  const initials = (config) => {
    let values = {};
    for (const key in config) { values[key] = config[key].initial; }
    return values;
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(handleCreateCompany, validation, config, initials(config));

  const name = { ...config.name, errors: errors.name, value: values.name, handleChange };
  //const emailAddress = { ...config.emailAddress, errors: errors.emailAddress, value: values.emailAddress, handleChange };
  const mobile = { ...config.mobile, errors: errors.mobile, value: values.mobile, handleChange };
  const NZBN = { ...config.NZBN, errors: errors.NZBN, value: values.NZBN, handleChange };
  const physicalAddress1 = { ...config.physicalAddress1, errors: errors.physicalAddress1, value: values.physicalAddress1, handleChange };
  const physicalAddress2 = { ...config.physicalAddress2, errors: errors.physicalAddress2, value: values.physicalAddress2, handleChange };

  if (CompanyCreateContext.loading) return <CompanyCreateLoader />

  return <Row>
    <Col xs="12" sm="12" md="12" lg="12" xl="12">
      <Breadcrumbs>
        <Link to={"/company"}><small>Home</small></Link>
        <small>Create</small>
      </Breadcrumbs>
      <DesignSection.Title>Create Company</DesignSection.Title>
    </Col>
    <Col xs="12" sm="12" md="6" lg="3" xl="3">
      <form onSubmit={handleSubmit} noValidate>
        <Input.Text {...name} />
        {/* <Input.Text {...emailAddress} /> */}
        <Input.Text {...mobile} />
        <Input.Text {...NZBN} />
        <Input.Text {...physicalAddress1} />
        <Input.Text {...physicalAddress2} />
        <section className="space-standard-top space-standard-bottom">
        <Button.Normal>Add Company</Button.Normal>
        </section>
      </form>
    </Col>
  </Row>

});

export default Default;
