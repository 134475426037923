import React from 'react'
import { Link } from 'react-router-dom';

import DesignSection from '../util-mui-design-section';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';

import CONFIG from './config';

const App = props => {
  const url = props.match ? props.match.url : "";
  const { label, prefix } = CONFIG.routes;
  return <DesignSection.ModuleName>
    <ListItem button onClick={()=>{props.history.push(prefix)}} >
  <ListItemIcon><AddShoppingCartRoundedIcon style={{color:url===prefix?'#2196F3':'grey'}} /></ListItemIcon>
    <Link style={{color:url===prefix?'#2196F3':'grey'}}  to={prefix}>{label}</Link>
    </ListItem>
  </DesignSection.ModuleName>
}

export default App;
