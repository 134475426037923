import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Search";
const server = null;
const service = null;
const options = {
  status: [
    {name: "Quoted", value: "1"},
    {name: "Payment Confirmed", value: "6"},
    {name: "Pending Activation", value: "7"},
    {name: "Activated", value: "5"}
  ]
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients?name=name",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients",
            method: 'GET'
          },
          listcorp: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-Corp/clients",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/accounts",
            method: "GET",
          },
        }
      },
      objects: {
        name: { name: "name", initial: "", type: "text", label: "Company", helpText: false,  rules: false },
      },
    }
  },
  routes: {
    label: "Search",
    prefix: "/company/search"
  },
  setup,
  environment
}

export default config;
