import React from 'react';

import './style.css';

import Icon from './icon';

const icon = props => {
	return <svg width={props.width} viewBox="0 0 571.3 58.8">
		<Icon />
	</svg>
}

export default icon;
