import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company List";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: null,
          moduleCode: "WGMB",
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients",
            method: "GET",
          },
          listcorp: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-Corp/clients",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/accounts",
            method: "GET",
          },
        },
      },
    },
  },
  routes: {
    label: "List",
    prefix: "/company/list",
  },
  setup,
  environment,
};

export default config;
