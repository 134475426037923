import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image
} from "@react-pdf/renderer";

import styles from "./styles-index";

/* module */
import PDFHeader from "./header";
import PDFHeaderTitle from "./header-title";
import PDFHeaderLabel from "./header-label";
import PDFHeaderData from "./header-data";
import PDF_CASH_Label from "./A_CASH-label";
import PDF_CHEQUE_Label from "./A_CHEQUE-label";
import PDF_EFTPOSVISAMCARD_Label from "./A_EFTPOSVISAMCARD-label";
import PDF_AMEX_Label from "./A_AMEX-label";
import PDF_DINERS_Label from "./A_DINERS-label";
import PDF_GIFTVOUCHERS_Label from "./A_GIFTVOUCHERS-label";
import PDF_GIFTCARD_Label from "./A_GIFTCARD-label";
import PDF_DIRECTCREDIT_Label from "./A_DIRECTCREDIT-label";
import PDF_REPRESENTED_Label from "./A_TITLE_REPRESENTED-label";
import PDF_COMMENTS_Label from "./A_TITLE_COMMENTS-label";
/* module */

const index = ({ reportData, startDate, endDate, runDate, runTime }) => {
  /* data props */
  const headerData = { reportData };
  const titleData = { reportData, startDate, endDate, runDate, runTime };
  /* data props */

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <PDFHeader />
        <PDFHeaderTitle {...titleData} />
        <PDFHeaderLabel />
        <PDFHeaderData {...headerData} />

        <PDF_REPRESENTED_Label />

        <PDF_CASH_Label />
        <PDF_CHEQUE_Label />
        <PDF_AMEX_Label />
        <PDF_DINERS_Label />
        <PDF_GIFTVOUCHERS_Label />
        <PDF_GIFTCARD_Label />
        <PDF_DIRECTCREDIT_Label />

        <PDF_COMMENTS_Label />
      </Page>
    </Document>
  );
};

export default index;
