import React, { useState, useEffect } from 'react';

const useSuggest = (callback, renderTemplate, collections, resultAttrFinder) => {

  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : collections.filter(lang => {
      const regex = new RegExp(inputValue,'gi')
      //const data = lang[resultAttrFinder].toLowerCase().slice(0, inputLength);
      const data = lang[resultAttrFinder];
      return data.match(regex);
    });
    // let count = 0;
    // return inputLength === 0 ? [] : collections.filter(lang => {
    //   console.log('wow...');
    //   const keep = count < 5 && lang.name.slice(0, inputLength).toLowerCase() === inputValue;
    //   if (keep) {
    //    count += 1;
    //   }
    //   console.log(keep, ' KEEP');
    //   return keep;
    // });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => {
    return JSON.stringify({suggestion});
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => {
    return renderTemplate(suggestion);
  }

  const onResetSelected = () => {
    setIsSelected(false);
    setSelected(false);
    setValue('');
    callback(false);
  }

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
    if (method === 'click') {
      callback(newValue);
      setSelected(newValue);
      setIsSelected(true);
    }
  };

  const onLoad = (newValue) => {
    setValue(newValue);
    callback(newValue);
    setSelected(newValue);
    setIsSelected(true);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    value,
    onChange
  };

  const suggestionProps = {
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    inputProps
  }

  return [
    onLoad,
    onResetSelected,
    isSelected,
    selected,
    suggestionProps,
    setSelected,
    setIsSelected,
    setValue
  ]
};

export default useSuggest;
