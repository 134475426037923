import React from 'react'
import { Link } from 'react-router-dom';

import Conf from './config';

const Atom =  props => {
  const label = Conf.routes.label;
  const prefix = Conf.routes.prefix;
  return <p>
    <Link to={prefix}>{label}</Link>
  </p>
}

export default Atom;
