import { useState, useEffect } from 'react';

const Initial = (config) => {
  const initial = {};
  Object.keys(config).map((data, index) => {
    initial[data] = config[data].initial;
  });
  return initial;
}

const useForm = (callback, validate, config, initials) => {

  const [values, setValues] = useState(Initial(config));
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      callback();
      setIsSubmit(false);
    }
  }, [errors, values, isSubmit]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const { dataError, dataset } = validate.Multiple(config, values, errors, values);
    setErrors(dataError);
    setIsSubmit(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    const { dataError, dataset } = validate.Single(config, { [event.target.name]: event.target.value }, errors, values);
    setErrors(dataError);
    setIsSubmit(false);
  };

  const handleCheck = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
    const { dataError, dataset } = validate.Single(config, { [event.target.name]: event.target.checked }, errors, values);
    setErrors(dataError);
    setIsSubmit(false);
  };

  const handleValue = (key, value) => {
    setValues(values => ({ ...values, [key]: value }));
    const { dataError, dataset } = validate.Single(config, { [key]: value }, errors);
    setErrors(dataError);
    setIsSubmit(false);
  }

  return {
    handleChange,
    handleCheck,
    handleSubmit,
    handleValue,
    values,
    setValues,
    errors,
  }

};

export default useForm;
