import product from '../../../app-module-product-container'
import company from '../../../app-module-company-container'
import reporting from '../../../app-module-reporting-container'
import orders from '../../../app-module-order-container'

const Modules = {
    company,
    orders,
    reporting,
}
export default Modules;
  