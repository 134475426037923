import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
  /* Title */
  import { ModuleTitle, ModuleSubTitle, ModuleLink } from '../../util-atomic-design';
  /* Title */
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONSTANTS */

const response = props => {

  const { data } = props
  const { outcomeDeveloperMessage } = data;
  let message = "Order is already activated.";
  if (outcomeDeveloperMessage === "Token has expired.") {
    message = "Activation token has expired. Please contact support."
  } 

  return <Fragment>
    <Typography align="center" gutterBottom>
      <img src={LOGO} width="40%" />
    </Typography>
    <img src={LANDINGGRAPHICS} width="100%"/>
    <ModuleTitle>Something went wrong!</ModuleTitle>
    <ModuleSubTitle>{ message }</ModuleSubTitle>
  </Fragment>
}

export default response;
