import React, { useEffect } from 'react';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import ContactDetailLoader from '../loader/detail';
import ContactInfo from '../info';
/* module */

/* functions */
import { ContactDetail } from '../../app-module-contact-container/functions';
/* functions */

import CONFIG from '../config';

const Index = observer(({companyId, contactId}) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  /* states */
  const { contactInfoContext, contactInfoMethod, contactInfoData } = ContactDetail({CONFIG, token, companyId, contactId});

  useEffect(() => {
    contactInfoMethod();
  }, [contactId]);
  /* states */

  /* data : false */
  /* no available data yet... */
  if (!contactInfoData) return <ContactDetailLoader />
  /* data : false */

  /* data props */
  const infoData = { ...contactInfoData }
  /* data props */

  return <ContactInfo {...infoData} />

});

export default Index;
