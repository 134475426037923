import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperMd, ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleSmallTitle } from '../../lib-atomic-design-section';
/* packages */

const index = ({clientEmail, clientMobile, clientName, clientNzbn}) => {

  return <ModuleWrapperMd>
    <ModulePaperWrapper>

      <ModuleSmallTitle>COMPANY</ModuleSmallTitle>
      <Typography variant="h6">
        <b>{ clientName }</b>
      </Typography>
      <Typography variant="body2">
        <b>{ clientMobile }</b>
      </Typography>
      <Typography variant="body2">
        <b>{ clientNzbn }</b>
      </Typography>

    </ModulePaperWrapper>
  </ModuleWrapperMd>


}

export default index;
