import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperInfo } from '../../util-atomic-design';
/* packages */

const index = ({name, emailAddress, mobile, NZBN}) => {
  return <ModuleWrapperInfo>
    <Typography variant="body2">
      <b>{ name }</b>
    </Typography>
    <Typography variant="body2">
      { emailAddress }
    </Typography>
    {/* <Typography variant="body2" gutterBottom>
      { mobile }
    </Typography> */}
    <Typography variant="body2" gutterBottom>
      { NZBN }
    </Typography>
  </ModuleWrapperInfo>
}

export default index;
