import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Options from './select-option';
import { Invalid } from '../feedbacks';

const Select = ({name, type, label, helpText, options, errors, value, onChange}) => {
  const data = { errors, options };
  return <div>
    <TextField
      select
      fullWidth
      error={errors ? true : false}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      helperText={helpText}
      variant="outlined"
      margin="dense"
    >
    <Options {...data} />
    </TextField>
    <Invalid {...data} />
  </div>
}

export default Select;
