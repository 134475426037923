import Rules from "./rules";

const getRules = (lists, config, field, dataset, allValues) => {
  const collection = [];
  for (let i=0;i<lists.length;i++) {
    let validation = Rules[lists[i]];
    let value = dataset[field];
    let data = { value, config, dataset, allValues };
    let result = validation(data);
    if (result !== true) collection.push(result);
  }
  return collection.length > 0 ? collection : false;
}

const setDataset = (config, dataset) => {
  let values = {}
  for (const key in config) {
    values[key] = dataset[key] ? dataset[key] : '';
  }
  return values;
}

const multiValidation = (config, dataset, errors, allValues) => {
  // console.log('multi...');
  let dataError = errors;
  dataset = setDataset(config, dataset);
  for (const key in dataset) {
    let rules = config[key].rules;
    let fieldErrors = getRules(rules, config[key], key, dataset, allValues);
    if (fieldErrors) dataError[key] = fieldErrors;
    if (!fieldErrors) delete dataError[key];
  }
  return { dataError, dataset };
}

const singleValidation = (config, dataset, errors, allValues) => {
  
  let dataError = errors;
  for (const key in dataset) {
    let rules = config[key].rules;
    let fieldErrors = getRules(rules, config[key], key, dataset, allValues);
    if (fieldErrors) dataError[key] = fieldErrors;
    if (!fieldErrors) delete dataError[key];
  }
  return { dataError, dataset };
}

export default {
  multiValidation,
  singleValidation
};