import React, { useState, useEffect, Fragment } from 'react';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import useForm from '../../util-hook-useform';
import useSuggest from '../../util-hook-usesuggest';
import validation from '../../util-inputs-validation';
import Input from '../../util-mui-inputs';
import { Inputs, Buttons } from '../../util-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
import { Invalid } from '../../lib-atomic';
/* packages */

/* module */
import StepsLoader from '../loader/steps';
import ProductDefaultInfo from '../../app-module-product-detail/info/default';
/* module */

/* functions */
import { ProductCat1List, ProductCat2List, ProductCat3List, ProductCat4List } from '../../app-module-product-container/functions';
/* functions */

import CONFIG from '../config';


/* CONSTANTS */
const MAXTOPUP = CONFIG.maxTopup;
const TOPUPFAILRESPONSE = CONFIG.application.order.responses.failTotal;
/* CONSTANTS */

const Index = observer(({ handleNext, handleBack, handleReset, handleCollection, collection }) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const formObjects = CONFIG.application.order.objects;

  /* states */
  /* product: cards and carriers */
  // const [totalValid, setTotalValid] = useState(true);
  //const { productCat2ListContext, productCat2ListMethod, productCat2ListData } = ProductCat2List({CONFIG, token});
  const { productCat3ListContext, productCat3ListMethod, productCat3ListData } = ProductCat3List({CONFIG, token});

  useEffect(() => {
    productCat3ListMethod();
  }, []);
  /* states */

  const productSource = productCat3ListData ? productCat3ListData.products : false;

  const productTemplate = (data) => {
    return <ProductDefaultInfo {...data} />
  }

  const productNext = () => {
    handleNext();
  }

  const productBack = () => {
    handleBack();
  }

  const checkTotal = ({value, quantity}) => {
    const giftCards = collection.products.filter(data => data.subClassId !== 3);
    let total = collection.products.map(data => {
      return data.value * data.quantity;
    });
    total = total[0] + (value *  quantity);
    if (total >= MAXTOPUP) return false;
    return true;
  }

  // const productValidate = () => {
  //   if (checkTotal(values)) {
  //     const { suggestion } = JSON.parse(selectedProduct);
  //     const total = values.value * values.quantity;
  //     const newProduct = {...values, ...suggestion, total}
  //     //handleCollection('status', true); // all good to proceed in checkout quote/order
  //     handleCollection('products', newProduct);
  //     values.value = "";
  //     values.quantity = "";
  //     values.product = "";
  //     setSelectedProduct(false);
  //     setIsSelectedProduct(false);
  //     setSelectedValue("");
  //     setTotalValid(true);
  //   } else {
  //     setTotalValid(false);
  //   }
  // }

  const productValidate = () => {
    const { suggestion } = JSON.parse(selectedProduct);
    const total = values.value * values.quantity;
    const newProduct = {...values, ...suggestion, total}
    //handleCollection('status', true); // all good to proceed in checkout quote/order
    handleCollection('products', newProduct);
    values.value = "";
    values.quantity = "";
    values.product = "";
    setSelectedProduct(false);
    setIsSelectedProduct(false);
    setSelectedValue("");
}

  const productAdd = (data) => {
    if (!data) return handleValue('product', '');
    const { suggestion } = JSON.parse(data);
    return handleValue('product',  String(suggestion.productId));
  }

  const [
    onLoadCompany,
    onResetProduct,
    isSelectedProduct,
    selectedProduct,
    suggestionProduct,
    setSelectedProduct,
    setIsSelectedProduct,
    setSelectedValue,
  ] = useSuggest(productAdd, productTemplate, productSource, 'description');

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(productValidate, validation, formObjects, false);

  /* form: step 3 */
  const productFormObject = { ...formObjects.product, errors: errors.product, value: values.product, handleChange: handleChange };
  const valueFormObject = { ...formObjects.value, errors: errors.value, value: values.value, handleChange: handleChange };
  const quantityFormObject = { ...formObjects.quantity, errors: errors.quantity, value: values.quantity, handleChange: handleChange };
  const failTotal = { error: [TOPUPFAILRESPONSE] }
  /* form: step 3 */

  if (!productSource) return <StepsLoader />

  const isProductCollection = collection.products.length > 0 ? true : false;

  return <form onSubmit={handleSubmit} noValidate>
    <p>Select a product (Gift Cards)</p>
    <Input.Suggest {...productFormObject} suggestionProps={suggestionProduct} isSelected={isSelectedProduct} selectedValue={selectedProduct} displayTemplate={productTemplate}>
      <Buttons.PrimarySmall onClick={onResetProduct}>Remove</Buttons.PrimarySmall>
    </Input.Suggest>
    <hr />
    {/* { !totalValid &&
      <Fragment>
      <Invalid {...failTotal} />
      <br/>
      </Fragment>
    } */}
    <Input.Text  {...quantityFormObject} />
    <Input.Text {...valueFormObject} />
    <br/>
    <Buttons.Normal>Add product</Buttons.Normal>
    <br/>
    <hr />
    <Buttons.DefaultSmallNon onClick={productBack}>Back</Buttons.DefaultSmallNon>
    { isProductCollection && <Buttons.DefaultSmallNon onClick={productNext}>Next</Buttons.DefaultSmallNon> }
  </form>

});

export default Index;
