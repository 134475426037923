import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const WrapperMain = props => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Grid container>
      { props.children }
    </Grid>
  </div>
}

export default WrapperMain;
