import React, { useContext } from 'react'
import { Route, Switch } from "react-router-dom";

import CONFIG from './config';

import Default from './default'

const Atom = props => {
  const { label, prefix } = CONFIG.routes;
  return <Route exact path={prefix} component={Default} />
}

export default Atom;
