import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Delete";
const server = null;
const service = null;
const initDate = new Date();
const initDateFormat = initDate.toISOString().split('T')[0]; // yyyy-mm-dd
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        endpoints: {
          detail: {
            headers: {
              'Authorization': null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{id}',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: 'GET'
          },
          delete: {
            headers: {
              'Authorization': null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{id}',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: 'DELETE'
          }          
        }   
      }
    }
  },
  routes: {
    label: "Orders Delete",
    prefix: "/orders/delete/:orderId"
  },
  setup,
  environment
}

export default config;
