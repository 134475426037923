import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* container */
import OrderContainer from '../../app-module-order-container/default';
/* container */

/* packages */
import { ModuleWrapperXl, ModuleWrapperLeft } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* modules */
import Loader from '../loader/orders';
import OrdersDetail from '../detail/orders';
/* modules */

/* section */
import Nav from '../section/nav';
import Title from '../section/title';
import Action from '../section/action';
/* section */

/* stores */
import { OrderDetail } from '../../app-module-order-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const DETAIL = CONFIG.application.order.api.endpoints.detail;
const DETAILCONFIG = {
  url: DETAIL.url,
  method: DETAIL.method,
  headers: DETAIL.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = observer((props) => {

  const { token } = LocalStorage.getLS().login;
  const { orderId } = props.match.params;

  /* state */
  DETAILCONFIG.headers = { ...DETAILCONFIG.headers, Authorization: token }
  const [ orderDetailContext, orderDetailMethod ] = FetchContextApi(DETAILCONFIG, OrderDetail);

  useEffect(() => {
    orderDetailMethod({query: orderId});
  }, [orderId]);
  /* state */

  /* data props */
  const detailData = { props, ...orderDetailContext.data }
  /* data props */
  
  /* data loader */
  if (orderDetailContext.loading || !orderDetailContext.data) {
    return <OrderContainer {...props} >
      <Loader />
    </OrderContainer>
  }
  /* data loader */
  
  return <OrderContainer {...props} >

    <ModuleWrapperXl>

      <Nav {...props} />
      <Title {...props} />
      <Action {...detailData} />

    </ModuleWrapperXl>

    <ModuleWrapperXl>

      <ModuleWrapperLeft>
      <OrdersDetail {...detailData} />
      </ModuleWrapperLeft>

    </ModuleWrapperXl>

  </OrderContainer>

});

export default index;
