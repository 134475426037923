import axios from "axios";

const useFetch = async ({ url, formData, config }) => {
  try {
    console.log(url, config);
    const response = await axios.post(url, formData, config);
    console.log(response);
    const { data, status } = response;
    console.log(data, "DATA AXIOS");
    // if (!response.ok) throw data;
    // if (!status >= 200 || !status <= 299) throw data;
    if (status < 200 || status >= 300) throw data;
    return [true, data];
  } catch (e) {
    return [false, e];
  }
};

export default useFetch;
