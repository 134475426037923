import React, { useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* stores */
import { Report19 } from '../../app-module-reporting-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const SAVEAS = require('file-saver');
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const REPORT19 = CONFIG.application.report.api.endpoints.report19;
const REPORT19OBJECTS = CONFIG.application.report.api.objects;
const REPORT19CONFIG = {
  url: REPORT19.url,
  method: REPORT19.method,
  headers: REPORT19.headers,
  responseType: 'blob'
}
/* CONSTANTS */

const index = observer((props) => {

  const { token } = LocalStorage.getLS().login;

  /* state */
  REPORT19CONFIG.headers = { ...REPORT19CONFIG.headers, Authorization: token }
  const [ report19Context, report19Method ] = FetchContextApi(REPORT19CONFIG, Report19);
  /* state */

  const handleDownload = async () => {
    const filename = new Date().toLocaleString();
    const query = Object.keys(values).map(key => key + '=' + values[key]).join('&');
    await report19Method({query});
    if (report19Context.data.blob) {
      SAVEAS(report19Context.data.blob, filename + '.xlsx');
      props.history.push("/reporting/l/download-xlsx/19");
    }
  }

  const handleCancel = async () => {
    props.history.push("/reporting");
  }

  const {
    values,
    onChange,
    onPick,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleDownload, REPORT19OBJECTS);

  /* data props */
  const formatDate = { formatDisplay: "dd/MM/yyyy", formatProcess: "YYYY-MM-DD" }
  const startDate = { ...REPORT19OBJECTS.startDate, ...formatDate, error: error.startDate, value: values.startDate, onPick };
  const endDate = { ...REPORT19OBJECTS.endDate, ...formatDate, error: error.endDate, value: values.endDate, onPick };
  const submitButton = { size: "small" }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <form onSubmit={onSubmit} noValidate>
    {
      /*
      accountLoginContext.data.invalidToken &&
        <Fragment>
        <Invalid {...failLogin} />
        <br/>
        </Fragment>
      */
    }
    <Inputs.Date {...startDate} />
    <Inputs.Date {...endDate} />
    <br/>
    <br/>
    <Buttons.Normal {...submitButton}>Search</Buttons.Normal>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
  </form>

});

export default index;
