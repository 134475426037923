import React from 'react';

import { Row, Col } from 'reactstrap';

const Wrapper = props => {
  return <Row>
    <Col>
    { props.children }
    </Col>
  </Row>
}

export default Wrapper;
