import React from 'react';
import { Link } from 'react-router-dom';

/* container */
import ReportingContainer from '../../app-module-reporting-container/default';
/* container */

/* packages */
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

/* modules */
import Report20Form from '../form/report20';
/* modules */

import CONFIG from '../config';

/* CONTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONTANTS */

const index = props => {

  return <ReportingContainer {...props} >
    <ModuleContainerSm>
      <ModulePaperWrapper>
      <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
      <ModuleTitle>Summary Report</ModuleTitle>
      <ModuleText>Please indicate the date range below and download</ModuleText>
      <br/>
      <Report20Form {...props} />
      </ModulePaperWrapper>
    </ModuleContainerSm>
  </ReportingContainer>

}

export default index;
