import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "login";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode[environment]
        },
        endpoints: {
          activation: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/activation',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/activation",
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Login",
    prefix: "/u/order-activation"
  },
  setup
}

export default config;
