import React, { useEffect } from 'react';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import CompanyDetailLoader from '../loader/detail';
import CompanyInfo from '../info';
/* module */

/* functions */
import { CompanyDetail } from '../../app-module-company-container/functions';
/* functions */

import CONFIG from '../config';

const Index = observer(({companyId}) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  /* states */
  const { companyInfoContext, companyInfoMethod, companyInfoData } = CompanyDetail({CONFIG, token, companyId});

  useEffect(() => {
    companyInfoMethod();
  }, [companyId]);
  /* states */

  /* data : false */
  /* no available data yet... */
  if (!companyInfoData) return <CompanyDetailLoader />
  /* data : false */

  /* data props */
  const { clients } = companyInfoData;
  const listData = { ...clients[0] }
  // if (infoData.length) {
  //  return <  page 404? that says data not exist  / >
  // }
  /* data props */

  console.log(companyInfoData.clients.slice())

  return <CompanyInfo {...listData} />

});

export default Index;
