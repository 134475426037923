import React from 'react';

import { PDFDownloadLink } from "@react-pdf/renderer";

/* module */
import PDFDocument from './document';
/* module */

const PDFLink = props => {
  const { pdfFilename, pdfPreviewData, pdfPreviewSize } = props;
  return <PDFDownloadLink
    document={<PDFDocument {...pdfPreviewData} />}
    fileName={pdfFilename}>
    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : props.children)}
  </PDFDownloadLink>
}

export default PDFLink;
