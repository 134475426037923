import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* container */
import OrderContainer from '../../app-module-order-container/default';
/* container */

/* packages */
import { ModuleWrapperMd, ModuleWrapperXl } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* modules */
import Loader from '../loader/orders';
import OrdersListing from '../list/orders';
/* modules */

/* section */
import Nav from '../section/nav';
import Title from '../section/title';
import Action from '../section/action';
/* section */

/* stores */
import { OrderList } from '../../app-module-order-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const SAVEAS = require('file-saver');
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const LIST = CONFIG.application.order.api.endpoints.list;
const LISTCONFIG = {
  url: LIST.url,
  method: LIST.method,
  headers: LIST.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = observer((props) => {

  const { token } = LocalStorage.getLS().login;
  const searchValues = props.location.state;
  const query = searchValues ? Object.keys(searchValues).map(key => key + '=' + searchValues[key]).join('&') : 'clientName=""';
  
  /* state */
  LISTCONFIG.headers = { ...LISTCONFIG.headers, Authorization: token }
  const [ orderListContext, orderListMethod ] = FetchContextApi(LISTCONFIG, OrderList);

  useEffect(() => {
    orderListMethod({query: "?" + query});
  }, [query]);
  /* state */
 
  /* data props */
  const listData = { props, ...orderListContext.data }
  /* data props */

  /* data loader */
  console.log(orderListContext.loading, orderListContext.data, ' TEST... ');  
  if (orderListContext.loading || !orderListContext.data) {
    return <OrderContainer {...props} >
      <Loader />
    </OrderContainer>
  }
  /* data loader */
  
  return <OrderContainer {...props} >

    <ModuleWrapperXl>

      <Nav {...props} />
      <Title {...props} />
      <Action {...listData} />

    </ModuleWrapperXl>

    <ModuleWrapperMd>

      <OrdersListing {...listData} />

    </ModuleWrapperMd>

  </OrderContainer>

});

export default index;
