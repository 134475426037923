import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */

/* sections */
import Title from '../section/title';
import Nav from '../section/nav';
/* sections */

/* form */
import LoginForm from '../form/login';
/* form */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
/* CONSTANTS */

const index = props => {

  return <Fragment>
    <br/>
    <br/>
    <ModulePaperWrapper>
      <Typography align="center" gutterBottom>
        <img src={LOGO} width="50%" />
      </Typography>
      <br/>
      <Typography variant="h6" align="center" gutterBottom>
        <b>Welcome to Westfield Corporate Gift Card Management System</b>
      </Typography>      
      <br/>
      <LoginForm {...props} />
    </ModulePaperWrapper>
  </Fragment>

}

export default index;
