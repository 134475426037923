import React, { Fragment } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

const SelectOptions = ({options}) => {
  return options.map((option, index) => {
    return <MenuItem key={index} value={option.value}>
      {option.name}
    </MenuItem>
  });
}

export default SelectOptions;
