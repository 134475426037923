import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

const productsItem = (product) => {
  console.log(product);
  const total = (product.quantity * product.value).toFixed(2);
  return (
    <View style={[styles.container]}>
      <View style={styles.itemContainer}>
        <Text></Text>
      </View>
      <View style={styles.totalContainer}>
        <Text></Text>
      </View>
      <View style={[styles.courierTotalContainer, styles.alignRightContainer]}>
        <View style={styles.transparentline}></View>
        <Text>{product.details.productName}*:&nbsp;&nbsp;</Text>
      </View>
      <View style={[styles.courierTotalContainer2, styles.alignRightContainer]}>
        <View style={styles.line}></View>
        <Text style={styles.TotalTitle}>${total}</Text>
      </View>
    </View>
  );
};

export default productsItem;
