import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Contacts List";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    contacts: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': 'WGMB'
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          },
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "List",
    prefix: "/company/:companyId/contacts/edit/:contactId"
  },
  setup
}

export default config;
