import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Buttons } from '../../lib-atomic';
/* packages */

const action = props => {

  return null;

}

export default action;
