import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Logout";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: null,
        endpoints: null
      },
      objects: null,
      responses: null
    }
  },
  routes: {
    label: "Logout",
    prefix: "/u/logout"
  },
  setup,
  environment
}

export default config;
