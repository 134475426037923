import React, { Component } from "react";

/* packages */
import Icon from '../../util-svgs';
/* packages */

import CONFIG from '../config';

const Index = props => {
  const logo = CONFIG.setup.assets.logo;
  return <img src={logo} width="100" />
}

export default Index;
