import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const Invalid = props => {
  const { errors } = props;
  if (!errors) return null;
  const messages = errors.map((error, index) => {
    return <p key={index}>
      {error}
    </p>
  });
  return <FormHelperText error>
    { messages }
  </FormHelperText>
}

export default Invalid;
