import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color: theme.palette.text.secondary,
  },
}));

const Column = ({data, index}) => {
  return <Grid item xs key={index}>
    <Typography variant="subtitle2" gutterBottom>
      { data }
    </Typography>
  </Grid>
}

const Row = props => {
  const { config, data } = props || [];
  if (data.length < 1) return null;
  const col = config.map((data, index) => {
    return Column({data: data.label, index});
  });
  return <Grid container spacing={1}>
    { col }
  </Grid>
}

const BodyColumn = ({data, cindex}) => {
  return <Grid item xs key={cindex}>
    { data }
  </Grid>
}

const BodyRow = props => {
  const { config, data } = props || [];
  return data.map((data, index) => {
    const col = config.map((cdata, cindex) => {
      let template = cdata.template;
      let content = !template ? data[cdata.field] : template(data[cdata.field], data);
      return BodyColumn({data: content, cindex});
    })
    return <Grid container spacing={1} key={index}>
      { col }
    </Grid>
  });
}

const Normal = props => {

  const classes = useStyles();

  const Header = Row(props);
  const Body = BodyRow(props);

  return <div className={classes.root}>
    { Header }
    { Body }
  </div>
}

export default Normal;
