import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Detail";
const server = null;
const service = null;
const initDate = new Date();
const initDateFormat = initDate.toISOString().split("T")[0]; // yyyy-mm-dd
const options = {
  payment_options: [
    { name: "Cash", value: "1" },
    { name: "Debit Card", value: "2" },
    { name: "Amex", value: "6" },
    { name: "Visa", value: "4" },
    { name: "MasterCard", value: "5" },
    { name: "Gift Voucher", value: "16" },
    { name: "Direct Credit", value: "22" },
  ],
  delivery_options: [
    { name: "Courier", value: "1" },
    { name: "Collection", value: "2" },
  ],
};
const products = setup.setup.thor.products[environment];
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          detail: {
            headers: {
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: "GET",
          },
          confirmation: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: "POST",
          },
          sendattachment: {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: null,
            },
            doc: "",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/mail/invoice",
            method: "POST",
          },
        },
        objects: {
          // starting_0: { name: "starting_0", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_1: { name: "starting_1", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_2: { name: "starting_2", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_3: { name: "starting_3", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_4: { name: "starting_4", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_5: { name: "starting_5", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_6: { name: "starting_6", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_7: { name: "starting_7", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_8: { name: "starting_8", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },
          // starting_9: { name: "starting_9", initial: "", type: "text", label: "Starting Card Number", helpText: false, rules: ["isNotNull"] },

          // auto_starting_red: { name: "auto_starting_red", initial: "", type: "text", label: "Starting (Default GC)", helpText: false, rules: ["isNotNull"] },
          // auto_starting_hb: { name: "auto_starting_hb", initial: "", type: "text", label: "Starting (Happpy Birthday GC)", helpText: false, rules: ["isNotNull"] },
          // auto_starting_ty: { name: "auto_starting_ty", initial: "", type: "text", label: "Starting (Thank you GC)", helpText: false, rules: ["isNotNull"] },

          reference: {
            name: "reference",
            initial: "",
            type: "text",
            label: "Reference Number (optional)",
            helpText: false,
            rules: false,
          },
          payment: {
            name: "payment",
            initial: "",
            type: "select",
            label: "Payment Details",
            options: options.payment_options,
            helpText: "Please select payment details",
            rules: ["isNotNull"],
          },
          //delivery: { name: "delivery", initial: "", type: "select", label: "Delivery Option", options: options.delivery_options, helpText: "Please select delivery option", rules: ["isNotNull"] },
          note: {
            name: "note",
            initial: "",
            type: "text",
            label: "Note (optional)",
            helpText: false,
            rules: false,
          },
        },
      },
    },
    company: {
      api: {
        headers: {
          "Content-Type": "application/json",
          moduleCode: "WGMB",
        },
        endpoints: {
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
        },
      },
    },
    contacts: {
      api: {
        headers: {
          "Content-Type": "application/json",
          moduleCode: "WGMB",
        },
        endpoints: {
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
        },
      },
    },
    product: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          list_Cat1: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=1",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=1",
            method: "GET",
          },
          list_Cat2: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=2",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=2",
            method: "GET",
          },
          list_Cat3: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=3",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=3",
            method: "GET",
          },
          list_Cat4: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=4",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=5",
            method: "GET",
          },
        },
      },
    },
  },
  routes: {
    label: "Orders detail",
    prefix: "/orders/confirmation/:orderId",
  },
  setup,
  environment,
  products,
};

export default config;
