import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Report 20";
const server = null;
const service = null;
const initDate = new Date();
const initDateFormat = initDate.toISOString().split("T")[0]; // yyyy-mm-dd
const config = {
  details: {
    project,
    server,
    environment,
    service
  },
  application: {
    report: {
      api: {
        endpoints: {
          report19: {
            headers: {
              Authorization: null
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report19?",
            url:
              setup.setup.api.westfield_reporting_v1.url[environment] +
              "/Westfield-Reporting/report19?",
            method: "GET"
          },
          report20: {
            headers: {
              Authorization: null
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report20?",
            url:
              setup.setup.api.westfield_reporting_v1.url[environment] +
              "/Westfield-Reporting/report20?",
            method: "GET"
          }
        },
        objects: {
          startDate: {
            name: "startDate",
            initial: initDateFormat,
            label: "Starting Date",
            helperText: false,
            rules: ["isNotNull"]
          },
          endDate: {
            name: "endDate",
            initial: initDateFormat,
            label: "Ending Date",
            helperText: false,
            rules: ["isNotNull"]
          }
        }
      }
    }
  },
  routes: {
    label: "Summary Report",
    prefix: "/reporting/20"
  },
  setup,
  environment
};

export default config;
