import React, { useEffect, Fragment } from 'react';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import useForm from '../../util-hook-useform';
import useSuggest from '../../util-hook-usesuggest';
import validation from '../../util-inputs-validation';
import Input from '../../util-mui-inputs';
import { Buttons } from '../../util-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import StepsLoader from '../loader/steps';
/* module */

/* functions */
import { OrderingUpdate } from '../../app-module-ordering-container/functions';
/* functions */

import CONFIG from '../config';

const Index = observer(({ handleNext, handleBack, handleReset, handleCollection, collection, props }) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const { orderId } = props.match.params;
  const formObjects = CONFIG.application.notes.objects;

  /* states */
  const { orderUpdateContext, orderUpdateMethod, orderUpdateData } = OrderingUpdate({CONFIG, token, orderId});
  const orderLoading = orderUpdateContext.loading;
  /* states */

  const finishNext = () => {
    handleNext();
  }

  const finishBack = () => {
    handleBack();
  }

  const finishValidate = async () => {
    handleCollection('notes', values.notes);
    handleCollection('po', values.purchaseOrder);
    const bodyData = {
      clientId: collection.company.id ,
      clientContactId: collection.contact.id,
      purchaseOrder: collection.po,
      notes: collection.notes,
      products: [...collection.products, ...collection.fees]
    }
    await orderUpdateMethod(JSON.stringify(bodyData));
    if (orderUpdateContext.data.outcomeCode === "0") {
      props.history.push("/orders/l/success-quote-edit/" + orderId);
    }
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(finishValidate, validation, formObjects, false);

  /* form: step 3 */
  const poFormObject = { ...formObjects.purchaseOrder, errors: errors.purchaseOrder, value: values.purchaseOrder, handleChange: handleChange };
  const noteFormObject = { ...formObjects.notes, errors: errors.notes, value: values.notes, handleChange: handleChange };
  /* form: step 3 */

  if (orderLoading) return <StepsLoader />

  return <form onSubmit={handleSubmit} noValidate>
    <p>Purchase details, notes/coments</p>
    <Input.Text {...poFormObject} />
    <Input.Textarea {...noteFormObject} />
    <br/>
    <Buttons.Normal>Update quote</Buttons.Normal>
    <br/>
    <hr />
    <Buttons.DefaultSmallNon onClick={finishBack}>Back</Buttons.DefaultSmallNon>
  </form>

});

export default Index;
