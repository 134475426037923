const isMin = (data) => {
  let value = data.value;
  const config = data.config;
  const limit = config.minLimit;
  const suffix = config.suffix;
  value = value.trim();
  return (value >= limit) ? true : "minimum of " + suffix + limit;
}

export default isMin;
