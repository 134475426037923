import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Recaptcha from "react-recaptcha";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FetchContextApi,
  FormHook,
  LocalStorage,
} from "../../lib-atomic-functions";
/* packages */

/* stores */
import {
  AuthLogin,
  AuthLoginCorp,
} from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.api.objects;
const ENDPOINTS = CONFIG.application.account.api.endpoints;
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: "json",
};
const LOGINCORPOBJECTSCONFIG = {
  url: ENDPOINTS.authCorp.url,
  method: ENDPOINTS.authCorp.method,
  headers: ENDPOINTS.authCorp.headers,
  responseType: "json",
};
const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified;

/* CONSTANTS */

const login = observer((props) => {
  // const { token } = LocalStorage.getLS().login;
  /* state */
  const [loaded, setLoaded] = useState(true);
  const [verified, setVerified] = useState(true);

  console.log(AuthLogin);
  /* state */
  const [accountLoginContext, accountLoginMethod] = FetchContextApi(
    LOGINOBJECTSCONFIG,
    AuthLogin
  );
  const [accountLoginCorpContext, accountLoginCorpMethod] = FetchContextApi(
    LOGINCORPOBJECTSCONFIG,
    AuthLoginCorp
  );

  /* state */

  // useEffect(()=> {
  //   DETAILOBJECTSCONFIG.headers = { ...DETAILOBJECTSCONFIG.headers, Authorization: token}
  // }, [token]);
  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleLogin = async () => {
    setLoaded(true);
    if (verified) {
      await accountLoginMethod({ body: JSON.stringify(values), query: false });
      await accountLoginCorpMethod({
        body: JSON.stringify(values),
        query: false,
      });
      LocalStorage.addLS("login", {
        ...accountLoginContext.data.json,
        username: values.username,
      });
      LocalStorage.addLS("loginCorp", {
        ...accountLoginCorpContext.data.json,
        username: values.username,
      });

      if (accountLoginContext.data.invalidToken) return false;
      props.history.push("/u/login-logout-redirect");
    }
  };

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  );

  const username = {
    ...LOGINOBJECTS.username,
    error: error.username,
    value: values.username,
    onChange,
  };
  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
  };
  const submitButton = { fullWidth: true };
  const failLogin = { error: [LOGINFAILRESPONSE] };
  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  return (
    <form onSubmit={onSubmit} noValidate>
      {accountLoginContext &&
        accountLoginContext.data &&
        accountLoginContext.data.invalidToken && (
          <Fragment>
            <Invalid {...failLogin} />
            <br />
          </Fragment>
        )}
      {!verified && loaded && (
        <Fragment>
          <Invalid {...verifyLogin} />
          <br />
        </Fragment>
      )}
      <Inputs.Text {...username} />
      <Inputs.Password {...password} />

      <br />
      <br />
      {/* 
      <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
      /> */}

      <Buttons.Normal {...submitButton}>Login</Buttons.Normal>
    </form>
  );
});

export default login;
