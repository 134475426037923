import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* design */
import DesignSection from '../../app-design-section';
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

/* module */
import ProductDetail from '../../app-module-product-detail/detail';
/* module */

/* stores */
import { OrderDecline } from '../../app-module-order-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const DECLINE = CONFIG.application.order.api.endpoints.delete;
const DECLINEOBJECTS = CONFIG.application.order.api.objects;
const DECLINECONFIG = {
  url: DECLINE.url,
  method: DECLINE.method,
  headers: DECLINE.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = ({props, order}) => {

  const { token } = LocalStorage.getLS().login;
  const orderId = order.id;
  
  /* states */
  DECLINECONFIG.headers = { ...DECLINECONFIG.headers, Authorization: token }
  const [ orderDeclineContext, orderDeclineMethod ] = FetchContextApi(DECLINECONFIG, OrderDecline);
  /* states */

  const handleDecline = async () => {
    await orderDeclineMethod({query: orderId});
    if (orderDeclineContext.status) {
      props.history.push("/orders/l/success-quote-delete/" + orderId);
    }
  }

  const handleCancel = async () => {
    props.history.push("/orders/detail/" + orderId);
  }

  /* data props */
  const submitButton = { size: "small", onClick: handleDecline }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <Fragment>

    <img src={LANDINGGRAPHICS} width="100%"/>
    <ModuleTitle>Almost there!</ModuleTitle>
    <ModuleText>You are about to delete order number <b>{orderId}</b>. Would you like to proceed?</ModuleText>
    <br/>
    <br/>
    <Buttons.Primary {...submitButton}>Proceed and delete</Buttons.Primary>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>

  </Fragment>

}

export default index;
