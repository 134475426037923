import React from 'react';

/* packages */
import Button from '../../util-mui-buttons';
import Input from '../../util-mui-inputs';
/* packages */

const Index = ({ name, emailAddress, mobile, NZBN, physicalAddress1, physicalAddress2, onSubmit }) => {
  return <form onSubmit={onSubmit} noValidate>
    <Input.Text {...name} />
    <Input.Text {...emailAddress} />
    <Input.Text {...mobile} />
    <Input.Text {...NZBN} />
    <Input.Text {...physicalAddress1} />
    <Input.Text {...physicalAddress2} />
    <br/>
    <Button.Normal>Update Company</Button.Normal>
  </form>
}

export default Index;
