import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Orders PDF";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: "GET",
          },
          print_count: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{orderId}/printInvoice",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: "GET",
          },
        },
      },
    },
    company: {
      api: {
        headers: {
          "Content-Type": "application/json",
          moduleCode: "WGMB",
        },
        endpoints: {
          detail: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
        },
      },
    },
    contacts: {
      api: {
        headers: {
          "Content-Type": "application/json",
          moduleCode: "WGMB",
        },
        endpoints: {
          detail: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
        },
      },
    },
    product: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        endpoints: {
          list_Cat1: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=1",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=1",
            method: "GET",
          },
          list_Cat2: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=2",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=2",
            method: "GET",
          },
          list_Cat3: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=3",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=3",
            method: "GET",
          },
          list_Cat4: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=4",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=4",
            method: "GET",
          },
          list_Cat5: {
            doc:
              "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/products/?categoryId=5",
            url:
              setup.setup.api.westfield_v1.url[environment] +
              "/products/?categoryId=5",
            method: "GET",
          },
        },
      },
    },
  },
  routes: {
    label: "Orders PDF",
    prefix: "/orders/pdf/:orderId",
  },
  setup,
};

export default config;
