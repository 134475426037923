import React from 'react';

import { Skeletons } from '../../util-atomic';

const Loader = props => {
  return <div>
    <Skeletons.Xlarge />
    <Skeletons.Medium />
    <Skeletons.Large />
  </div>
}

export default Loader;
