import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-body-title';

import CONFIG from '../../../config';

const itemLabel = props => {
  return <View style={[styles.container, styles.itemsWrapper]}>
    <View style={styles.bodyTitleContainer}>
      <Text style={styles.bodyTitleText}>Represented by:</Text>
    </View>
  </View>
}

export default itemLabel;
