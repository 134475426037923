import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleLinkWrapper, ModuleLinkItem } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONSTANTS */

const index = props => {

  return <ModuleContainerSm>
    <ModulePaperWrapper>
    <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
    <ModuleTitle>Orders</ModuleTitle>
    <ModuleText>Ordering module for creating/processing/activating orders.</ModuleText>

    <br/>

    <ModuleLinkWrapper>
      <ModuleLinkItem>
        <Link to="/orders/list">View all orders</Link>
      </ModuleLinkItem>
      <ModuleLinkItem>
        <Link to="/orders/search">Search for order</Link>
      </ModuleLinkItem>
      <ModuleLinkItem>
        <Link to="/orders/create">Create a quote</Link>
      </ModuleLinkItem>
    </ModuleLinkWrapper>

    </ModulePaperWrapper>
  </ModuleContainerSm>

}

export default index;
