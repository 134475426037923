import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

class Store {

  loading = false;
  data = false;
  status = true;
  expireToken = false;

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = this.clean(status, data.json);
    this.expireToken = data.json.status === 400 ? true : false;
  }

  clean = (status, data) => {
    //if (!status || !data) return false;
    return {
      confirm: data
    }
  }


}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable,
})


export default createContext(new Store())
