import React from 'react';

import { Container } from 'reactstrap';

import Header from './header';
import Import from './imports';
import Footer from './footer';

import appConf from '../../components/app-conf';
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;

const Program = props => {
  return <Themes>
    <Container>
      <Header />
      <Import {...props} />
      <Footer />
    </Container>
  </Themes>
}

export default Program;
