import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

/* mui : styles */
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 10,
    marginBottom: 10,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));
/* mui : styles */

const WrapperInfo = props => {
  const classes = useStyles();
  return <Grid item xs={12} sm={10} md={8} lg={9} xl={9}>
    <Paper className={classes.paper}>
      { props.children }
    </Paper>
  </Grid>
}

export default WrapperInfo;
