import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* packages */
import Button from '../../util-mui-buttons';
/* packages */

const Index = ({ companyId }) => {

  return <Fragment>
    <Button.NormalSmall>
    <Link to={"/company/detail/" + companyId}>Back to detail</Link>
    </Button.NormalSmall>
  </Fragment>

}

export default Index;
