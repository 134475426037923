import React from 'react';

import Bell from './bell';
import Ringer from './ringer';

const icon = props => {
  return <svg width={props.width} viewBox="0 0 512 512">
    <Bell fill={props.fill} />
    <Ringer fill={props.fill} />
  </svg>
}

export default icon;
