import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

const Invalid = props => {
  const { errors } = props;
  if (!errors) return null;
  const messages = errors.map((error, index) => {
    return <p key={index}>{error}</p>
  });
  return <FormHelperText>
    { messages }
  </FormHelperText>
}

export default Invalid;
