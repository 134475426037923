import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import CompanyDetailStore from '../stores/detail';
/* stores */

import CONFIG from '../../config';

const Index = ({token, companyId}) => {
  const companyDetailContext  = useContext(CompanyDetailStore);
  const companyDetailMethod = async () => {
    companyDetailContext.setLoading(true);
    CONFIG.application.company.api.headers.Authorization = token;
    const fetchCorp = await Fetch({
      // url: CONFIG.application.company.api.endpoints.detail.url,
      url: CONFIG.application.company.api.endpoints.listcorp.url,
      config: {
        method: CONFIG.application.company.api.endpoints.contacts.method,
        headers: CONFIG.application.company.api.headers
      }
    });
    companyDetailContext.setStatus(fetchCorp[0]);
    const combined = fetchCorp[1];
    combined["clients"] = [...fetchCorp[1].accounts];
    companyDetailContext.setData(combined);
    companyDetailContext.setLoading(false);
  }
  const companyDetailData = companyDetailContext.isData();

  return { companyDetailContext, companyDetailMethod, companyDetailData }
}

export default Index;
