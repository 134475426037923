import React, { Fragment } from "react";

/* mui */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
/* mui */

/* packages */
import { Buttons } from "../../lib-atomic";
import {
  ModuleWrapperMd,
  ModulePaperWrapper
} from "../../lib-atomic-design-section";
import { ModuleSmallTitle } from "../../lib-atomic-design-section";
import { NumberCurrency } from "../../lib-atomic-functions";
/* packages */

/* module */
import ProductDetail from "../../app-module-product-detail/detail";
/* module */

const index = ({ index, productId, quantity, value, handleRemove }) => {
  const productData = { productId };
  const total = NumberCurrency((quantity * value).toFixed(2));
  const value_currency = NumberCurrency(Number(value).toFixed(2));
  const removeButton = {
    size: "small",
    onClick: event => {
      handleRemove(index);
    }
  };

  return (
    <ModuleWrapperMd>
      <ModulePaperWrapper>
        <ModuleSmallTitle>ITEM</ModuleSmallTitle>

        <ProductDetail {...productData} />

        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ModuleSmallTitle>AMOUNT</ModuleSmallTitle>
            <Typography variant="h6">
              <b>${value_currency}</b>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ModuleSmallTitle>QUANTITY</ModuleSmallTitle>
            <Typography variant="h6">
              <b>x{quantity}</b>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ModuleSmallTitle>TOTAL</ModuleSmallTitle>
            <Typography variant="h6">
              <b>${total}</b>
            </Typography>
          </Grid>
        </Grid>

        {handleRemove && (
          <Fragment>
            <br />
            <Buttons.Primary {...removeButton}>Remove</Buttons.Primary>
          </Fragment>
        )}
      </ModulePaperWrapper>
    </ModuleWrapperMd>
  );
};

export default index;
