import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Breadcrumbs } from '../../lib-atomic';
/* packages */

const nav = props => {
  return <Breadcrumbs.Normal>
    <Link to={"/orders"}><small>Home</small></Link>
    <Link to={"/orders/list"}><small>List</small></Link>
    <small>Search</small>
  </Breadcrumbs.Normal>
}

export default nav;
