import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* design */
import DesignSection from '../../app-design-section';
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

/* module */
import ProductDetail from '../../app-module-product-detail/detail';
/* module */

/* stores */
import { OrderSendingActivation } from '../../app-module-order-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const SENDING = CONFIG.application.order.api.endpoints.sending_activation;
const SENDINGOBJECTS = CONFIG.application.order.api.objects;
const SENDINGCONFIG = {
  url: SENDING.url,
  method: SENDING.method,
  headers: SENDING.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = ({props, order}) => {

  const { token } = LocalStorage.getLS().login;
  const orderId = order.id;
  
  /* states */
  SENDINGCONFIG.headers = { ...SENDINGCONFIG.headers, Authorization: token }
  const [ orderSendingContext, orderSendingMethod ] = FetchContextApi(SENDINGCONFIG, OrderSendingActivation);
  /* states */

  const handleDecline = async () => {
    await orderSendingMethod({query: orderId + '/resend-email'});
    if (orderSendingContext.status) {
      props.history.push("/orders/l/success-quote-send-activation/" + orderId);
    }
  }

  const handleCancel = async () => {
    props.history.push("/orders/detail/" + orderId);
  }

  /* data props */
  const submitButton = { size: "small", onClick: handleDecline }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <Fragment>

    <img src={LANDINGGRAPHICS} width="100%"/>
    <ModuleTitle>Almost there!</ModuleTitle>
    <ModuleText>You are about to send activation email for <b>{orderId}</b>. Would you like to proceed?</ModuleText>
    <br/>
    <br/>
    <Buttons.Primary {...submitButton}>Proceed and send</Buttons.Primary>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>

  </Fragment>

}

export default index;
