import React, { Fragment } from "react";
import { Link } from "react-router-dom";

/* packages */
import { Buttons } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

const action = ({ order }) => {
  const { flHeadOffice } = LocalStorage.getLS().info;
  const { id, orderStatusName, flCorporate } = order;

  const isHQ = flHeadOffice === 1 ? true : false;
  const isHQCorporate = flCorporate === 1 ? true : false;
  const delete1 =
    orderStatusName === "Quoted" || orderStatusName === "Declined";
  const decline1 = orderStatusName === "ONHOLD";
  const isQuoted =
    orderStatusName === "Quoted" ||
    orderStatusName === "Approved" ||
    orderStatusName === "ONHOLD"
      ? true
      : false;
  const isOnHoldHQ =
    orderStatusName === "ONHOLD" && flCorporate !== 1 && isHQ ? true : false;
  const isOnHoldHQCorporate = isOnHoldHQ && flCorporate === 1 ? true : false;
  const isPaymentConfirmed =
    orderStatusName === "Payment Confirmed" ? true : false;
  const isPendingActivation =
    orderStatusName === "Pending Activation" ? true : false;
  const isQuotedNotOnHold = isQuoted && !isOnHoldHQ ? true : false;
  const isActivated = orderStatusName === "Activated" ? true : false;
  const isPaymentConfirmedActivated =
    isPaymentConfirmed || isActivated ? true : false;

  /* data props */
  const linkButton = { size: "small" };
  /* data props */

  return (
    <Fragment>
      {isQuoted && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/edit/" + id}>Edit</Link>
        </Buttons.Primary>
      )}

      {isQuoted && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/pdf/" + id}>Print Quote</Link>
        </Buttons.Primary>
      )}

      {isPaymentConfirmedActivated && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/pdf/" + id}>Print Invoice</Link>
        </Buttons.Primary>
      )}

      {/* {isQuotedNotOnHold && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/delete/" + id}>Delete</Link>
        </Buttons.Primary>
      )} */}

      {delete1 && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/delete/" + id}>Delete</Link>
        </Buttons.Primary>
      )}

      {isQuotedNotOnHold && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/confirmation/" + id}>Proceed To Process</Link>
        </Buttons.Primary>
      )}

      {isOnHoldHQCorporate && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/confirmation/" + id}>Proceed To Process</Link>
        </Buttons.Primary>
      )}

      {isOnHoldHQ && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/approve/" + id}>Approve</Link>
        </Buttons.Primary>
      )}

      {decline1 && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/decline/" + id}>Decline</Link>
        </Buttons.Primary>
      )}

      {/* {isOnHoldHQ && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/decline/" + id}>Decline</Link>
        </Buttons.Primary>
      )} */}

      {isPaymentConfirmed && !isHQCorporate && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/send/activation/" + id}>Send Activation</Link>
        </Buttons.Primary>
      )}

      {isPendingActivation && !isHQCorporate && (
        <Buttons.Primary {...linkButton}>
          <Link to={"/orders/send/activation/" + id}>Send Activation</Link>
        </Buttons.Primary>
      )}

      <br />
      <br />
    </Fragment>
  );
};

export default action;
