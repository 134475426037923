import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Landing";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          list: {
            doc: '',
            url: '',
            method: ''
          }
        }
      },
      objects: null,
    },
  },
  routes: {
    label: "Landing",
    prefix: "/company"
  },
  setup,
  environment
}

export default config;
