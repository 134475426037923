import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image
} from "@react-pdf/renderer";

import styles from "./styles-header-label";

import CONFIG from "../../../config";

const itemLabel = props => {
  return (
    <View style={[styles.container, styles.itemsWrapper]}>
      {/*
      <View style={styles.headerDataContainer}>
        <Text style={styles.headerDataText}>User</Text>
      </View>
    */}
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Qty. Card Sold</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Gift Card Sales</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Service Fees</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Greeting Card Fees</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Admin Fees</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Courier</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Total</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Cash</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Eft-Pos</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Visa</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Master Card</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Amex</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Diners</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Gift Card</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Direct Credit Sum</Text>
      </View>
      <View style={styles.headerLabelContainer}>
        <Text style={styles.headerLabelText}>Total</Text>
      </View>
    </View>
  );
};

export default itemLabel;
