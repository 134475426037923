import React from 'react';

/* packages */
import Privilege from '../../components/app-conf-privilege';
import { LocalStorage } from '../../components/lib-atomic-functions';
/* packages */

const importApp = props => {
  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableModules = rolesHandler(LocalStorage, rolesModules);
  let Applications = [];
  for (const mod in availableModules) {
    const { App } = availableModules[mod];
    Applications.push(App);
  }
  return Applications.map((Module, index) => {
    return <Module {...props} key={index} />
  });
}

export default importApp;
