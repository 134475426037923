import React, { useContext } from "react";

/* packages */
import Fetch from "../../../util-fetch";
/* packages */

/* stores */
import InfoStore from "./store";
/* stores */

const Index = ({ CONFIG, token, companyId }) => {
  const companyInfoContext = useContext(InfoStore);
  const companyInfoMethod = async () => {
    companyInfoContext.setLoading(true);
    CONFIG.application.company.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.company.api.endpoints.detail.url + companyId,
      config: {
        method: CONFIG.application.company.api.endpoints.detail.method,
        headers: CONFIG.application.company.api.headers
      }
    });
    companyInfoContext.setStatus(fetch[0]);
    companyInfoContext.setData(fetch[1]);
    companyInfoContext.setLoading(false);
  };

  const companyInfoData = companyInfoContext.isData();

  return { companyInfoContext, companyInfoMethod, companyInfoData };
};

export default Index;
