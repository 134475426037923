import React, { useEffect, useContext } from "react";
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* stores */
import { OrderList, OrderDetail } from '../../app-module-order-container/stores';
/* stores */

const index = observer((props) => {

  /* ---------------- LIST */
  /* stores contexts */
  const orderListContext  = useContext(OrderList);
  const orderDetailContext  = useContext(OrderDetail);
  /* stores contexts */

  /* logout if login not available */
  const { login } = LocalStorage.getLS();
  if (!login) return <Redirect to='/u/logout' />

  /* logout > token expire */
  useEffect(() => {
    // console.log(report19Context.expireToken);
    // console.log(report20Context.expireToken);
    if (orderListContext.expireToken || orderDetailContext.expireToken) props.history.push('/u/landing-token-expire');
  }, [orderListContext.expireToken, orderDetailContext.expireToken]);

  return props.children;

});

export default index;
