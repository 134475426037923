import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* packages */
import { ModuleWrapperMain, ModuleWrapperFull, ModuleWrapperLeft, ModuleWrapperXxs } from '../../lib-atomic-design-section';
/* packages */

/* module */
import Nav from '../nav/contact';
import Title from '../title/contact';
import Form from '../form/contact';
import Loader from '../loader';
import CompanyInfoDetail from '../../app-module-company-detail/detail';
/* module */

import CONFIG from '../config';

const Index = observer((props) => {

  const { companyId } = props.match.params;

  /* data props */
  const navData = { props, companyId };
  const detailData = { companyId };
  /* data props */

  return <ModuleWrapperMain>

    <ModuleWrapperFull>
      <Nav {...navData} />
      <Title />
    </ModuleWrapperFull>

    <ModuleWrapperXxs>

      <CompanyInfoDetail {...detailData} />
      <Form {...props} />

    </ModuleWrapperXxs>

  </ModuleWrapperMain>

});

export default Index;
