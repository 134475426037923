import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#42a5f5',
    '&:hover': {
      backgroundColor: '#1976d2'
    },
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 0,
    '& a': {
      textDecoration: "none",
      display: 'block',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      fontWeight: 'bold',
      fontSize: 18,
      color: 'white',
      '&:hover': {
        color: '#e3f2fd'
      }
    }
  },

}));
/* CONSTANTS */

const link = props => {
  const classes = useStyles();
  return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    <Paper className={classes.paper} elevation={0}>
    { props.children }
    </Paper>
  </Grid>

}

export default link;
