import React, { Fragment } from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';

/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const date = props => {

  const { formatDisplay, formatProcess, value, initial, name, label, onPick } = props

  const [selectedDate, setSelectedDate] = React.useState(initial);

  const handleDateChange = (date) => {
    if (!date) {
      date = "";
    } else {
      setSelectedDate(date);
      date = moment(date).format(formatProcess);
    }
    onPick(name, date);
  }

  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {/* disableToolbar */}
    <KeyboardDatePicker
      name={name}
      label={label}
      autoOk
      fullWidth
      variant="inline"
      format={formatDisplay}
      margin="dense"
      id="date-picker-inline"
      value={selectedDate}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
    <Invalid {...props} />
  </MuiPickersUtilsProvider>
}

export default date;
