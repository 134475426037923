import React, { useContext } from "react";

/* packages */
import Fetch from "../fetch";
import FetchBlob from "../fetch-blob";
import FetchJson from "../fetch-json";
import FetchFormData from "../axios-form-data";
/* packages */

const index = ({ url, method, headers, responseType }, contextStore) => {
  const funcContext = useContext(contextStore);
  const funcMethod = async ({ body, query }) => {
    funcContext.setLoading({
      loading: true,
      status: true,
      data: { blob: false, json: false }
    });
    url = url;
    if (query) url = url + query;
    let fetch = null;
    if (responseType === "blob") {
      // blob
      fetch = await FetchBlob({
        url,
        config: {
          method,
          headers,
          body
        }
      });
    } else if (responseType === "formdata") {
      // fetch = await FetchJson({
      //   url,
      //   config: {
      //     method,
      //     headers,
      //     body
      //   }
      // });

      console.log(headers, "AXIOS");
      const newHeaders = {
        ...headers,
        method: "POST"
      };
      fetch = await FetchFormData({
        url,
        formData: body,
        config: {
          headers
        }
      });
      console.log(fetch, " FETCH AXIOS");
    } else {
      // json
      fetch = await FetchJson({
        url,
        config: {
          method,
          headers,
          body
        }
      });
    }
    console.log(fetch, method, headers, body);
    funcContext.setLoading({
      loading: false,
      status: fetch[0],
      data: fetch[1]
    });
  };
  return [funcContext, funcMethod];
};

export default index;
