import React from 'react';

import Config from './config';
import Routes from './routes';
import Links from './links';
import Launcher from './launcher';
import App from './app';

/* admin packages */
import AdminApplications from '../app-module-admin-container/packages';
/* admin packages */

/* packages */
// import Packages from './packages';
// /* packages */

// const {  Modules } = Packages;


const Dashboard = {
  dashboard: {
    Config,
    Routes,
    Links,
    Launcher,
    App
  }
}

const Applications = { ...Dashboard }

export default {
  AdminApplications,
  Applications
};
