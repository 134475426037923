import React from "react"

import './style.css';

const large = props => {
  return <div className="skeleton-container">
    <div className="line lg h60"></div>
  </div>
}

export default large;
