import Small from './small';
import Medium from './medium';
import Large from './large';
import Xlarge from './xlarge';

export default {
  Small,
  Medium,
  Large,
  Xlarge,
}
