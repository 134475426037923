import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Contact Edit";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    contact: {
      api: {
        headers: {
          "Content-Type": "application/json",
          moduleCode: "WGMB",
        },
        endpoints: {
          list: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "GET",
          },
          update: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients/{id}/contacts",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients/",
            method: "POST",
          },
        },
      },
      objects: {
        firstName: {
          name: "firstName",
          initial: "",
          label: "First Name",
          helperText: false,
          rules: false,
        },
        lastName: {
          name: "lastName",
          initial: "",
          label: "Last Name",
          helperText: false,
          rules: false,
        },
        emailAddress: {
          name: "emailAddress",
          initial: "",
          label: "Email Address",
          helperText: false,
          rules: false,
        },
        mobile: {
          name: "mobile",
          initial: "",
          label: "Contact Number",
          helperText: false,
          rules: false,
        },
        phone1: {
          name: "phone1",
          initial: "0",
          label: "Phone Number",
          helperText: false,
          rules: false,
        },
        comment: {
          name: "comment",
          initial: "",
          label: "Position",
          helperText: false,
          rules: false,
        },
        flPrimary: {
          name: "flPrimary",
          initial: "",
          label: "Opt-in",
          helperText: false,
          rules: false,
        },
        flActive: {
          name: "flActive",
          initial: "",
          label: "Active",
          helperText: false,
          rules: false,
        },
      },
    },
  },
  routes: {
    label: "Edit",
    prefix: "/company/:companyId/contacts/edit/:contactId",
  },
  setup,
};

export default config;
