import React, { useContext } from "react";

/* packages */
import Fetch from "../../../util-fetch";
/* packages */

/* stores */
import ListStore from "./store";
/* stores */

const Index = ({ CONFIG, token, searchQuery }) => {
  const companyListContext = useContext(ListStore);
  const companyListMethod = async () => {
    companyListContext.setLoading(true);
    CONFIG.application.company.api.headers.Authorization = token;
    const fetchCorp = await Fetch({
      url: CONFIG.application.company.api.endpoints.listcorp.url + searchQuery,
      config: {
        method: CONFIG.application.company.api.endpoints.listcorp.method,
        headers: CONFIG.application.company.api.headers,
      },
    });
    const fetch = await Fetch({
      url: CONFIG.application.company.api.endpoints.list.url,
      config: {
        method: CONFIG.application.company.api.endpoints.list.method,
        headers: CONFIG.application.company.api.headers,
      },
    });
    // companyListContext.setStatus(fetch[0]);
    // companyListContext.setData(fetch[1]);
    // companyListContext.setLoading(false);
    companyListContext.setStatus(fetchCorp[0]);
    const combined = fetchCorp[1];
    combined["clients"] = [...fetchCorp[1].accounts];
    combined["clients"] = combined["clients"].filter((client) =>
      client.name.toString().toLowerCase().includes(searchQuery.toString().toLowerCase().slice(6))
    );
    //current
    const current = combined["clients"].filter((client) => client.statusId.toString().includes("747"));
    //pending approval
    const pendingapproval = combined["clients"].filter((client) => client.statusId.toString().includes("752"));
    //pending activation
    const pending = combined["clients"].filter((client) => client.statusId.toString().includes("749"));
    combined["clients"] = pendingapproval.concat(pending).concat(current); 
    combined["clients"] = [...combined.clients, ...fetch[1].clients];
    companyListContext.setData(combined);
    companyListContext.setLoading(false);
  };
  const companyListData = companyListContext.isData();

  return { companyListContext, companyListMethod, companyListData };
};

export default Index;
