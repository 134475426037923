import React from "react";

/* packages */
import Privilege from '../../app-conf-privilege';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

const importApp = props => {
  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableModules = rolesHandler(LocalStorage, rolesModules);
  let Applications = [];
  for (const module in availableModules) {
    const { Launcher } = availableModules[module];
    Applications.push(Launcher);
  }
  return Applications.map((Launcher, i) => {
    return <div>
      <Launcher {...props} key={i} />
    </div>
  });
}

export default importApp;
