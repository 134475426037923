import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
/* mui */

/* module */
import OrderFormStep1 from '../form/step1_company';
import OrderFormStep2 from '../form/step2_contact';
import OrderFormStep3 from '../form/step3_product';
import OrderFormStep3Carrier from '../form/step3_product_carrier';
import OrderFormStep4 from '../form/step4_fee';
import OrderFormStep5 from '../form/step5_note';
/* module */


import CONFIG from '../config';

const index = ({collection, setCollection, props}) => {

  const steps = ['Company', 'Contact', 'Products (Gift Cards)', 'Products (Trifolds)', 'Fees', 'Purchase Order/Notes'];

  /* states: mui steps */
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
  const handleReset = () => {
    setActiveStep(0);
  }
  const step1= activeStep === 0 ? true : false;
  const step2 = activeStep === 1 ? true : false;
  const step3 = activeStep === 2 ? true : false;
  const step4 = activeStep === 3 ? true : false;
  const step5 = activeStep === 4 ? true : false;
  const step6 = activeStep === 5 ? true : false;
  /* states: mui steps */

  const handleCollection = (key, data) => {
    const asArray = ['products','fees'].indexOf(key) < 0 ? false : true;
    if (!asArray) collection[key] = data;
    if (asArray) collection[key].push(data);
    setCollection({...collection});
  }

  /* data props */
  const step1Data = { handleNext, handleBack, handleReset, handleCollection, collection }
  const step2Data = { handleNext, handleBack, handleReset, handleCollection, collection }
  const step3Data = { handleNext, handleBack, handleReset, handleCollection, collection }
  const step4Data = { handleNext, handleBack, handleReset, handleCollection, collection }
  const step5Data = { handleNext, handleBack, handleReset, handleCollection, collection }
  const step6Data = { handleNext, handleBack, handleReset, handleCollection, collection, props }
  /* data props */

  return <Stepper activeStep={activeStep} orientation="vertical">
    {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
        <StepContent>
          { step1 && <OrderFormStep1 {...step1Data} /> }
          { step2 && <OrderFormStep2 {...step2Data} /> }
          { step3 && <OrderFormStep3 {...step3Data} /> }
          { step4 && <OrderFormStep3Carrier {...step4Data} /> }
          { step5 && <OrderFormStep4 {...step5Data} /> }
          { step6 && <OrderFormStep5 {...step6Data} /> }
        </StepContent>
      </Step>
    ))}
  </Stepper>

}

export default index;
