import React, { useContext, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Moment from "react-moment";
import LuhnJS from "luhn-js";

import { Row, Col } from "reactstrap";

/* design */
import DesignSection from "../../app-design-section";
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { FormHook, LocalStorage } from "../../lib-atomic-functions";
import { FetchContextApi } from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleLink,
  ModuleText,
  ModuleImage
} from "../../lib-atomic-design-section";
/* packages */

/* module */
import ProductDetail from "../../app-module-product-detail/detail";
/* module */

/* stores */
import { OrderConfirm } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const CONFIRMATION = CONFIG.application.order.api.endpoints.confirmation;
const CONFIRMATIONOBJECTS = CONFIG.application.order.api.objects;
const CONFIRMATIONCONFIG = {
  url: CONFIRMATION.url,
  method: CONFIRMATION.method,
  headers: CONFIRMATION.headers,
  responseType: "json"
};
/* CONSTANTS */

const index = ({ props, order, product_card }) => {
  const { token } = LocalStorage.getLS().login;
  const tokenCorp = LocalStorage.getLS().loginCorp.token;
  const { products } = product_card;
  const orderId = order.id;
  const isCorporate = order.flCorporate === 1 ? true : false;
  const [confirm, setConfirm] = useState(false);
  const [openAutomate, setOpenAutomate] = useState(false);
  const [topupError, setTopupError] = useState(false);
  const [topupErrorMsg, setTopupErrorMsg] = useState("");

  /* states */
  CONFIRMATIONCONFIG.headers = {
    ...CONFIRMATIONCONFIG.headers,
    Authorization: isCorporate ? tokenCorp : token
  };
  const [orderConfirmContext, orderConfirmMethod] = FetchContextApi(
    CONFIRMATIONCONFIG,
    OrderConfirm
  );
  /* states */

  const handleGenProducts = products => {
    console.log(CONFIG.products.cards);
    const { red, hb, ty } = CONFIG.products.cards;
    const productsCards = products.filter(product => product.subClassId === 3);
    const productRed = productsCards.filter(
      product => product.productId === red
    );
    const productHb = productsCards.filter(product => product.productId === hb);
    const productTy = productsCards.filter(product => product.productId === ty);
    const isProductRed = productRed.length > 0 ? true : false;
    const isProductHb = productHb.length > 0 ? true : false;
    const isProductTy = productTy.length > 0 ? true : false;
    return [
      productsCards,
      productRed,
      productHb,
      productTy,
      isProductRed,
      isProductHb,
      isProductTy
    ];
  };
  const [
    productsCards,
    productRed,
    productHb,
    productTy,
    isProductRed,
    isProductHb,
    isProductTy
  ] = handleGenProducts(products);

  const total_cards = productsCards.reduce(
    (a, b) => a + (b["quantity"] || 0),
    0
  );
  const total_value = products
    .reduce((a, b) => a + (b["value"] * b["quantity"] || 0), 0)
    .toFixed(2);

  /* red, hb, ty */
  let NEWCONFIRMATIONOBJECTSRED = {};
  let NEWCONFIRMATIONOBJECTSHB = {};
  let NEWCONFIRMATIONOBJECTSTY = {};
  let NEWCONFIRMATIONOBJECTSRED_AUTO = {};
  let NEWCONFIRMATIONOBJECTSHB_AUTO = {};
  let NEWCONFIRMATIONOBJECTSTY_AUTO = {};
  productRed.map((product, index) => {
    let key = "starting_red_" + index;
    NEWCONFIRMATIONOBJECTSRED[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "",
      helpText: false,
      rules: false
    };
  });
  productHb.map((product, index) => {
    let key = "starting_hb_" + index;
    NEWCONFIRMATIONOBJECTSHB[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "",
      helpText: false,
      rules: false
    };
  });
  productTy.map((product, index) => {
    let key = "starting_ty_" + index;
    NEWCONFIRMATIONOBJECTSTY[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "",
      helpText: false,
      rules: false
    };
  });

  productRed.map((product, index) => {
    let key = "auto_starting_red";
    NEWCONFIRMATIONOBJECTSRED_AUTO[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "Starting # (Default GC)",
      helpText: false,
      rules: ["isNotNull"]
    };
  });
  productHb.map((product, index) => {
    let key = "auto_starting_hb";
    NEWCONFIRMATIONOBJECTSHB_AUTO[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "Starting # (Happy Birthday GC)",
      helpText: false,
      rules: ["isNotNull"]
    };
  });
  productTy.map((product, index) => {
    let key = "auto_starting_ty";
    NEWCONFIRMATIONOBJECTSTY_AUTO[key] = {
      name: key,
      initial: "",
      type: "text",
      label: "Starting # (Thank you GC)",
      helpText: false,
      rules: ["isNotNull"]
    };
  });

  console.log(productTy, "TEST...");

  NEWCONFIRMATIONOBJECTSRED = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSRED
  };
  NEWCONFIRMATIONOBJECTSHB = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSHB
  };
  NEWCONFIRMATIONOBJECTSTY = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSTY
  };
  NEWCONFIRMATIONOBJECTSRED_AUTO = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSRED_AUTO
  };
  NEWCONFIRMATIONOBJECTSHB_AUTO = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSHB_AUTO
  };
  NEWCONFIRMATIONOBJECTSTY_AUTO = {
    ...CONFIRMATIONOBJECTS,
    ...NEWCONFIRMATIONOBJECTSTY_AUTO
  };
  const NEWCONFIRMATIONOBJECTS = {
    ...NEWCONFIRMATIONOBJECTSRED,
    ...NEWCONFIRMATIONOBJECTSHB,
    ...NEWCONFIRMATIONOBJECTSTY,
    ...NEWCONFIRMATIONOBJECTSRED_AUTO,
    ...NEWCONFIRMATIONOBJECTSHB_AUTO,
    ...NEWCONFIRMATIONOBJECTSTY_AUTO
  };
  /* red, hb, ty */

  const handleAutomateLoop = (products, type, startnumber) => {
    let start = startnumber;
    return products.map((product, index) => {
      const key = "starting_" + type + "_" + index;
      const { quantity } = product;
      let mynum = Number(start);
      start = start.substring(0, 15);
      start = Number(start) + Number(quantity);
      start = LuhnJS.generate(String(start));
      return {
        productId: product.productId,
        value: product.value,
        quantity: product.quantity,
        startCardNumber: mynum
      };
    });
  };

  const handleAutomate = () => {
    const [
      productsCards,
      productRed,
      productHb,
      productTy,
      isProductRed,
      isProductHb,
      isProductTy
    ] = handleGenProducts(products);
    let red = false;
    let hb = false;
    let ty = false;
    let all = [];
    if (isProductRed) {
      red = values["auto_starting_red"];
      const red_result = handleAutomateLoop(productRed, "red", red);
      all = [...all, ...red_result];
    }
    if (productHb) {
      hb = values["auto_starting_hb"];
      const hb_result = handleAutomateLoop(productHb, "hb", hb);
      all = [...all, ...hb_result];
    }
    if (productTy) {
      ty = values["auto_starting_ty"];
      const ty_result = handleAutomateLoop(productTy, "ty", ty);
      all = [...all, ...ty_result];
    }

    return all;
  };

  const handleConfirmation = async () => {
    const productAutomate = handleAutomate();
    const bodyData = {
      paymentTypeId: values.payment,
      extOrderNumber: values.reference,
      comments: values.note,
      products: [...productAutomate]
    };

    await orderConfirmMethod({
      query: orderId,
      body: JSON.stringify(bodyData)
    });
    if (orderConfirmContext.status) {
      props.history.push("/orders/l/success-quote-confirm/" + orderId);
    } else {
      setTopupError(true);
      setTopupErrorMsg(
        orderConfirmContext.data.confirm.outcomeDeveloperMessage
      );
    }
  };

  const handleCancel = async () => {
    props.history.push("/orders/detail/" + orderId);
  };

  const handleConfirm = () => {
    setConfirm(!confirm);
  };

  const handleOpenAutomate = () => {
    setOpenAutomate(!openAutomate);
  };

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleConfirmation,
    NEWCONFIRMATIONOBJECTS
  );

  const starting_red_0 = {
    ...NEWCONFIRMATIONOBJECTS.starting_red_0,
    error: error.starting_red_0,
    value: values.starting_red_0,
    onChange
  };
  const starting_red_1 = {
    ...NEWCONFIRMATIONOBJECTS.starting_red_1,
    error: error.starting_red_1,
    value: values.starting_red_1,
    onChange
  };
  const starting_red_2 = {
    ...NEWCONFIRMATIONOBJECTS.starting_red_2,
    error: error.starting_red_2,
    value: values.starting_red_2,
    onChange
  };
  const starting_red_3 = {
    ...NEWCONFIRMATIONOBJECTS.starting_red_3,
    error: error.starting_red_3,
    value: values.starting_red_3,
    onChange
  };
  const starting_red_4 = {
    ...NEWCONFIRMATIONOBJECTS.starting_red_4,
    error: error.starting_red_4,
    value: values.starting_red_4,
    onChange
  };

  const starting_hb_0 = {
    ...NEWCONFIRMATIONOBJECTS.starting_hb_0,
    error: error.starting_hb_0,
    value: values.starting_hb_0,
    onChange
  };
  const starting_hb_1 = {
    ...NEWCONFIRMATIONOBJECTS.starting_hb_1,
    error: error.starting_hb_1,
    value: values.starting_hb_1,
    onChange
  };
  const starting_hb_2 = {
    ...NEWCONFIRMATIONOBJECTS.starting_hb_2,
    error: error.starting_hb_2,
    value: values.starting_hb_2,
    onChange
  };
  const starting_hb_3 = {
    ...NEWCONFIRMATIONOBJECTS.starting_hb_3,
    error: error.starting_hb_3,
    value: values.starting_hb_3,
    onChange
  };
  const starting_hb_4 = {
    ...NEWCONFIRMATIONOBJECTS.starting_hb_4,
    error: error.starting_hb_4,
    value: values.starting_hb_4,
    onChange
  };

  const starting_ty_0 = {
    ...NEWCONFIRMATIONOBJECTS.starting_ty_0,
    error: error.starting_ty_0,
    value: values.starting_ty_0,
    onChange
  };
  const starting_ty_1 = {
    ...NEWCONFIRMATIONOBJECTS.starting_ty_1,
    error: error.starting_ty_1,
    value: values.starting_ty_1,
    onChange
  };
  const starting_ty_2 = {
    ...NEWCONFIRMATIONOBJECTS.starting_ty_2,
    error: error.starting_ty_2,
    value: values.starting_ty_2,
    onChange
  };
  const starting_ty_3 = {
    ...NEWCONFIRMATIONOBJECTS.starting_ty_3,
    error: error.starting_ty_3,
    value: values.starting_ty_3,
    onChange
  };
  const starting_ty_4 = {
    ...NEWCONFIRMATIONOBJECTS.starting_ty_4,
    error: error.starting_ty_4,
    value: values.starting_ty_4,
    onChange
  };

  const auto_starting_red = {
    ...NEWCONFIRMATIONOBJECTS.auto_starting_red,
    error: error.auto_starting_red,
    value: values.auto_starting_red,
    onChange
  };
  const auto_starting_hb = {
    ...NEWCONFIRMATIONOBJECTS.auto_starting_hb,
    error: error.auto_starting_hb,
    value: values.auto_starting_hb,
    onChange
  };
  const auto_starting_ty = {
    ...NEWCONFIRMATIONOBJECTS.auto_starting_ty,
    error: error.auto_starting_ty,
    value: values.auto_starting_ty,
    onChange
  };

  const reference = {
    ...NEWCONFIRMATIONOBJECTS.reference,
    error: error.reference,
    value: values.reference,
    onChange
  };
  const payment = {
    ...NEWCONFIRMATIONOBJECTS.payment,
    error: error.payment,
    value: values.payment,
    onChange
  };
  //const delivery = { ...NEWCONFIRMATIONOBJECTS.delivery, error: error.delivery, value: values.delivery, onChange };
  const note = {
    ...NEWCONFIRMATIONOBJECTS.note,
    error: error.note,
    value: values.note,
    onChange
  };

  const automate = {
    label: <small>I want to automate the starting card numbers.</small>,
    onChange: handleOpenAutomate
  };

  const auto_startingListLoop = {
    auto_starting_red,
    auto_starting_hb,
    auto_starting_ty
  };

  const startingListLoop = {
    starting_red: {
      starting_red_0,
      starting_red_1,
      starting_red_2,
      starting_red_3,
      starting_red_4
    },
    starting_hb: {
      starting_hb_0,
      starting_hb_1,
      starting_hb_2,
      starting_hb_3,
      starting_hb_4
    },
    starting_ty: {
      starting_ty_0,
      starting_ty_1,
      starting_ty_2,
      starting_ty_3,
      starting_ty_4
    }
  };

  const auto_startingLoop = (products, type) => {
    return products.map((product, index) => {
      if (index === 0) {
        const productData = { productId: product.productId };
        const indexInput = "auto_starting_" + type;
        return (
          <section
            key={index}
            className="space-standard-top space-standard-bottom"
          >
            <ProductDetail {...productData} />
            <Inputs.Text {...auto_startingListLoop[indexInput]} />
          </section>
        );
      }
    });
  };

  const startingLoop = (products, type) => {
    return products.map((product, index) => {
      const productData = { productId: product.productId };
      const startingType = "starting_" + type;
      const indexInput = "starting_" + type + "_" + index;
      return <Inputs.Hidden {...startingListLoop[startingType][indexInput]} />;
    });
  };

  /* data props */
  const submitButton = { size: "small" };
  const cancelButton = { size: "small", onClick: handleCancel };
  const startingInputRed = startingLoop(productRed, "red");
  const startingInputHb = startingLoop(productHb, "hb");
  const startingInputTy = startingLoop(productTy, "ty");

  const auto_startingInputRed = auto_startingLoop(productRed, "red");
  const aut_startingInputHb = auto_startingLoop(productHb, "hb");
  const auto_startingInputTy = auto_startingLoop(productTy, "ty");

  const failTopup = { error: [topupErrorMsg] };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <img src={LANDINGGRAPHICS} width="100%" />
      <ModuleTitle>Almost there!</ModuleTitle>
      <ModuleText>
        You are about to confirm order number <b>{orderId}</b>.
      </ModuleText>
      <br />
      <Inputs.Text {...reference} />
      <Inputs.Select {...payment} />
      <Inputs.Textarea {...note} />
      <hr />
      <ModuleText>
        <small>
          <i>* Please specify starting card number on each card.</i>
        </small>
      </ModuleText>
      {topupError && (
        <Fragment>
          <Invalid {...failTopup} />
          <br />
        </Fragment>
      )}
      <hr />
      {auto_startingInputRed}
      {aut_startingInputHb}
      {auto_startingInputTy}
      <hr />
      {startingInputRed} {startingInputHb} {startingInputTy}
      {!confirm && (
        <section className="space-standard-top space-standard-bottom">
          <Buttons.Primary onClick={handleConfirm}>
            Confirm and submit
          </Buttons.Primary>
        </section>
      )}
      {confirm && (
        <section className="space-standard-top space-standard-bottom">
          <p>
            <b>
              You are about to top up {total_cards} cards totalling $
              {total_value} do you wish to continue
            </b>
          </p>
          <Buttons.Normal {...submitButton}>
            Continue and Confirm
          </Buttons.Normal>
          <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
        </section>
      )}
    </form>
  );
};

export default index;
