import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";

import styles from './styles';

import CONFIG from '../../../config';
const { logo, logo_pdf } = CONFIG.setup.assets;
const currentTime = new Date();
const year = currentTime.getFullYear();

const footer = props => {
  return <Fragment>
    <View style={styles.container}>
      <View style={styles.line}></View>
    </View>

    <View style={[styles.container, styles.otherWrapper]}>
      <View style={styles.otherContainer}>
        <View style={styles.columnDirection}>
          <Text>If you are paying by direct credit pelase include the quote number with your payment and direct credit funds into</Text>
          <Text style={styles.footerContentBold}>Westfield Gift Card 03-0104-0948859-10</Text>
          <Text>Tax invoice will be included with your Gift Card delivery.</Text>
          <Text>This quotation is valid fo 30 days from the date stated above.</Text>
        </View>
      </View>
    </View>

    <View style={[styles.container, styles.footerWrapper]}>
      <View style={styles.footerContainer}>
        <View style={[styles.footerMain]}>

          <View style={[styles.footerContentContainer]}>
            <View style={styles.footerContentLeft}>
              <Text style={styles.footerContentText}>GIFT CARDS</Text>
              <Text style={styles.footerContentText}>Call 0800 937 834 - 9am - 5pm UTC, Business days</Text>
              <Text style={styles.footerContentText}>Email westfieldgiftcards@scentregroup.com</Text>
            </View>
            <View style={styles.footerContentRight}>
              <View style={styles.textContainer}>
                <Image style={styles.logoFooter} src={logo_pdf} />
              </View>
            </View>
          </View>

          <View style={[styles.footerContentContainer]}>
            <View style={styles.footerContentFull}>
              <Text style={styles.footerContentLinks}>
                <Link src="https://giftcards.westfield.co.nz/page/contact">Contact Us </Link>
                <Text>  </Text>
                <Link src="https://www.westfield.co.nz/privacy-policy">Privacy Policy </Link>
                <Text>  </Text>
                <Link src="https://www.westfield.co.nz/terms-and-conditions">Product Terms & Condition</Link>
                <Text>  </Text>
                <Link src="http://www.scentregroup.com/">@ {year} Scentre Group</Link>
              </Text>
            </View>
          </View>

        </View>
      </View>
    </View>
  </Fragment>
}

export default footer;
