import React from 'react';
import Button from '@material-ui/core/Button';

const PrimarySmall = props => {
  const { children, onClick } = props;
  const handles = { onClick }
  return <Button variant="contained" color="primary" size="small" {...handles} >
    {children}
  </Button>
}

export default PrimarySmall;
