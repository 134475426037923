import React from "react";

import { Row } from 'reactstrap';

import Imports from './imports';

const Index = (props) => {
  return <Row>
    <Imports />
  </Row>
}

export default Index;
