import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import Store from './store';
/* stores */

const index = ({CONFIG}) => {
  const contactListContext  = useContext(Store);
  const contactListMethod = async ({token, companyId}) => {
    contactListContext.setLoading({loading: true, status: true, data: false});
    CONFIG.application.contact.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.contact.api.endpoints.list.url + companyId + '/contacts',
      config: {
        method: CONFIG.application.contact.api.endpoints.list.method,
        headers: CONFIG.application.contact.api.headers
      }
    });
    contactListContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { contactListContext, contactListMethod }
}

export default index;
