import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* mui : styles */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 10,
    marginBottom: 10,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));
/* mui : styles */

const index = ({id, name, contact_person, contact_person_email, contact_person_mobile}) => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
        <Paper className={classes.paper}>
          <Typography variant="body2">
            <b>Customer Information</b>
          </Typography>
          <Typography variant="body2">
            { id }
          </Typography>
          <Typography variant="body2">
            { name }
          </Typography>
          <Typography variant="body2">
            { contact_person }
          </Typography>
          <Typography variant="body2">
            { contact_person_email }
          </Typography>
          <Typography variant="body2">
            { contact_person_mobile }
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  </div>
}

export default index;
