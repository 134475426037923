import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONSTANTS */

const index = props => {

  const { orderId } = props.match.params;

  return <ModuleContainerSm>
    <ModulePaperWrapper>
    <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
    <ModuleTitle>Great!</ModuleTitle>
    <ModuleText>You have successfully send an activation email for order number <b>{orderId}</b>.</ModuleText>
    <ModuleText>Please click the link below to see details.</ModuleText>
    <br/>
    <ModuleLink><Link to={"/orders/detail/" + orderId}>Order details</Link></ModuleLink>
    </ModulePaperWrapper>
  </ModuleContainerSm>

}

export default index;
