import React, { useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
/* mui */

/* packages */
import { ModuleWrapper, ModuleWrapperMain, ModuleWrapperLeft, ModuleWrapperRight, ModuleWrapperFull, ModuleWrapperForm } from '../../util-atomic-design';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* module */
import Loader from '../loader/steps';
import OrderNav from './nav';
import OrderTitle from './title';
import OrderSubTitle from './subtitle';
import OrderSubTitle2 from './subtitle2';
import OrderSubTitle3 from './subtitle3';
import OrderStepper from './stepper';
/* module */

/* sections */
import CompanyInfoFetcher from '../../app-module-company-info-section-fetcher/default';
import ContactInfoFetcher from '../../app-module-contact-info-section-fetcher/default';
import OrderSectionItems from '../../app-module-order-section-product/default';
import CompanyDetailSectionInfo from '../../app-module-company-detail-section-info/default';
/* sections */

/* functions */
import { OrderingDetail } from '../../app-module-ordering-container/functions';
/* functions */

import CONFIG from '../config';

/* CONSTANTS */
const steps = ['Company', 'Contact', 'Products', 'Fees', 'Others'];
/* CONSTANTS */

const index = observer((props) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;
  if (!login) return <Redirect to='/u/logout' />

  const { orderId } = props.match.params;

  /* states */
  const { orderDetailContext, orderDetailMethod } = OrderingDetail({CONFIG});

  useEffect(() => {
    orderDetailMethod({token, orderId});
  }, [orderId]);

  useEffect(() => {
    if (orderDetailContext.data) handleSetInitialCollection(orderDetailContext.data);
  }, [orderDetailContext.data]);

  useEffect(() => {
    if (orderDetailContext.expireToken) props.history.push('/u/landing-token-expire');
  }, [orderDetailContext.expireToken]);
  /* states */

  /* states */
  const [collection, setCollection] = useState({
    status: false,
    company: false,
    contact: false,
    po: false,
    products: [],
    fees: [],
    note: false
  })
  /* states */

  const handleSetInitialCollection = (data) => {
    const products = data.detail.filter((product) => product.subClassId === 2 || product.subClassId === 3)
    const fees = data.detail.filter((product) => product.subClassId === 1 || product.subClassId === 4)
    collection.status = true;
    collection.company = {id : data.clientId, data};
    collection.contact = {id: data.contactId};
    collection.products = products;
    collection.fees = fees;
    collection.po = data.purchaseOrder;
    collection.note = data.notes;
    setCollection({...collection});
  }

  const handleRemoveProduct = (selectedIndex) => {
    collection.products = collection.products.filter((product, index) => index !== selectedIndex);
    setCollection({...collection});
  }

  const handleRemoveFees = (selectedIndex) => {
    collection.fees = collection.fees.filter((product, index) => index !== selectedIndex);
    setCollection({...collection});
  }

  /* data props */
  const navData = { orderId }
  const summaryData = {
    companyId: collection.company ? collection.company.id : false,
    contactId: collection.contact ? collection.contact.id : false,
    products: collection.products.length > 0 ? collection.products : false,
    fees: collection.fees.length > 0 ? collection.fees : false
  }
  const stepperData = { collection, setCollection, props }
  const companyInfoData = { ...collection.company.data }
  const companyData = { companyId: collection.company ? collection.company.id : false }
  const contactData = { companyId: collection.company ? collection.company.id : false, contactId: collection.contact ? collection.contact.id : false }
  const productsData = { products: collection.products.length > 0 ? collection.products : false, handleRemove: handleRemoveProduct }
  const feesData = { products: collection.fees.length > 0 ? collection.fees : false, handleRemove: handleRemoveFees }
  /* data props */

  return <ModuleWrapperMain>
    <ModuleWrapperFull>
      <OrderNav {...navData} />
    </ModuleWrapperFull>
    <ModuleWrapperLeft>
      <OrderTitle {...props} />
      <OrderStepper {...stepperData} />
    </ModuleWrapperLeft>
    <ModuleWrapperRight>
      <OrderSubTitle {...props} />
      { companyData.companyId && <CompanyInfoFetcher {...companyData} /> }
      { contactData.contactId && <ContactInfoFetcher {...{...contactData}} /> }
      <OrderSectionItems {...productsData} />
      <OrderSectionItems {...feesData} />
      {/*
      { companyData.companyId && <CompanyDetail {...companyData} /> }
      { contactData.contactId && <ContactDetail {...contactData} /> }
      <OrderItemsList {...productsData} />
      <OrderItemsList {...feesData} />
      */}
    </ModuleWrapperRight>
  </ModuleWrapperMain>

});

export default index;
