import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* packages */
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */
import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
import { AuthDetail } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.account.api.endpoints;
const DETAILOBJECTSCONFIG = {
  url: ENDPOINTS.detail.url,
  method: ENDPOINTS.detail.method,
  headers: ENDPOINTS.detail.headers,
  responseType: "json",
};

const index = observer((props) => {
  const { token, userId } = LocalStorage.getLS().login;

  DETAILOBJECTSCONFIG.headers = {
    ...DETAILOBJECTSCONFIG.headers,
    Authorization: token,
  };
  const [accountDetailContext, accountDetailMethod] = FetchContextApi(
    DETAILOBJECTSCONFIG,
    AuthDetail
  );

  useEffect(() => {
    accountDetailMethod({}); // {token}
  }, [token]);
  if (accountDetailContext.data.invalidToken) return false;
  if (userId == undefined) {
    window.alert("Wrong username or password!")
    return <Redirect to="/u/logout" />;
  }

  // hardcode privileged users
  const privilegedIds = ["33813398", "32262889"];
  const privilegedIds2 = ["20328498"];
  if (privilegedIds.includes(userId.toString())) {
    accountDetailContext.data.json.flHeadOffice = 2;
  }
  if (privilegedIds2.includes(userId.toString())) {
    accountDetailContext.data.json.flHeadOffice = 3;
  }
  LocalStorage.addLS("info", accountDetailContext.data.json);

  const { login, info } = LocalStorage.getLS();
  const { flHeadOffice } = info;
  if (login) {
    if (flHeadOffice === 0) return <Redirect to="/orders" />;
    if (flHeadOffice === 1) return <Redirect to="/orders" />;
    if (flHeadOffice === 2) return <Redirect to="/reporting" />;
    if (flHeadOffice === 3) return <Redirect to="/reporting" />;
  }

  return null;
});

export default index;
