import React from 'react';

/* packages */
import { ModuleTitle } from '../../lib-atomic-design-section';
/* packages */

const action = props => {
  return <ModuleTitle>
    Order Search Results
  </ModuleTitle>
}

export default action;
