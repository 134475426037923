import React from 'react'
import { Link } from 'react-router-dom';

import DesignSection from '../util-mui-design-section';

import CONFIG from './config';

const Links = props => {
  const { label, prefix } = CONFIG.routes;
  return <DesignSection.ModuleLink>
    <Link to={prefix}>{ label }</Link>
  </DesignSection.ModuleLink>
}

export default Links;
