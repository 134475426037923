/* packages */
import atomicComponents from './packages';
const {
  ApiServices
} = atomicComponents;
/* packages */

const env = process.env.NODE_ENV;
const project = "app-thq-password-reset";
const server = null;
const service = "password_reset";
const config = {
  details: {
    name: project,
    server: server,
    environment: env,
    service: service,
  },
  routes: {
    label: "Password Reset",
    prefix: "/password-reset"
  }
}

export default config;
