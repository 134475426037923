import React, { useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* stores */
import { Report21 } from '../../app-module-reporting-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const REPORT21 = CONFIG.application.report.api.endpoints.report21;
const REPORT21OBJECTS = CONFIG.application.report.api.objects;
const REPORT21CONFIG = {
  url: REPORT21.url,
  method: REPORT21.method,
  headers: REPORT21.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = observer((props) => {

  const { token } = LocalStorage.getLS().login;

  /* state */
  REPORT21CONFIG.headers = { ...REPORT21CONFIG.headers, Authorization: token }
  const [ report21Context, report21Method ] = FetchContextApi(REPORT21CONFIG, Report21);
  /* state */

  const handleDownload = async () => {
    const filename = new Date().toLocaleString();
    const query = Object.keys(values).map(key => key + '=' + values[key]).join('&');
    await report21Method({query});
    // console.log(report21Context)
    // no report data
    if (!report21Context.status && !report21Context.data) {
      props.history.push("/reporting/l/no-available-data");
    } else {
      props.history.push({ pathname: "/reporting/l/download-pdf/21", ...values });
    }
  }

  const handleCancel = async () => {
    props.history.push("/reporting");
  }

  const {
    values,
    onChange,
    onPick,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleDownload, REPORT21OBJECTS);

  /* data props */
  const formatDate = { formatDisplay: "dd/MM/yyyy", formatProcess: "YYYY-MM-DD" }
  const startDate = { ...REPORT21OBJECTS.startDate, ...formatDate, error: error.startDate, value: values.startDate, onPick };
  const endDate = { ...REPORT21OBJECTS.endDate, ...formatDate, error: error.endDate, value: values.endDate, onPick };
  const submitButton = { size: "small" }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <form onSubmit={onSubmit} noValidate>
    {
      /*
      accountLoginContext.data.invalidToken &&
        <Fragment>
        <Invalid {...failLogin} />
        <br/>
        </Fragment>
      */
    }
    <Inputs.Date {...startDate} />
    <Inputs.Date {...endDate} />
    <br/>
    <br/>
    <Buttons.Normal {...submitButton}>Search</Buttons.Normal>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
  </form>

});

export default index;
