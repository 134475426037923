import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Token Expire";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    demo: {
      api: {
        headers: null,
        endpoints: null
      },
      objects: null
    }
  },
  routes: {
    label: "Token Expire",
    prefix: "/u/landing-idle-user"
  },
  setup
}

export default config;
