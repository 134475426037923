import React, { useContext } from "react";

/* packages */
import Fetch from "../../../util-fetch";
/* packages */

/* stores */
import ListStore from "./store";
/* stores */

const Index = ({ CONFIG, token }) => {
  const productCat5ListContext = useContext(ListStore);
  const productCat5ListMethod = async () => {
    productCat5ListContext.setLoading(true);
    CONFIG.application.product.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.product.api.endpoints.list_Cat5.url,
      config: {
        method: CONFIG.application.product.api.endpoints.list_Cat5.method,
        headers: CONFIG.application.product.api.headers,
      },
    });
    productCat5ListContext.setStatus(fetch[0]);
    productCat5ListContext.setData(fetch[1]);
    productCat5ListContext.setLoading(false);
  };
  const productCat5ListData = productCat5ListContext.isData();

  return { productCat5ListContext, productCat5ListMethod, productCat5ListData };
};

export default Index;
