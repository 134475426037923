import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* design */
import DesignSection from '../../app-design-section';
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

/* stores */
import { CompanyDecline } from '../../app-module-company-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const DECLINE = CONFIG.application.company.api.endpoints.decline;
const DECLINECONFIG = {
  url: DECLINE.url,
  method: DECLINE.method,
  headers: DECLINE.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = (props) => {

  const { token } = LocalStorage.getLS().login;
  const companyId = props.companyId
  
  /* states */
  DECLINECONFIG.headers = { ...DECLINECONFIG.headers, Authorization: token }
  const [ companyDeclineStatus, companyDeclineMethod ] = FetchContextApi(DECLINECONFIG, CompanyDecline);
  /* states */

  const handleDecline = async () => {
    await companyDeclineMethod({query: companyId + '/decline'});
    if (companyDeclineStatus.status) {
      props.history.push("/company/l/success-company-decline/" + companyId);
    } else {
      //landing-fail page
    }
  }

  const handleCancel = async () => {
    props.history.push("/company/detail/" + companyId);
  }

  /* data props */
  const submitButton = { size: "small", onClick: handleDecline }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <Fragment>

    <img src={LANDINGGRAPHICS} width="100%"/>
    <ModuleTitle>Almost there!</ModuleTitle>
    <ModuleText>You are about to decilne company ID <b>{companyId}</b>. Would you like to proceed?</ModuleText>
    <br/>
    <br/>
    <Buttons.Primary {...submitButton}>Proceed and Decline</Buttons.Primary>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>

  </Fragment>

}

export default index;
