import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Create";
const server = null;
const service = null;
const initDate = new Date();
const initDateFormat = initDate.toISOString().split('T')[0]; // yyyy-mm-dd
const options = {
  status: [
    {name: "QUOTED", value: "1"},
    {name: "ONHOLD", value: "2"},
    {name: "APPROVED", value: "8"},
    {name: "DECLINED", value: "3"},
    {name: "PAYMENT CONFIRMED", value: "6"},
    {name: "PENDING ACTIVATION", value: "7"},
    {name: "ACTIVATED", value: "5"}
  ]
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          list: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders",
            method: 'GET'
          }
        }
      },
      objects: {
        clientName: { name: "clientName", initial: "", label: "Company", helperText: false,  rules: false },
        orderId: { name: "orderId", initial: "", label: "Order Number", helperText: false, rules: ["isNumber"] },
        startDate: { name: "startDate", initial: "", label: "Date From", helperText: false, rules: false },
        endDate: { name: "endDate", initial: "", label: "Date To", helperText: false, rules: false },
        statusId: { name: "statusId", initial: "", label: "Status", options: options.status, helperText: false,  rules: false },
      },
    }
  },
  routes: {
    label: "Search",
    prefix: "/orders/search"
  },
  setup
}

export default config;
