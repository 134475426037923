import React from 'react';
import { Link } from 'react-router-dom';

/* container */
import ReportingContainer from '../../app-module-reporting-container/default';
/* container */

/* packages */
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

import CONFIG from '../config';

/* CONTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONTANTS */

const index = props => {

  return <ReportingContainer>
  <ModuleContainerSm>
    <ModulePaperWrapper>
    <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
    <ModuleTitle>Sorry!</ModuleTitle>
    <ModuleText>You have no available results for your report.</ModuleText>
    <br/>
    <ModuleLink><Link to="/reporting">Home</Link></ModuleLink>
    </ModulePaperWrapper>
  </ModuleContainerSm>
  </ReportingContainer>
  
}

export default index;
