import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";

import styles from './styles';

import CONFIG from '../../../config';
const { logo, logo_pdf } = CONFIG.setup.assets;
const currentTime = new Date();
const year = currentTime.getFullYear();

const footer = props => {
  return <Fragment>
    <View style={styles.container}>
      <View style={styles.line}></View>
    </View>

    <View style={[styles.container, styles.otherWrapper]}>
      <View style={styles.otherContainer}>
        <View style={styles.columnDirection}>
          <Text>Thank you for choosing to purchase Westfield Gift Cards.</Text>
          <Text>If you have any questions please refer to the Westfield Gift Card Purchase Terms and Conditions, </Text>
          <Text>www.westfield.co.nz or alternatively contact us at westfieldnzgiftcards@scentregroup.com or 0800 937 834</Text>
          <Text>Westfield Gift Cards expire 12 months from the date purchase.</Text>
          <Text>This receipt should be retained as your proof of purchase.</Text>
        </View>
      </View>
    </View>

    <View style={[styles.container, styles.footerWrapper]}>
      <View style={styles.footerContainer}>
        <View style={[styles.footerMain]}>

          <View style={[styles.footerContentContainer]}>
            <View style={styles.footerContentLeft}>
              <Text style={styles.footerContentText}>GIFT CARDS</Text>
              <Text style={styles.footerContentText}>Call 0800 937 834 - 9am - 5pm UTC Business days</Text>
              <Text style={styles.footerContentText}>Email westfieldgiftcards@scentregroup.com</Text>
            </View>
            <View style={styles.footerContentRight}>
              <View style={styles.textContainer}>
                <Image style={styles.logoFooter} src={logo_pdf} />
              </View>
            </View>
          </View>

          <View style={[styles.footerContentContainer]}>
            <View style={styles.footerContentFull}>
              <Text style={styles.footerContentLinks}>
                <Link src="https://giftcards.westfield.co.nz/page/contact">Contact Us </Link>
                <Text>  </Text>
                <Link src="https://www.westfield.co.nz/privacy-policy">Privacy Policy </Link>
                <Text>  </Text>
                <Link src="https://www.westfield.co.nz/terms-and-conditions">Product Terms & Condition</Link>
                <Text>  </Text>
                <Link src="http://www.scentregroup.com/">@ {year} Scentre Group</Link>
              </Text>
            </View>
          </View>

        </View>
      </View>
    </View>

  </Fragment>
}

export default footer;
