import React from "react"

import './style.css';

const xlarge = props => {
  return <div className="skeleton-container">
    <div className="line xl h60"></div>
  </div>
}

export default xlarge;
