import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

import { Invalid } from '../feedbacks';

const Textarea = ({name, type, label, helpText, errors, value, onChange}) => {
  const data = { errors };
  return <Fragment>
    <TextField
      fullWidth
      multiline
      error={errors ? true : false}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      helperText={helpText}
      variant="outlined"
      margin="dense"
    />
  <Invalid {...data} />
  </Fragment>
}

export default Textarea;
