import React from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleLinkWrapper, ModuleLinkItem } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

import CONFIG from '../config';

/* CONTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONTANTS */

const index = props => {

  return <ModuleContainerSm>
    <ModulePaperWrapper>
    <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
    <ModuleTitle>Company</ModuleTitle>
    <ModuleText>Company module for creating/updating company/clients records and contacts.</ModuleText>

    <br/>

    <ModuleLinkWrapper>
      <ModuleLinkItem>
        <Link to="/company/list">View all company</Link>
      </ModuleLinkItem>
      <ModuleLinkItem>
        <Link to="/company/search?name=%22%22">Search a company</Link>
      </ModuleLinkItem>
      <ModuleLinkItem>
        <Link to="/company/create">Create new company</Link>
      </ModuleLinkItem>
    </ModuleLinkWrapper>

    </ModulePaperWrapper>
  </ModuleContainerSm>

}

export default index;
