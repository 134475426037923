import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import './style.css';
import 'typeface-roboto';
import 'bootstrap/dist/css/bootstrap.min.css';

import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const primary = blue[500]; // #F44336

// All the following keys are optional.
// We try our best to provide a great default value.
const textColor = "#616161";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: pink[300],
      main: pink[500],
      dark: pink[700],
      contrastText: '#fff',
    },
    error: {
      main: '#ef9a9a'
    },
    text: {
      primary: textColor,
      secondary: textColor
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        '& a': { textDecoration: "none" },
        '& a:hover': { textDecoration: "none" }
      }
    }
  },
  typography: {
    h5: {
      color: textColor,
    },
    subtitle2: {
      color: textColor,
    },
  },
});

const Theme = props => {
  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
}

export default Theme;
