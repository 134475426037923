import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ListStore from './store';
/* stores */

const Index = ({CONFIG, token}) => {
  const productCat3ListContext  = useContext(ListStore);
  const productCat3ListMethod = async () => {
    productCat3ListContext.setLoading(true);
    CONFIG.application.product.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.product.api.endpoints.list_Cat3.url,
      config: {
        method: CONFIG.application.product.api.endpoints.list_Cat3.method,
        headers: CONFIG.application.product.api.headers
      }
    });
    productCat3ListContext.setStatus(fetch[0]);
    productCat3ListContext.setData(fetch[1]);
    productCat3ListContext.setLoading(false);
  }
  const productCat3ListData = productCat3ListContext.isData();

  return { productCat3ListContext, productCat3ListMethod, productCat3ListData }
}

export default Index;
