import React, { useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
/* packages */

/* section */
import CompanySectionInfo from '../../app-module-company-detail-section-info/default';
/* section */

/* loader */
import Loader from '../loader/fetcher';
/* loader */

/* stores */
import { Fetcher } from '../../app-module-company-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const COMPANY = CONFIG.application.company.api.endpoints.detail;
const COMPANYCONFIG = {
  url: COMPANY.url,
  method: COMPANY.method,
  headers: COMPANY.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = observer(({companyId}) => {

  const { token } = LocalStorage.getLS().login;

  /* state */
  COMPANYCONFIG.headers = { ...COMPANYCONFIG.headers, Authorization: token }
  const [ companyContext, companyMethod ] = FetchContextApi(COMPANYCONFIG, Fetcher);

  useEffect(() => {
    if (companyId) companyMethod({query: companyId});
  }, [companyId]);
  /* state */

  if (companyContext.loading) return <Loader />
  if (!companyContext.data.json) return <Loader />

  /* data props */
  const { name, emailAddress, mobile, NZBN } = companyContext.data.json.clients[0];
  const companyData = {
    clientEmail: emailAddress,
    clientMobile: mobile,
    clientName: name,
    clientNzbn: NZBN
  };
  /* data props */

  return <CompanySectionInfo {...companyData} />

});

export default index;
