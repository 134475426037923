import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* packages */
import { Buttons } from '../../lib-atomic';
/* packages */

/* section */
import Search from '../../app-module-order-section-search/default';
/* section */

const action = props => {

  /* data props */
  const linkButton = { size: "small" }
  /* data props */

  return <Fragment>

    <br/>
    <br/>

    <Search {...props} />

  </Fragment>

}

export default action;
