import React from 'react';
import Button from '@material-ui/core/Button';

const PrimaryBlock = props => {
  const { children, onClick } = props;
  const handles = { onClick }
  return <Button fullWidth variant="contained" color="primary" {...handles} >
    {children}
  </Button>
}

export default PrimaryBlock;
