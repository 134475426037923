import React, { Fragment } from 'react';

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from './styles-index';

import CONFIG from '../../../config';

const body = ({reportData, startDate, endDate, runDate, runTime}) => {
  if (!reportData) return null;
  const { centerName } = reportData;
  return <Fragment>
    <View style={[styles.container, styles.titleWrapper]}>
      <View style={styles.titleContainer}>
        <View style={styles.columnDirection}>
          <Text style={styles.SectionTitle}>
            Daily Sales for { centerName } ({startDate} - {endDate}) Report Run { runDate } { runTime }
          </Text>
        </View>
      </View>
    </View>
  </Fragment>
}

export default body;
