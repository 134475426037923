import React, { Fragment } from "react";

/* module */
import Item from "./item";
/* module */

const index = ({ products, handleRemove }) => {
  if (!products) return null;
  return products.map((product, index) => {
    const itemData = { index, ...product, handleRemove };
    return <Item key={index} {...itemData} />;
  });
};

export default index;
