import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* container */
import ReportingContainer from '../../app-module-reporting-container/default';
/* container */

/* packages */
import { ModuleContainerSm } from '../../lib-atomic-design-section';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleLinkWrapper, ModuleLinkItem } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
import Privilege from '../../app-conf-privilege';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';


/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
/* CONSTANTS */

const index = props => {

  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableSubModules = rolesHandler(LocalStorage, rolesSubModules);
  return <ModuleContainerSm>
    <ModulePaperWrapper>
    <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
    <ModuleTitle>Reporting</ModuleTitle>
    <ModuleText>Reporting module for all available reports.</ModuleText>

    <br/>

    <ModuleLinkWrapper>
      { availableSubModules.reporting.map((moduleItem, i) => {
        if (moduleItem.Links) return <ModuleLinkItem key={i}>
          <Link to={moduleItem.Config.routes.prefix}>{moduleItem.Config.routes.label}</Link>
        </ModuleLinkItem>
      })}
    </ModuleLinkWrapper>

    </ModulePaperWrapper>
  </ModuleContainerSm>

}

export default index;
