import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Redirect";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          detail: {
            headers: {
              'Content-Type': 'application/json',
              'moduleCode': setup.setup.thor.moduleCode[environment]
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.westfield_v1.url[environment] + "/lookup/isHQ",
            method: 'GET'
          }
        }
      },
      objects: null,
      responses: null
    }
  },
  routes: {
    label: "Redirect",
    prefix: "/u/login-logout-redirect"
  },
  setup,
  environment
}

export default config;
