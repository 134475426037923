import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const image = props => {
  return <Typography align="center" gutterBottom>
    { props.children }
  </Typography>
}

export default image;
