import React from 'react';

const icon = props => {
  return <svg>
    <circle {...props} cx="256" cy="256" r="64"/>
    <circle {...props} cx="256" cy="448" r="64"/>
    <circle {...props} cx="256" cy="64" r="64"/>
  </svg>
}

export default icon;
