import React from 'react';

/* mui */
import Grid from '@material-ui/core/Grid';
/* mui */

const wrapper = props => {
  return <Grid container spacing={1}>
    { props.children }
  </Grid>
}

export default wrapper;
