import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */

/* sections */
import ActivationResponseInvalid from './invalid';
import ActivationResponseValid from './response';
import ActivationLoader from '../loader/activation';
/* sections */

/* functions */
import { OrderingActivation } from '../../app-module-ordering-container/functions';
/* functions */

import CONFIG from '../config';

const index = observer((props) => {

  const query = props.location.search;

  /* states */
  const { orderActivationContext, orderActivationMethod, orderActivationData } = OrderingActivation({CONFIG});
    
  useEffect(() => {
    orderActivationMethod(query);
  }, []);
  /* states */
  
  /* data props */
  const invalidData = { ...props, data: orderActivationContext.data }
  /* data props */
  
  return <Fragment>
    <br/>
    <br/>
    <ModulePaperWrapper>
      { orderActivationData && <ActivationResponseValid {...props} /> }
      { !orderActivationData && <ActivationResponseInvalid {...invalidData} /> }
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
