import React, { Fragment } from 'react';

/* mui */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperMd, ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleSmallTitle } from '../../lib-atomic-design-section';
/* packages */

const index = ({contactId, contactFirstName, contactEmail, contactMobile, contactComments, editLink}) => {

  return <ModuleWrapperMd>
    <ModulePaperWrapper>

      <ModuleSmallTitle>CONTACT</ModuleSmallTitle>
      <Typography variant="h6">
        <b>{ contactFirstName }</b>
      </Typography>
      <Typography variant="body2">
        { contactComments }
      </Typography>

      <ModuleSmallTitle>EMAIL AND CONTACT NUMBER</ModuleSmallTitle>
      <Typography variant="body2" noWrap>
        <b>{ contactEmail }</b>
      </Typography>
      <Typography variant="body2">
        <b>{ contactMobile }</b>
      </Typography>

      { editLink && editLink }

    </ModulePaperWrapper>
  </ModuleWrapperMd>

}

export default index;
