import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Create";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: null,
          moduleCode: "WGMB",
        },
        endpoints: {
          create: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/clients",
            url: setup.setup.api.westfield_v1.url[environment] + "/clients",
            method: "PUT",
          },
        },
      },
      objects: {
        name: {
          name: "name",
          initial: "",
          type: "text",
          label: "Company Name",
          helpText: false,
          rules: ["isNotNull", "isName"],
        },
        //emailAddress: { name: "emailAddress", initial: "", type: "text", label: "Email Address", helpText: false, rules: false },
        mobile: {
          name: "mobile",
          initial: "",
          type: "text",
          label: "Contact Number",
          helpText: false,
          rules: ["isNotNull", "isNumber"],
        },
        NZBN: {
          name: "NZBN",
          initial: "",
          type: "text",
          label: "NZ Business Number",
          helpText: false,
          rules: false,
          // rules: ['isNotNull', 'isNumber', 'isMinMax'], minmax_limit: 13, minmax_suffix: 'digit NZBN number', allow_null: false
        },
        physicalAddress1: {
          name: "physicalAddress1",
          initial: "",
          type: "text",
          label: "PO BOX Address",
          helpText: false,
          rules: false,
        },
        physicalAddress2: {
          name: "physicalAddress2",
          initial: "",
          type: "text",
          label: "Delivery Address",
          helpText: false,
          rules: false,
        },
      },
    },
  },
  routes: {
    label: "Create",
    prefix: "/company/create",
  },
  setup,
  environment,
};

export default config;
