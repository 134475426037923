import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Company Create";
const server = null;
const service = null;
const options = {
  status: [
    {name: "Quoted", value: "1"},
    {name: "Payment Confirmed", value: "6"},
    {name: "Pending Activation", value: "7"},
    {name: "Activated", value: "5"}
  ]
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    company: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          list: {
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders',
            url: setup.setup.api.westfield_v1.url[environment] + "/orders",
            method: 'GET'
          }
        }
      },
      objects: {
        name: { name: "name", initial: "", label: "Company", helperText: false,  rules: false },
      },
    }
  },
  routes: {
    label: "Search",
    prefix: "/orders/search"
  },
  setup
}

export default config;
