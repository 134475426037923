import React from "react";

/* mui */
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    padding: theme.spacing(0.3),
  },
  quoted: {
    backgroundColor: "#039be5",
    color: "#fafafa",
  },
  approved: {
    backgroundColor: "#fbc02d",
    color: "#fafafa",
  },
  declined: {
    backgroundColor: "#e53935",
    color: "#fafafa",
  },
  confirmed: {
    backgroundColor: "#43a047",
    color: "#fafafa",
  },
  corporateStripe: {
    backgroundColor: "#43a047",
    color: "#fafafa",
  },
  other: {
    backgroundColor: "#757575",
    color: "#fafafa",
  },
}));
/* CONSTANTS */

const wrapper = ({
  orderStatusName,
  paymentType,
  paymentTypeName,
  enrolmentTraderName,
}) => {
  /* mui */
  const classes = useStyles();
  /* mui */
  console.log(enrolmentTraderName, " enrolmentTraderName");
  let style = [classes.chip];
  switch (orderStatusName) {
    case "Quoted":
      style.push(classes.quoted);
      break;
    case "Approved":
      style.push(classes.approved);
      break;
    case "Declined":
      style.push(classes.declined);
      break;
    case "Payment Confirmed":
      style.push(classes.confirmed);
      break;
    default:
      switch (paymentTypeName) {
        case "Stripe":
          style.push(classes.corporateStripe);
          break;
        default:
          style.push(classes.other);
      }
  }
  // switch (paymentTypeName) {
  //   case "Stripe":
  //     style.push(classes.corporateStripe);
  //     break;
  // }

  if (
    enrolmentTraderName === "Westfield Corporate Online" &&
    orderStatusName === "ONHOLD"
  )
    return (
      <Chip
        size="small"
        label={orderStatusName + " / " + paymentTypeName}
        className={style}
      />
    );

  return <Chip size="small" label={orderStatusName} className={style} />;
};

export default wrapper;
