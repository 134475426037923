import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  loading = false;
  data = false;
  status = false;

  setLoading = (data) => {
    this.loading = data;
  }

  setData = (data) => {
    this.data = data;
  }

  setStatus = (status) => {
    this.status = status;
  }

}

const store = new Store();

decorate(store, {
  loading: observable,
  data: observable,
  status: observable
  /*
  todos: observable,
  remainingTodos: computed
  */
})

export default createContext(store);
