import React from 'react';

import { Skeletons } from '../../lib-atomic';
import { ModuleWrapperMd, ModuleWrapperMain, ModuleWrapperLeft, ModuleWrapperRight } from '../../lib-atomic-design-section';

const loader = props => {
  return <ModuleWrapperMd>

    <ModuleWrapperMain>

      <ModuleWrapperLeft>

        <Skeletons.Large />
        <Skeletons.Medium />

        <Skeletons.Large />
        <Skeletons.Medium />

        <Skeletons.Large />
        <Skeletons.Medium />

        <Skeletons.Large />
        <Skeletons.Medium />

        <Skeletons.Large />
        <Skeletons.Medium />

      </ModuleWrapperLeft>

      <ModuleWrapperRight>

        <Skeletons.Large />
        <Skeletons.Small />
        <Skeletons.Small />

      </ModuleWrapperRight>

    </ModuleWrapperMain>

  </ModuleWrapperMd>
}

export default loader;
