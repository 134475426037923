import React, { useContext, useEffect } from 'react';

import { StyleSheet, Font } from "@react-pdf/renderer";

// Register font
const source = 'https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNzbP97U9sKh0jjxbPbfOKg.ttf'
Font.register({ family: 'Cera', src: source });

export default StyleSheet.create({
  SectionTitle: {
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  TotalTitle: {
    fontSize: 11,
    fontWeight: "bold"
  },
  StartEndNumber: {
    fontSize: 8
  },
  ProductName: {
    fontSize: 8
  },
  page: {
    flex: 1,
    flexDirection: "column",
    fontSize: 10,
    fontWeight: "normal",
    paddingTop: 47,
    paddingBottom: 47,
    fontFamily: 'Cera'
  },
  container: {
    paddingLeft: 47,
    paddingRight: 47,
    flexDirection: "row",
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
    width: 250 ,
    backgroundColor: "yellow"
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
    width: 250,
    backgroundColor: "red"
  },
  logoHeader: {
    width: 150,
    height: 32
  },
  logoFooter: {
    width: 50,
    height: 16
  },
  headerWrapper: {
  },
  headerContainer: {
    flex: 2,
    textAlign: "left",
    //backgroundColor: "purple",
    paddingTop: 3,
    paddingBottom: 3,
  },
  titleWrapper: {
  },
  titleContainer: {
    flex: 1,
    textAlign: "left",
    //backgroundColor: "green",
    paddingTop: 3,
    paddingBottom: 3,
  },
  companyWrapper: {
  },
  companyContainer: {
    flex: 2,
    textAlign: "left",
    //backgroundColor: "yellow",
    paddingTop: 3,
    paddingBottom: 3,
  },
  itemsWrapper: {
  },
  itemContainer: {
    flex: 6,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 3,
    paddingBottom: 3,
  },
  itemContainer2: {
    flex: 5,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 3,
    paddingBottom: 3,
  },
  itemContainer3: {
    flex: 8,
    textAlign: "left",
    // backgroundColor: "blue",
    paddingTop: 3,
    paddingBottom: 3,
  },
  totalWrapper: {
  },
  totalContainer: {
    flex: 2,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 3,
    paddingBottom: 3,
  },
  otherWrapper: {
  },
  otherContainer: {
    flex: 1,
    textAlign: "left",
    // backgroundColor: "green",
    paddingTop: 3,
    paddingBottom: 3,
  },
  rowDirection: {
    flexDirection: "row",
  },
  columnDirection: {
    flexDirection: "column",
  },
  alignLeftContainer: {
    textAlign: "left",
  },
  alignRightContainer: {
    textAlign: "right",
  },
  line: {
    flex: 1,
    backgroundColor: "black",
    height: 1,
    marginTop: 5,
    marginBottom: 5
  },
  footerWrapper: {
    flex: 1,
    flexDirection: "column",
  },
  footerContainer: {
    flex: 1,
    justifyContent: "flex-end",
    paddingTop: 3,
    paddingBottom: 3,
  },
  footerMain: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "black",
    borderTop: 5,
    borderTopColor: "red",
    color: "white"
  },
  footerContentContainer: {
    flexDirection: "row",
  },
  footerContentFull: {
    flex: 10,
    textAlign: "left",
  },
  footerContentLeft: {
    flex: 6,
    textAlign: "left",
  },
  footerContentText: {
    paddingTop: 5,
    paddingBottom: 5
  },
  footerContentLinks: {
    borderTop: 1,
    borderTopColor: "grey",
    paddingTop: 7,
    paddingBottom: 5,
    marginTop: 7
  },
  footerContentRight: {
    flex: 1,
    textAlign: "left",
  },
});
