import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import ActivationStore from './store';
/* stores */

const Index = ({CONFIG}) => {
  const orderActivationContext  = useContext(ActivationStore);
  const orderActivationMethod = async (query) => {
    console.log(query);
    
    orderActivationContext.setLoading(true);
    const fetch = await Fetch({
      url: CONFIG.application.order.api.endpoints.activation.url + query,
      config: {
        method: CONFIG.application.order.api.endpoints.activation.method,
        headers: CONFIG.application.order.api.headers
      }
    });
    console.log(CONFIG.application.order.api.endpoints.activation.url + query);
    
    orderActivationContext.setStatus(fetch[0]);
    orderActivationContext.setData(fetch[1]);
    orderActivationContext.setLoading(false);
  }
  const orderActivationData = orderActivationContext.isData();

  return { orderActivationContext, orderActivationMethod, orderActivationData }
}

export default Index;
