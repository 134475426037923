import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

/* packages */
import Button from '../../util-mui-buttons';
/* packages */

const index = ({orderId, download}) => {

  return <Fragment>
    <Button.NormalSmall>
      <Link to={"/orders/detail/" + orderId}>Back to details</Link>
    </Button.NormalSmall>
    <Button.NormalSmall>
      {download}
    </Button.NormalSmall>
  </Fragment>

}

export default index;
