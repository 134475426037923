import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';

import { Row, Col } from 'reactstrap';

/* design */
import DesignSection from '../../app-design-section';
/* design */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import { FetchContextApi } from '../../lib-atomic-functions';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
/* packages */

/* stores */
import { CompanyApprove } from '../../app-module-company-container/stores';
/* stores */

import CONFIG from '../config';

/* CONSTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const APPROVE = CONFIG.application.company.api.endpoints.approve;
const APPROVECONFIG = {
  url: APPROVE.url,
  method: APPROVE.method,
  headers: APPROVE.headers,
  responseType: 'json'
}
/* CONSTANTS */

const index = (props) => {

  const { token } = LocalStorage.getLS().login;
  const companyId = props.companyId
  
  /* states */
  APPROVECONFIG.headers = { ...APPROVECONFIG.headers, Authorization: token }
  const [ companyApproveStatus, companyApproveMethod ] = FetchContextApi(APPROVECONFIG, CompanyApprove);
  /* states */

  const handleApprove = async () => {
    console.log("TEST", APPROVECONFIG)
    await companyApproveMethod({query: companyId + '/approval'});
    // console.log("TEST", companyApproveStatus, companyApproveStatus.status)
    if (companyApproveStatus.status) {
      props.history.push("/company/l/success-company-approve/" + companyId);
      // https://tr4ns10.tr4ns.com/Westfield-BulkOrder/accounts32271572/approval
    } else {
      //landing-fail page
    }  }

  const handleCancel = async () => {
    props.history.push("/company/detail/" + companyId);
  }

  /* data props */
  const submitButton = { size: "small", onClick: handleApprove }
  const cancelButton = { size: "small", onClick: handleCancel }
  /* data props */

  return <Fragment>

    <img src={LANDINGGRAPHICS} width="100%"/>
    <ModuleTitle>Almost there!</ModuleTitle>
    <ModuleText>You are about to approve company ID <b>{companyId}</b>. Would you like to proceed?</ModuleText>
    <br/>
    <br/>
    <Buttons.Primary {...submitButton}>Proceed and Approve</Buttons.Primary>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>

  </Fragment>

}

export default index;
