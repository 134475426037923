import React from 'react';

import Button from '@material-ui/core/Button';

/*
: properties
children
onClick
size
fullWidth
*/

const primary = props => {
  return <Button {...props} variant="contained" color="primary" >
    { props.children }
  </Button>
}

export default primary;
