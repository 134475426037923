import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  smallTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const title = props => {

  /* mui */
  const classes = useStyles();
  /* mui */

  return <Typography className={classes.smallTitle} variant="body2">
    <small>{ props.children }</small>
  </Typography>
}

export default title;
