import React, { useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { Buttons } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
import {
  ModuleWrapperMain,
  ModuleWrapperFull,
  ModuleWrapperLeft,
  ModuleWrapperRight
} from "../../lib-atomic-design-section";
import {
  ModuleWrapperMd,
  ModulePaperWrapper
} from "../../lib-atomic-design-section";
/* packages */

/* module */
import Nav from "../nav/orders";
import Title from "../title/orders";
import Action from "../action/orders";
import Loader from "../loader";
import {
  PDFPreviewInvoice,
  PDFLinkInvoice,
  PDFBlobProviderInvoiceCorp,
  PDFPreviewQuote,
  PDFLinkQuote
} from "../program";
/* module */

/* functions */
import {
  OrderingDetail,
  OrderingPrintCount
} from "../../app-module-ordering-container/functions";
import { CompanyDetail } from "../../app-module-company-container/functions";
import { ContactDetail } from "../../app-module-contact-container/functions";
import {
  ProductCat1List,
  ProductCat2List,
  ProductCat3List,
  ProductCat4List
} from "../../app-module-product-container/functions";
/* functions */

import CONFIG from "../config";

const index = observer(props => {
  const { login } = LocalStorage.getLS();
  const { token } = login;
  if (!login) return <Redirect to="/u/logout" />;

  const { orderId } = props.match.params;

  /* states */
  /* order */
  const { orderPrintCountContext, orderPrintCountMethod } = OrderingPrintCount({
    CONFIG
  });
  const { orderDetailContext, orderDetailMethod } = OrderingDetail({ CONFIG });
  /* products */
  const {
    productCat1ListContext,
    productCat1ListMethod,
    productCat1ListData
  } = ProductCat1List({ CONFIG, token });
  const {
    productCat2ListContext,
    productCat2ListMethod,
    productCat2ListData
  } = ProductCat2List({ CONFIG, token });
  const {
    productCat3ListContext,
    productCat3ListMethod,
    productCat3ListData
  } = ProductCat3List({ CONFIG, token });
  const {
    productCat4ListContext,
    productCat4ListMethod,
    productCat4ListData
  } = ProductCat4List({ CONFIG, token });

  useEffect(() => {
    orderDetailMethod({ token, orderId });
    //orderInfoMethod();
    productCat1ListMethod();
    productCat2ListMethod();
    productCat3ListMethod();
    productCat4ListMethod();
  }, [orderId]);
  /* states */

  /* states */
  /* companys */
  const {
    companyInfoContext,
    companyInfoMethod,
    companyInfoData
  } = CompanyDetail({
    CONFIG,
    token,
    companyId: orderDetailContext.data.clientId
  });
  /* contacts */
  const {
    contactInfoContext,
    contactInfoMethod,
    contactInfoData
  } = ContactDetail({
    CONFIG,
    token,
    companyId: orderDetailContext.data.clientId,
    contactId: orderDetailContext.data.contactId
  });

  useEffect(() => {
    console.log(orderDetailContext);
    if (orderDetailContext.data.clientId) companyInfoMethod();
    if (orderDetailContext.data.contactId) contactInfoMethod();
    if (orderDetailContext.data.orderStatusName === "Payment Confirmed")
      orderPrintCountMethod({ token, orderId }); // count print/view count
  }, [orderDetailContext.data]);

  useEffect(() => {
    if (orderDetailContext.expireToken)
      props.history.push("/u/landing-token-expire");
  }, [orderDetailContext.expireToken]);
  /* states */

  const productsAll =
    productCat1ListData &&
    productCat2ListData &&
    productCat3ListData &&
    productCat4ListData
      ? [
          ...productCat1ListData.products,
          ...productCat2ListData.products,
          ...productCat3ListData.products,
          ...productCat4ListData.products
        ]
      : false;

  /* laoder */
  if (orderDetailContext.loading) return <Loader />;
  if (!orderDetailContext.data) return <Loader />;
  if (!contactInfoContext.data) return <Loader />;
  if (orderDetailContext.expireToken) return <Loader />; // redirect
  if (!companyInfoData || !contactInfoData || !productsAll) return <Loader />;

  const { orderStatusName } = orderDetailContext.data;
  const isPDFQuote =
    orderStatusName === "Quoted" ||
    orderStatusName === "Approved" ||
    orderStatusName === "ONHOLD"
      ? true
      : false;
  const productsData = orderDetailContext.data.detail.filter(
    product => product.subClassId === 2 || product.subClassId === 3
  );
  const feesData = orderDetailContext.data.detail.filter(
    product => product.subClassId === 1 || product.subClassId === 4
  );
  const productsDetailsData = productsData.map(productDetail => {
    productDetail["details"] = productsAll.filter(
      product => product.productId === productDetail.productId
    )[0];
    return productDetail;
  });
  const feesDetailsData = feesData.map(productDetail => {
    productDetail["details"] = productsAll.filter(
      product => product.productId === productDetail.productId
    )[0];
    return productDetail;
  });

  /* data props */
  const order = orderDetailContext.data;
  const navData = { orderId };
  const previewData = {
    pdfFilename: orderId + ".pdf",
    pdfPreviewData: {
      orderInfoData: order,
      companyInfoData,
      contactInfoData,
      productsDetailsData,
      feesDetailsData
    },
    pdfPreviewSize: {
      width: 450,
      height: 700
    }
  };

  return <PDFBlobProviderInvoiceCorp {...previewData} />;
});

export default index;
