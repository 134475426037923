import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const Breadcrumb = props => {
  return <Breadcrumbs maxItems={3} aria-label="Breadcrumb">
    { props.children }
  </Breadcrumbs>
}

export default Breadcrumb;
