import React, { useContext } from 'react';

/* packages */
import Fetch from '../../../util-fetch';
/* packages */

/* stores */
import CreateStore from './store';
/* stores */

const Index = ({CONFIG, token}) => {
  const orderCreateContext  = useContext(CreateStore);
  const orderCreateMethod = async (body) => {
    orderCreateContext.setLoading(true);
    CONFIG.application.order.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.order.api.endpoints.create.url,
      config: {
        method: CONFIG.application.order.api.endpoints.create.method,
        headers: CONFIG.application.order.api.headers,
        body
      }
    });
    orderCreateContext.setStatus(fetch[0]);
    orderCreateContext.setData(fetch[1]);
    orderCreateContext.setLoading(false);
  }
  const orderCreateData = orderCreateContext.isData();

  return { orderCreateContext, orderCreateMethod, orderCreateData }
}

export default Index;
