import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

/* container */
import ReportingContainer from '../../app-module-reporting-container/default';
/* container */

/* packages */
import { Buttons } from '../../lib-atomic';
import { ModuleContainerSm, ModulePaperWrapper, ModuleWrapperMain } from '../../lib-atomic-design-section';
import { ModuleTitle, ModuleLink, ModuleText, ModuleImage } from '../../lib-atomic-design-section';
import { PDFPreviewReport21, PDFLinkReport21 } from '../../app-module-reporting-section-pdf/default';
/* packages */

/* stores */
import { Report21 } from '../../app-module-reporting-container/stores';
/* stores */

import CONFIG from '../config';

/* CONTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.default;
const NOW = new Date();
const DATE = moment(NOW).format('DD/MM/YYYY');
const TIME = moment(NOW).format('H:mm:ss');
/* CONTANTS */

const index = props => {
  const { startDate, endDate } = props.location;

  /* stores contexts */
  const Report21Context  = useContext(Report21);
  /* stores contexts */

  /* data props */
  const dataPDF = {
    filename: startDate + "_" + endDate + '.pdf',
    data: {
      reportData: Report21Context.data.json,
      startDate: moment(startDate).format('DD/MM/YYYY'),
      endDate: moment(endDate).format('DD/MM/YYYY'),
      runDate: DATE,
      runTime: TIME
    },
    size: {
      width: 700,
      height: 600
    }
  }
  /* data props */

  if (!Report21Context.data) props.history.push('/reporting/21');

  return <ReportingContainer>
    <ModuleContainerSm>
      <ModulePaperWrapper>
      <ModuleImage><img src={LANDINGGRAPHICS} width="100%"/></ModuleImage>
      <ModuleTitle>Great! Your file is now ready.</ModuleTitle>
      <ModuleText>Please click the button below to download the file.</ModuleText>
      <br/>
      <Buttons.Primary>
        <PDFLinkReport21 {...dataPDF} />
      </Buttons.Primary>
      <br/>
      <br/>
      <ModuleLink><Link to="/reporting/21">Back to Report 21</Link></ModuleLink>
      </ModulePaperWrapper>
    </ModuleContainerSm>
  </ReportingContainer>

}

export default index;
