import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "login";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service
  },
  application: {
    account: {
      api: {
        endpoints: {
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode: setup.setup.thor.moduleCode[environment]
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.westfield_auth_v1.url[environment] + "/token",
            method: "POST"
          },
          authCorp: {
            headers: {
              "Content-Type": "application/json",
              moduleCode: setup.setup.thor.moduleCodeCorp[environment]
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.westfield_auth_v1.url[environment] + "/token",
            method: "POST"
          },
          detail: {
            headers: {
              "Content-Type": "application/json",
              moduleCode: setup.setup.thor.moduleCode[environment]
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.westfield_v1.url[environment] + "/lookup/isHQ",
            method: "GET"
          }
        },
        objects: {
          username: {
            name: "username",
            initial: "",
            label: "Username",
            helperText: false,
            rules: ["isNotNull"]
          },
          password: {
            name: "password",
            initial: "",
            label: "Password",
            helperText: false,
            rules: false
          }
        }
      },
      responses: {
        fail: "Incorrect email or password",
        unverified: "Please verify your account by ticking the recaptcha."
      }
    }
  },
  routes: {
    label: "Login",
    prefix: "/u/login"
  },
  setup
};

export default config;
