import React from "react";

import Grid from "@material-ui/core/Grid";
import TableHeader from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

/* modules */
import Column from "./column-header";
import { TableRow } from "@material-ui/core";
/* modules */

/*
:properties
config
*/

const row = ({ config }) => {
  return (
    <TableHeader>
      <TableRow>
        {config.map((data, index) => {
          return <Column key={index} {...data} />;
        })}
      </TableRow>
    </TableHeader>
  );
};

export default row;
