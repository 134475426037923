import React from 'react';

import { Row, Col } from 'reactstrap';

const programFooter = props => {
  return <Row>
    <Col>
    program footer
    </Col>
  </Row>
}

export default programFooter;
