import React from "react"

import './style.css';

const medium = props => {
  return <div className="skeleton-container">
    <div className="line md h60"></div>
  </div>
}

export default medium;
