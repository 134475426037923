import React from 'react';

import Typography from '@material-ui/core/Typography';

const feed = ({ error }) => {
  return error.map((item, index) => {
    return <Typography key={index} variant="caption" display="block" gutterBottom>
      { item }
    </Typography>
  });
}

export default feed;
