import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import Idle from 'react-idle'

/* packages */
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* CONSTANT */
import CONFIG from '../config';
const IDLELIMIT = CONFIG.setup.setup.idle_limit;
/* CONSTANT */

const index = props => {

  const { login } = LocalStorage.getLS();
  if (!login) return <Redirect to='/u/login' />
  const { token } = login;

  const handleIdle = ({idle}) => {
    if (idle) return <Redirect to='/u/landing-idle-user' />
    if (!idle) return <Fragment>{ props.children }</Fragment>
  };

  if (token) return <Idle timeout={IDLELIMIT} render={handleIdle} />

  return <Fragment>{ props.children }</Fragment>

}

export default index;
