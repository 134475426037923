import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Landing Success Company Decline";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: null,
        endpoints: null
      }
    }
  },
  routes: {
    label: "Success Company Decline",
    prefix: "/company/l/success-company-decline/:companyId"
  },
  setup,
  environment
}

export default config;
