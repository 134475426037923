import React from 'react';

import Modules from '../../modules/program-modules';

const programImports = props => {
  return Modules.map((Module, i) => {
    return <Module.Routes key={i} />
  });
}

export default programImports;
