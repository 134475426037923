import isNotNull from "./isNotNull";
import isEmail from "./isEmail";
import isPassword from "./isPassword";
import isNumber from "./isNumber";
import isMin from "./isMin";
import isDate from "./isDate";
import isJson from "./isJson";
import isMinVal from "./isMinVal";
import isMaxVal from "./isMaxVal";
import isName from "./isName";
import isMinMax from "./isMinMax";

export default {
  isNotNull,
  isEmail,
  isPassword,
  isNumber,
  isMin,
  isDate,
  isJson,
  isMinVal,
  isMaxVal,
  isName,
  isMinMax
}
