import React, { Fragment } from "react";
import { Link } from "react-router-dom";

/* packages */
import Button from "../../util-mui-buttons";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

const Index = ({ companyId }) => {
  return (
    <Fragment>
      <Button.NormalSmall>
        <Link to={"/company/edit/" + companyId}>Edit</Link>
      </Button.NormalSmall>
      <Button.NormalSmall>
        <Link to={"/company/approve/" + companyId}>Approve</Link>
      </Button.NormalSmall>
      <Button.NormalSmall>
        <Link to={"/company/decline/" + companyId}>Decline</Link>
      </Button.NormalSmall>
    </Fragment>
  );
};

export default Index;
