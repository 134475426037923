import React from 'react';
import Button from '@material-ui/core/Button';

const Primary = props => {
  const { children, onClick } = props;
  const handles = { onClick }
  return <Button variant="contained" color="primary" {...handles} >
    {children}
  </Button>
}

export default Primary;
