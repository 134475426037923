import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

class Store {

  loading = false;
  data = false;
  status = true;
  expireToken = false;

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = this.clean(status, data.json);
    this.expireToken = data.json.status === 400 ? true : false;
  }

  clean = (status, data) => {
    if (!status || !data) return false;
    const product_card = this.filterProduct([3], data);
    const product = this.filterProduct([2,3], data);
    const fee = this.filterProduct([1,4], data);
    return {
      order: data,
      company: data,
      contact: data,
      product: { products: product },
      fee: { products: fee },
      product_card: { products: product_card }
    }
  }

  // [2,3] product | [1,4] fee
  filterProduct = (idList, data) => {
    return data.detail.filter((product) => idList.includes(product.subClassId))
  }


}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable,
})


export default createContext(new Store())
