import React, { useContext } from "react";

/* packages */
import { Fetch } from "../../../lib-atomic-functions";
/* packages */

/* stores */
import Store from "./store";
/* stores */

const index = ({ CONFIG }) => {
  const orderDetailContext = useContext(Store);
  const orderDetailMethod = async ({ token, orderId }) => {
    orderDetailContext.setLoading({
      loading: true,
      status: false,
      data: false
    });
    CONFIG.application.order.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.order.api.endpoints.detail.url + orderId,
      config: {
        method: CONFIG.application.order.api.endpoints.detail.method,
        headers: CONFIG.application.order.api.headers
      }
    });
    orderDetailContext.setLoading({
      loading: false,
      status: fetch[0],
      data: fetch[1]
    });
  };

  return { orderDetailContext, orderDetailMethod };
};

export default index;
