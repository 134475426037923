import React from 'react'
import { Link } from 'react-router-dom';

import DesignSection from '../util-mui-design-section';

import CONFIG from './config';

const App = props => {
  const url = props.match ? props.match.url : "";
  const { label, prefix } = CONFIG.routes;
  return null;
  return <DesignSection.ModuleName>
    <Link to={prefix}>{label}</Link>
  </DesignSection.ModuleName>
}

export default App;
