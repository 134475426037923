import React, { useContext, useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
import { FormHook } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
var SEARCHOBJECTS = CONFIG.application.order.objects;
/* CONSTANTS */

const search = observer(({props, handleClickAway}) => {

  const [openAutomate, setOpenAutomate] = useState(false);

  // useEffect(() => {
  //   const initDate = new Date();
  //   const initDateFormat = initDate.toISOString().split('T')[0]; // yyyy-mm-dd
    
  //   if (!openAutomate) {
  //     SEARCHOBJECTS.startDate.initial =initDateFormat 
  //     SEARCHOBJECTS.endDate.initial =initDateFormat
  //     console.log('1');
      
  //   } else {
  //     SEARCHOBJECTS.startDate.initial ="" 
  //     SEARCHOBJECTS.endDate.initial =""
  //     console.log('2');
  //   }
  //   resetForm(SEARCHOBJECTS)
  // }, [openAutomate])

  const handleCancel = () => {
    handleClickAway();
  }

  const handleSearch = () => {
    handleClickAway();
    props.history.push('/orders/search', {...values});
  }

  const handleOpenAutomate = () => {
    setOpenAutomate(!openAutomate);
  }
  const {
    resetForm,
    values,
    onChange,
    onPick,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleSearch, SEARCHOBJECTS);

  if (SEARCHOBJECTS.startDate.initial === "" && openAutomate) {
    const initDate = new Date();
    const initDateFormat = initDate.toISOString().split('T')[0]; // yyyy-mm-dd
      SEARCHOBJECTS.startDate.initial =initDateFormat 
      SEARCHOBJECTS.endDate.initial =initDateFormat
      resetForm(SEARCHOBJECTS)
  }
  if (SEARCHOBJECTS.startDate.initial !== "" && !openAutomate) {
      SEARCHOBJECTS.startDate.initial ="" 
      SEARCHOBJECTS.endDate.initial =""
      resetForm(SEARCHOBJECTS)
  }
  const formatDate = { formatDisplay: "dd/MM/yyyy", formatProcess: "YYYY-MM-DD" }
  const clientName = { ...SEARCHOBJECTS.clientName, error: error.clientName, value: values.clientName, onChange };
  const orderId = { ...SEARCHOBJECTS.orderId, error: error.orderId, value: values.orderId, onChange };
  const startDate = { ...SEARCHOBJECTS.startDate, ...formatDate, error: error.startDate, value: values.startDate, onPick };
  const endDate = { ...SEARCHOBJECTS.endDate, ...formatDate, error: error.endDate, value: values.endDate, onPick };
  const statusId = { ...SEARCHOBJECTS.statusId, error: error.statusId, value: values.statusId, onChange };
  const searchButton = { fullWidth: false, size: "small" }
  const cancelButton = { fullWidth: false, size: "small", onClick: handleCancel }
  // const advanced = { ...SEARCHOBJECTS.advanced, error: error.advanced, value: values.advanced, onChange };
  const advanced = { label: <small>Advanced</small>, onChange: handleOpenAutomate }

  return <form onSubmit={onSubmit} noValidate>
    <Inputs.Text {...clientName} />
    <Inputs.Text {...orderId} />
    <Inputs.Select {...statusId} />
    
    <Inputs.Checkbox {...advanced} />
    { openAutomate &&
      <Fragment>
      <Inputs.Date {...startDate} />
      <Inputs.Date {...endDate} />
      </Fragment>
    }
    <br/>
    <br/>
    <Buttons.Normal {...searchButton}>Search</Buttons.Normal>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
  </form>

});

export default search;
