import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  loading = false;
  data = false;
  status = false;

  setLoading = (data) => {
    this.loading = data;
  }

  setData = (data, contactId) => {
    if (!data.contacts) return false;
    const detail = data.contacts.filter((contact) => contact.id === Number(contactId))[0];
    this.data = detail;
  }

  setStatus = (status) => {
    this.status = status;
  }

  isData = () => {
    return (this.status && !this.loading) ? this.data : false;
  }

}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable
  /*
  todos: observable,
  remainingTodos: computed
  */
})


export default createContext(new Store())
