import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { FormHook, LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import Nav from "../nav/contact";
import Title from "../title/contact";
import Form from "../form/contact";
import Loader from "../loader";
import CompanyInfoDetail from "../../app-module-company-detail/detail";
/* module */

/* functions  */
import {
  ContactListAll,
  ContactUpdate,
} from "../../app-module-contact-container/functions";
/* functions  */

import CONFIG from "../config";

/* CONSTANTS */
const CONTACTOBJECTS = CONFIG.application.contact.objects;
/* CONSTANTS */

const index = observer((props) => {
  const { login } = LocalStorage.getLS();
  const { token } = login;

  const { companyId, contactId } = props.match.params;

  /* state */
  const { contactUpdateContext, contactUpdateMethod } = ContactUpdate({
    CONFIG,
  });
  const { contactListContext, contactListMethod } = ContactListAll({ CONFIG });

  useEffect(() => {
    contactListMethod({ token, companyId });
  }, []);

  useEffect(() => {
    if (contactListContext.data.contacts) {
      const { contacts } = contactListContext.data;
      handleEditValues({ contacts, contactId });
    }
  }, [contactListContext.data.contacts]);
  /* state */

  const handleUpdate = async () => {
    values.flPrimary = values.flPrimary ? 1 : 0;
    values.flActive = values.flActive ? 1 : 0;
    values.id = contactId;
    await contactUpdateMethod({
      token,
      companyId,
      contactId,
      body: JSON.stringify(values),
    });
    props.history.push("/company/detail/" + companyId);
  };

  const handleEditValues = ({ contacts, contactId }) => {
    const editValues = contacts.filter(
      (contact) => contact.id === Number(contactId)
    )[0];
    Object.keys(CONTACTOBJECTS).map((key, index) => {
      console.log(editValues[key], key);
      if (key === "flPrimary" || key === "flActive") {
        handleValue(key, editValues[key] === 1 ? true : false);
      } else {
        handleValue(key, editValues[key]);
      }
    });
  };

  const { values, onChange, onCheck, onSubmit, handleValue, error } =
    FormHook.useFormHook(handleUpdate, CONTACTOBJECTS);

  /* laoder */
  if (contactListContext.loading) return <Loader />;
  if (!contactListContext.data) return <Loader />;
  if (contactListContext.expireToken) return <Loader />; // redirect

  const firstName = {
    ...CONTACTOBJECTS.firstName,
    error: error.firstName,
    value: values.firstName,
    onChange,
  };
  const lastName = {
    ...CONTACTOBJECTS.lastName,
    error: error.lastName,
    value: values.lastName,
    onChange,
  };
  const emailAddress = {
    ...CONTACTOBJECTS.emailAddress,
    error: error.emailAddress,
    value: values.emailAddress,
    onChange,
  };
  const mobile = {
    ...CONTACTOBJECTS.mobile,
    error: error.mobile,
    value: values.mobile,
    onChange,
  };
  const comment = {
    ...CONTACTOBJECTS.comment,
    error: error.comment,
    value: values.comment,
    onChange,
  };
  const flPrimary = {
    ...CONTACTOBJECTS.flPrimary,
    error: error.flPrimary,
    value: values.flPrimary,
    onChange: onCheck,
  };
  const flActive = {
    ...CONTACTOBJECTS.flActive,
    error: error.flActive,
    value: values.flActive,
    onChange: onCheck,
  };
  const submitButton = { fullWidth: true };

  return (
    <form onSubmit={onSubmit} noValidate>
      <Inputs.Text {...firstName} />
      <Inputs.Text {...lastName} />
      <Inputs.Text {...emailAddress} />
      <Inputs.Text {...mobile} />
      <Inputs.Text {...comment} />
      {/* <Inputs.Checkbox {...flPrimary} /> */}
      {/* <Inputs.Checkbox {...flActive} /> */}
      <br />
      <br />
      <Buttons.Normal>Update Contact</Buttons.Normal>
    </form>
  );
});

export default index;
