import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

/* module */
import PDFHeader from "./header";
import PDFCompany from "./company";
import PDFProductsLabel from "./products-label";
import PDFProductsBody from "./products-body";
import PDFProductsItem from "./products-item";
import PDFFeesBody from "./fees-body";
import PDFFeesItem from "./fees-item";
import PDFTotalBody from "./total-body";
import PDFFooter from "./footer";
/* module */

const index = ({
  orderInfoData,
  companyInfoData,
  contactInfoData,
  productsDetailsData,
  feesDetailsData,
}) => {
  const totalData = { order: orderInfoData };
  const productsData = { products: productsDetailsData };
  const feesData = { products: feesDetailsData };
  const companyData = {
    order: orderInfoData,
    company: companyInfoData.clients[0],
    contact: contactInfoData,
  };
  const orderData = { order: orderInfoData };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* header */}
        <PDFHeader {...orderData} />
        {/* header */}

        {/* body */}
        <PDFCompany {...companyData} />
        {/* body */}

        {/* header */}
        <PDFProductsLabel />
        {/* header */}

        {/* body */}
        <PDFProductsBody {...productsData} />
        {/* body */}

        {/* body */}
        <PDFFeesBody {...feesData} />
        {/* body */}

        {/* body */}
        <PDFTotalBody {...totalData} />
        {/* body */}

        {/* body */}
        <PDFFooter {...orderData} />
        {/* body */}
      </Page>
    </Document>
  );
};

export default index;
