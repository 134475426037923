import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

import { NumberCurrency } from "../../../../lib-atomic-functions";

const totalBody = ({ order }) => {
  const totalGST_currency = NumberCurrency(order.totalGST.toFixed(2));
  const totalAmount_currency = NumberCurrency(order.totalAmount.toFixed(2));
  return (
    <Fragment>
      <View style={[styles.container]}>
        <View style={styles.itemContainer}>
          <Text></Text>
        </View>
        <View style={styles.totalContainer}>
          <Text></Text>
        </View>
        <View
          style={[styles.courierTotalContainer, styles.alignRightContainer]}
        >
          <View style={styles.transparentline}></View>
          <Text>Total:&nbsp;&nbsp;</Text>
        </View>
        <View
          style={[styles.courierTotalContainer2, styles.alignRightContainer]}
        >
          <View style={styles.line}></View>
          <Text style={styles.TotalTitle}>${totalAmount_currency}</Text>
        </View>
      </View>

      <View style={[styles.container, { marginTop: -5 }]}>
        <View style={styles.itemContainer}>
          <Text></Text>
        </View>
        <View style={styles.totalContainer}>
          <Text></Text>
        </View>
        <View style={[styles.gstContainer, styles.alignRightContainer]}>
          <Text>(*INC GST of ${totalGST_currency})</Text>
        </View>
      </View>
    </Fragment>
  );
};

export default totalBody;
