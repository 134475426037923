/* development */
import Style from '../../app-plugin-design-system';
import TranxactorLogo from '../../util-svgs/tranxactor';
import Box from '../../util-box';
import Loader from '../../util-loader';

export default {
  Style,
  TranxactorLogo,
  Box,
  Loader
}
