import React, { useEffect, Fragment } from "react";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import useForm from "../../util-hook-useform";
import useSuggest from "../../util-hook-usesuggest";
import validation from "../../util-inputs-validation";
import Input from "../../util-mui-inputs";
import { Inputs, Buttons } from "../../util-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import StepsLoader from "../loader/steps";
import CompanyInfo from "../../app-module-company-detail/info";
/* module */

/* functions */
import { CompanyList } from "../../app-module-company-container/functions";
/* functions */

import CONFIG from "../config";

const Index = observer(
  ({ handleNext, handleBack, handleReset, handleCollection, collection }) => {
    const { login } = LocalStorage.getLS();
    const { token } = login;

    const formObjects = CONFIG.application.company.objects;

    /* states */
    const { companyListContext, companyListMethod, companyListData } =
      CompanyList({ CONFIG, token });

    useEffect(() => {
      companyListMethod();
    }, []);

    useEffect(() => {
      if (collection.company) loadFromCollection();
    }, [collection]);
    //companyAdd(JSON.stringify({suggestion: data_ordering.company}));
    /* states */

    const companySource = companyListData ? companyListData.clients : false;

    const loadFromCollection = () => {
      onLoadCompany(JSON.stringify({ suggestion: collection.company }));
    };

    const companyNext = () => {
      handleNext();
    };

    const companyBack = () => {
      handleBack();
    };

    const companyTemplate = (data) => {
      return <CompanyInfo {...data} />;
    };

    const companyValidate = () => {
      handleNext();
      const { suggestion } = JSON.parse(selectedCompany);
      handleCollection("status", true); // all good to proceed in checkout quote/order
      handleCollection("contact", false); // reset the contact
      handleCollection("company", false);
      handleCollection("company", suggestion);
    };

    const companyAdd = (data) => {
      if (!data) return handleValue("company", "");
      const { suggestion } = JSON.parse(data);
      return handleValue("company", String(suggestion.id));
    };

    const [
      onLoadCompany,
      onResetCompany,
      isSelectedCompany,
      selectedCompany,
      suggestionCompany,
    ] = useSuggest(companyAdd, companyTemplate, companySource, "name");

    const { values, errors, handleChange, handleSubmit, handleValue } = useForm(
      companyValidate,
      validation,
      formObjects,
      false
    );

    /* form: stepOne */
    const companyFormObject = {
      ...formObjects.company,
      errors: errors.company,
      value: values.company,
      handleChange: handleChange,
    };
    /* form: stepOne */

    if (!companySource) return <StepsLoader />;

    const isCompanyCollection = collection.company ? true : false;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <p>Select a company to quote</p>
        <Input.Suggest
          {...companyFormObject}
          suggestionProps={suggestionCompany}
          isSelected={isSelectedCompany}
          selectedValue={selectedCompany}
          displayTemplate={companyTemplate}
        >
          <Buttons.PrimarySmall onClick={onResetCompany}>
            Remove
          </Buttons.PrimarySmall>
        </Input.Suggest>
        <hr />
        <Buttons.Normal>Select and continue</Buttons.Normal>
        <br />
        {isCompanyCollection && <hr />}
        {isCompanyCollection && (
          <Buttons.DefaultSmallNon onClick={companyNext}>
            Next
          </Buttons.DefaultSmallNon>
        )}
      </form>
    );
  }
);

export default Index;
