import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

const productsLabel = (props) => {
  const { isCardNumberExist } = props;
  console.log(props);
  return (
    <View style={[styles.container, styles.itemsWrapper]}>
      {isCardNumberExist && (
        <View style={styles.itemCardNoContainer}>
          <Text>Start Card #</Text>
        </View>
      )}
      {isCardNumberExist && (
        <View style={styles.itemCardNoContainer}>
          <Text>End Card #</Text>
        </View>
      )}
      <View style={styles.qtyContainer}>
        <Text>Qty</Text>
      </View>
      <View style={styles.otherContainer}>
        <Text style={{ marginLeft: isCardNumberExist ? 0 : 20 }}>
          Card Value
        </Text>
      </View>
      <View style={styles.itemContainer}>
        <Text>Item</Text>
      </View>
      <View style={[styles.totalContainer, styles.alignRightContainer]}>
        <Text>Total</Text>
      </View>
    </View>
  );
};

export default productsLabel;
