import React from 'react';
import { Link } from 'react-router-dom';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* mui : styles */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 10,
    marginBottom: 10,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));
/* mui : styles */

const index = props => {
  const classes = useStyles();
  const { children } = props;
  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
        <Paper className={classes.paper}>
          { children }
        </Paper>
      </Grid>
    </Grid>
  </div>
}

export default index;
