import React, { Fragment } from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperMd, ModulePaperWrapper } from '../../lib-atomic-design-section';
import { ModuleSmallTitle } from '../../lib-atomic-design-section';
import { NumberCurrency } from '../../lib-atomic-functions';
/* packages */

const index = ({totalAmount, totalGST}) => {

  totalAmount = NumberCurrency(totalAmount.toFixed(2));
  totalGST = totalGST ? NumberCurrency(totalGST.toFixed(2)) : false;

  return <ModuleWrapperMd>
    <ModulePaperWrapper>

      <ModuleSmallTitle>TOTAL</ModuleSmallTitle>
      <Typography variant="h6">
        <b>${ totalAmount }</b>
      </Typography>

      { totalGST &&
      <Fragment>
      <ModuleSmallTitle>GST</ModuleSmallTitle>
      <Typography variant="h6">
        <b>${ totalGST }</b>
      </Typography>
      </Fragment>
      }

    </ModulePaperWrapper>
  </ModuleWrapperMd>

}

export default index;
