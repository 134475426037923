/* development */
import ReportingPageLanding from '../../app-module-reporting-page-landing';
import ReportingPage19 from '../../app-module-reporting-page-19';
import ReportingPage20 from '../../app-module-reporting-page-20';
import ReportingPage21 from '../../app-module-reporting-page-21';

import ReportingLanding19 from '../../app-module-reporting-landing-19';
import ReportingLanding20 from '../../app-module-reporting-landing-20';
import ReportingLanding21 from '../../app-module-reporting-landing-21';
import ReportingLandingEmpty from '../../app-module-reporting-landing-empty';

// import modules from '../../app-conf/westfield/modules';
// console.log(modules)
// const Modules = [ ...modules.reporting ]


export default {
  // Modules
}
